import { useState } from 'react';
import StripedDataGrid from 'components/Grid/StripedDataGrid';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyImg from 'assets/img/app_states/empty/result-not-found.png';
import NoFiltersImg from 'assets/img/app_states/empty/no-filters.png';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Filters from './Filters';
import getExperienceDesc from 'utilities/helpers/experienceEnums';

const EmployeeSkills = props => {
  const columns = [
    { field: 'no', headerName: 'Employee ID', cellClassName: 'rows', width: 150 },
    {
      field: 'fullName',
      headerName: 'Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 300,
      cellClassName: 'rows',
      valueGetter: params => `${params.row.firstName || ''} ${params.row.lastName || ''}`
    },
    {
      field: 'email',
      headerName: 'Email',
      cellClassName: 'rows',
      width: 300
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      cellClassName: 'rows',
      width: 280
    }
  ];
  const [filteredColumns, setFilteredColumns] = useState(columns);

  const employees = props.employees.map(e => {
    const dynamicSkills = {};
    e?.filteredSkills?.forEach(skill => {
      dynamicSkills[skill.name] = `Level:${skill?.level || '-'},Used in Aspire:${
        skill?.usedInAspire === true ? 'yes' : 'no'
      },Experience:${getExperienceDesc(skill?.experienceId) || 'N/A'}`;
    });

    return {
      id: e.id,
      no: e.employeeNo,
      firstName: e.firstName,
      lastName: e.lastName,
      email: e.email,
      jobTitle: e.jobTitle,
      filteredSkills: e?.selectedSkills?.map(skill => `(${skill.name}, ${skill.level})`).join(', '),
      ...dynamicSkills
    };
  });

  const addSkillColumns = () => {
    props?.selectedSkills?.forEach(skill => {
      columns.push({
        field: skill,
        headerName: skill,
        width: 180,
        cellClassName: 'skills',
        renderCell: params => (
          <div style={{ whiteSpace: 'pre-line', lineHeight: '1.4' }}>
            {params.value
              ? params.value?.split(',')?.map(data => {
                  const details = data?.split(':');
                  return (
                    <div className="skillInfoWrapper">
                      <span className="label">{details?.[0]} :</span>
                      <span className={details?.[1]}> {details?.[1]}</span>
                    </div>
                  );
                })
              : 'None'}
          </div>
        )
      });
    });
    setFilteredColumns(columns.filter(Boolean));
  };

  const exportToolbar = () => (
    <GridToolbarContainer className="rightToolbar">
      <GridToolbarExport />
    </GridToolbarContainer>
  );
  const renderDetails = params => {
    return props.navigate(`/employees/${params}/profile`);
  };
  return (
    <div id="EmployeeSkills">
      {!props.isLoading ? (
        <div className="EmployeeSkillsWrapper">
          <Filters
            addSkillColumns={addSkillColumns}
            groups={props?.groups}
            handleGroupChange={props?.handleGroupChange}
            filterForm={props?.filterForm}
            handleSkillChange={props?.handleSkillChange}
            filteredSkills={props?.filteredSkills}
            deleteSkill={props?.deleteSkill}
            handleLevelChange={props?.handleLevelChange}
            deleteLevel={props?.deleteLevel}
            handleExperienceChange={props?.handleExperienceChange}
            experienceRanges={props?.experienceRanges}
            handleUsedInAspireChange={props?.handleUsedInAspireChange}
            applyFilter={props?.applyFilter}
            clearFilter={props?.clearFilter}
            groupRef={props?.groupRef}
          />
          {employees?.length ? (
            <StripedDataGrid
              sortModel={props?.sortModel}
              autoWidth
              rows={employees}
              columns={filteredColumns}
              onSortModelChange={props?.handleSortChange}
              slots={props?.isFiltered ? { toolbar: exportToolbar } : {}}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                    page: props?.page - 1
                  }
                }
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableRowSelectionOnClick
              onRowClick={params => renderDetails(params.row.id)}
              getRowClassName={params =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              onPaginationModelChange={params => {
                props?.setPage(params?.page + 1);
              }}
            />
          ) : props?.isFiltered ? (
            <EmptyState
              stateImage={
                <div>
                  <img src={EmptyImg} alt="" width={50} style={{ marginTop: '12rem' }} />
                </div>
              }
              stateText={
                <div className="empty-text-section">
                  <div
                    style={{ marginBottom: '0.75em', font: '400 15px/24px "Poppins", sans-serif' }}
                    className="empty-text-message"
                  >
                    No Result found.
                  </div>
                </div>
              }
            />
          ) : (
            <EmptyState
              stateImage={
                <div>
                  <img src={NoFiltersImg} alt="" width={50} style={{ marginTop: '12rem' }} />
                </div>
              }
              stateText={
                <div className="empty-text-section">
                  <div
                    style={{ marginBottom: '0.75em', font: '400 15px/24px "Poppins", sans-serif' }}
                    className="empty-text-message"
                  >
                    No Filters Applied yet.
                  </div>
                </div>
              }
            />
          )}
        </div>
      ) : (
        <LoadingState />
      )}
    </div>
  );
};

export default EmployeeSkills;
