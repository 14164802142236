import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const toModel = item => {
  const { __v, _lastUpdatedBy, ...c } = item;
  return c;
};
const filter = async filter => {
  const nameFilter = filter.name ? `name=${filter.name}` : '';
  const { data: departments } = await axiosInstances
    .withAuthToken()
    .get(`/departments/?${nameFilter}`);
  return departments.map(department => toModel(department));
};
const update = (data, id) => {
  return axiosInstances.withAuthToken().patch(`/departments/${id}`, data);
};
const add = data => {
  return axiosInstances.withAuthToken().post('/departments/', data);
};
const deleteDepartment = id => {
  return axiosInstances.withAuthToken().delete(`/departments/${id}`);
};
const get = async id => {
  const { data: department } = await axiosInstances.withAuthToken().get(`/departments/${id}`);
  return toModel(department);
};
export const departmentsService = { filter, update, add, deleteDepartment, get };
