import moment from 'moment';
import * as _ from 'lodash';
import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const listBestSuggestions = async (projectSkills, from, to) => {
  let params = `?filter=${JSON.stringify({ from, to, maxLevel: 3, minLevel: 5 })}`;
  // let params = `?filter=${encodeURI(JSON.stringify({ from, to, maxLevel: 3, minLevel: 5 }))}`;
  if (projectSkills.length > 0) {
    params = params + `&ids=${JSON.stringify(projectSkills)}`;
    // params= params + `&ids=${encodeURI(JSON.stringify(projectSkills))}`;
  }
  const filterEndDate = moment(from);
  const filterStartDate = moment(to);
  const { data } = await axiosInstances.withAuthToken().get(`/employees/skills/${params}`);
  const suggestedEmployees = data.map(emp => {
    const _emp = emp;
    const flatEfforts = _.flatMap(_emp.allocations, al => _.flatten(al.efforts));
    const totalDays = filterEndDate.diff(filterStartDate, 'days');
    _emp.freeDays =
      totalDays -
      flatEfforts
        .map(e =>
          moment
            .min([moment(e.end), filterEndDate])
            .diff(moment.max([moment(e.start), filterStartDate]), 'days')
        )
        .reduce((sum, e) => (sum += e));
    _emp.avgUtilization =
      flatEfforts
        .map(
          e =>
            e.effort *
            moment
              .min([moment(e.end), filterEndDate])
              .diff(moment.max([moment(e.start), filterStartDate]), 'days')
        )
        .reduce((sum, e) => (sum += e)) / totalDays;
    _emp.totalProjects = _.uniqBy(_emp.allocations, p => p.project).length;
    _emp.benchedProjects = _.uniqBy(
      _emp.allocations.filter(x => _emp.bench === true),
      p => p.project
    ).length;
    return _emp;
  });
  return _.sortBy(suggestedEmployees, a => a.avgUtilization);
};
export const bestMatchService = { listBestSuggestions };
