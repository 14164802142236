import React from 'react';
import TitleBarContainer from '../title-bar/TitleBarContainer';
import TextField from '@material-ui/core/TextField';
import './SearchBar.scss';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

const SearchBarCotainer = props => {
  const filter = e => {
    props.filter(e.target.value);
  };
  const titleBarSecondary = (
    <>
      <TextField
        onChange={filter}
        placeholder={props.placeholder}
        autoFocus
        margin="dense"
        fullWidth
      />
    </>
  );
  const titleBarPrimary = (toggleMode, openDrawer) => (
    <>
      {props.searchBarActions && props.searchBarActions}
      {props.showSearchButton && (
        <IconButton style={{ color: 'inherit' }} onClick={() => toggleMode(true)}>
          <SearchIcon />
        </IconButton>
      )}
      {props.searchBarCommandPlaceholder && props.searchBarCommandPlaceholder}
    </>
  );
  return (
    <div id="SearchBar">
      <TitleBarContainer
        title={props.title}
        backRoute={props.backRoute}
        titleBarPrimaryTitlePrefix={props.searchBarTitlePrefix}
        titleBarPrimary={titleBarPrimary}
        titleBarSecondary={titleBarSecondary}
      />
    </div>
  );
};

SearchBarCotainer.defaultProps = {
  placeholder: 'Search',
  showSearchButton: true
};
export default SearchBarCotainer;
