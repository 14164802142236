import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const toModel = item => {
  const { __v, _lastUpdatedBy, ...c } = item;
  return c;
};
const filter = async (clientid, filter) => {
  const nameFilter = filter.name ? `name=${filter.name}` : '';
  const { data: projects } = await axiosInstances
    .withAuthToken()
    .get(`/clients/${clientid}/projects/?${nameFilter}`);
  return projects.map(project => toModel(project));
};
const update = (data, clientid, projectId) => {
  return axiosInstances.withAuthToken().patch(`/clients/${clientid}/projects/${projectId}`, data);
};
const add = (data, clientid) => {
  return axiosInstances.withAuthToken().post(`/clients/${clientid}/projects/`, data);
};
const deleteProject = (id, clientid) => {
  return axiosInstances.withAuthToken().delete(`/clients/${clientid}/projects/${id}`);
};
const get = async (id, clientid) => {
  const { data: project } = await axiosInstances
    .withAuthToken()
    .get(`/clients/${clientid}/projects/${id}`);
  return toModel(project);
};
const addResource = (id, data, clientid) => {
  return axiosInstances.withAuthToken().post(`/clients/${clientid}/projects/${id}/resources`, data);
};
const audit = (id, clientid) => {
  return axiosInstances.withAuthToken().get(`/clients/${clientid}/projects/${id}/audit`);
};
export const ProjectsService = { filter, update, add, deleteProject, get, addResource, audit };
