import React, { useState } from 'react';
import * as tagManageService from '../../services/tags-management';
import TagInfoDialog from './TagInfoDialog';
import './TagInfoDialog.scss';

const TagInfoDialogContainer = props => {
  const [tag, setTag] = useState(props.data || { name: '', color: '#FFFFFF' });
  const [isValid, setIsValid] = useState(!!props.data);

  const reset = () => {
    setTag({ name: '', color: '#FFFFFF' });
    setIsValid(false);
  };
  const cancel = () => {
    reset();
    props.closeDialog();
  };
  const handleChange = e => {
    const updatedtag = { ...tag, [e.target.name]: e.target.value };
    let isValid = false;
    if (checkValidity(updatedtag)) {
      console.log('valid');
      isValid = true;
    }
    setIsValid(isValid);
    setTag(updatedtag);
  };
  const checkValidity = tag => {
    if (!Object.values({ ...tag }).includes('')) {
      return true;
    } else {
      return false;
    }
  };
  const createTag = async () => {
    const data = { ...tag, name: tag.name?.trim(), color: tag.color?.trim() };
    if (data.id && data.id !== null && data.id !== undefined) {
      try {
        const { id, name, color } = data;
        const tagData = { name, color };
        await tagManageService.update(tagData, id);
        reset();
        props.onTagCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onTagCreate('fail', errorMessage);
      }
    } else {
      try {
        await tagManageService.add(data);
        reset();
        props.onTagCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onTagCreate('fail', errorMessage);
      }
    }
  };
  return (
    <TagInfoDialog
      tag={tag}
      isValid={isValid}
      handleChange={handleChange}
      cancel={cancel}
      createTag={createTag}
    />
  );
};
export default TagInfoDialogContainer;
