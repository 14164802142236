import React from 'react';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import PanToolIcon from '@material-ui/icons/PanToolOutlined';
import './AccessDenied.scss';
const AccessDenied = () => {
  return (
    <LayoutContainer titleBar={<></>}>
      <div id="accessDenied">
        <PanToolIcon style={{ color: 'red', fontSize: 'xxx-large', marginBottom: '20px' }} />
        <div className="accessDeniedMessage">You don't have permission to view this page</div>
      </div>
    </LayoutContainer>
  );
};
export default AccessDenied;
