import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from 'modules/shared/components/_controls/Menu/Menu';
import ConfirmDialogComponent from 'modules/shared/components/confirm-dialog/ConfirmDialogComponent';
import EmployeeDenseContainer from 'modules/shared/components/_employee-views/EmployeeDense/EmployeeDenseContainer';
import ProjectDialogContainer from '../project-dialog/ProjectDialogContainer';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyProjectsImg from 'assets/img/app_states/empty/Projects.png';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import Button from '@material-ui/core/Button';
const ProjectList = props => {
  return (
    <div id="ProjectList">
      {!props.isLoading ? (
        props.projects.length > 0 ? (
          <>
            <div className="projectsContainer">
              {props.projects.map((project, idx) => (
                <div key={idx} className="projectCard">
                  <div className="projectCardHeader">
                    <div className="projectCardTitle">{project.name}</div>
                    <div className="menuContainer">
                      <Menu>
                        <div
                          className="CustommenuItem"
                          onClick={() => {
                            props.openSideContent(project);
                            props.openDrawer?.();
                          }}
                        >
                          <InfoIcon />
                          <span style={{ marginLeft: '16px' }}>Details</span>
                        </div>
                        <div
                          className="CustommenuItem"
                          onClick={() =>
                            props.navigate(
                              `/admin/clients/${project.clientId}/projects/${project.id}`
                            )
                          }
                        >
                          <TimelapseIcon />
                          <span style={{ marginLeft: '16px' }}>Allocations</span>
                        </div>
                        <div
                          className="CustommenuItem"
                          onClick={() => props.openProjectDialog(project)}
                        >
                          <EditIcon />
                          <span style={{ marginLeft: '16px' }}>Edit</span>
                        </div>
                        <div
                          className="CustommenuItem"
                          onClick={() => props.confirmDelete(project)}
                        >
                          <DeleteIcon />
                          <span style={{ marginLeft: '16px' }}>Delete</span>
                        </div>
                      </Menu>
                    </div>
                  </div>
                  <div className="projectCardContent">
                    <div className="progressContainer">
                      <span className="progressPercent">
                        {Math.round(props.getPercentage(project) * 100) + '%'}
                      </span>
                      <LinearProgress
                        style={{ width: '100%' }}
                        variant="determinate"
                        value={Math.round(props.getPercentage(project) * 100)}
                      />
                    </div>
                    <div className="projectLeader">
                      <EmployeeDenseContainer data={project.leadId} initialsOnly={false} />
                      {!project.billable && <MoneyOffIcon color="secondary" />}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <EmptyState
            stateImage={
              <div>
                <img src={EmptyProjectsImg} alt="" />
              </div>
            }
            stateText={
              <div className="empty-text-section">
                <div
                  style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                  className="empty-text-message"
                >
                  This client doesn't have any projects yet.
                </div>
                <div className="empty-text-action" style={{ textAlign: 'center' }}>
                  <Button
                    style={{ position: 'static', textTransform: 'unset' }}
                    onClick={() => props.openProjectDialog()}
                    variant="contained"
                    color="primary"
                  >
                    Create new project
                  </Button>
                </div>
              </div>
            }
          />
        )
      ) : (
        <LoadingState />
      )}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.isDialogOpen}
        onBackdropClick={props.closeProjectDialog}
        aria-labelledby="form-dialog-title"
      >
        <ProjectDialogContainer
          data={props.dialogData}
          closeDialog={props.closeProjectDialog}
          onProjectCreate={props.onProjectCreate}
        />
      </Dialog>

      <Dialog open={props.isDeleteDialogOpen} onBackdropClick={props.closeDeleteDialog}>
        <ConfirmDialogComponent data={props.toDelete} close={props.closeDeleteDialog} />
      </Dialog>

      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          {props.successToastText}
        </Alert>
      </Snackbar>

      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          {props.errorToastText}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default ProjectList;
