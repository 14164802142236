import React from 'react';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Rectangle,
  Area,
  Line
} from 'recharts';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyReportImg from 'assets/img/app_states/empty/Report.png';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'rgba(97, 97, 97, 0.9)',
          color: '#fff',
          padding: '0px',
          margin: '0px',
          borderRadius: '4px',
          lineHeight: '1.4em',
          textAlign: 'center'
        }}
      >
        <p className="intro" style={{ textAlign: 'center', padding: '4px 15px' }}>
          {payload[0].name} <br /> {payload[0].value}{' '}
        </p>
      </div>
    );
  }
  return null;
};
const composedChartTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#000000b3',
          color: '#fff',
          padding: '3px',
          margin: '0px',
          borderRadius: '4px',
          lineHeight: '1.4em',
          textAlign: 'left',
          fontSize: '13px'
        }}
      >
        {payload.map((entry, idx) => {
          let fieldName = '';
          switch (entry.dataKey) {
            case 'availability':
              fieldName = 'availability';
              break;
            case 'jordanAvailability':
              fieldName = 'Jordan availability';
              break;
            case 'indiaAvailability':
              fieldName = 'India availability';
              break;
            case 'marketDemand':
              fieldName = 'Market demand';
              break;
            default:
              break;
          }
          return (
            <div key={idx} style={{ padding: '3px 6px', display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: entry.color,
                  display: 'inline-block',
                  marginRight: '6px',
                  borderRadius: '2px'
                }}
              />
              {`${fieldName}: ${entry.payload[entry.dataKey]}`}
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};
const CustomizedXAxisTick = ({ x, y, payload, index, visibleTicksCount }, options) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={options?.xOffset || -5}
        y={options?.yOffset || 10}
        style={{
          transform: visibleTicksCount > 3 ? 'rotate(-40deg)' : undefined,
          fontSize: '11px'
        }}
        textAnchor={options?.textAnchor || 'middle'}
        fill="#666"
      >
        {options?.full
          ? payload.value
          : payload.value.length > 7 && visibleTicksCount > 3
          ? payload.value.slice(0, 7) + '..'
          : payload.value}
      </text>
    </g>
  );
};
const CustomCursor = props => {
  const { height, points } = props;
  return (
    <Rectangle
      fill="black"
      stroke={undefined}
      x={points[0].x}
      y={points[0].y}
      width={1}
      height={height}
    />
  );
};
const customLabelLine = ({ cx, cy, midAngle, innerRadius, outerRadius, value, stroke }) => {
  const RADIAN = Math.PI / 180;
  let radius1 = 20 + innerRadius + (outerRadius - innerRadius);
  let radius2 = innerRadius + (outerRadius - innerRadius);
  let x2 = cx + radius1 * Math.cos(-midAngle * RADIAN);
  let y2 = cy + radius1 * Math.sin(-midAngle * RADIAN);
  let x1 = cx + radius2 * Math.cos(-midAngle * RADIAN);
  let y1 = cy + radius2 * Math.sin(-midAngle * RADIAN);
  if (value === 0) {
    return <></>;
  }
  return <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} strokeWidth={1}></line>;
};
const CurrentInventory = props => {
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState(undefined);
  const [area, setArea] = React.useState({
    show: false,
    color: undefined,
    dataKey: undefined
  });
  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };
  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };
  const resetArea = () => {
    setArea({ show: false, color: undefined, dataKey: undefined });
  };
  const renderLegend = props => {
    const { payload } = props;
    return (
      <div style={{ width: '200px' }}>
        <div
          style={{
            marginLeft: '10px',
            marginBottom: '5px',
            fontWeight: 700,
            color: 'rgba(0,0,0,.87)'
          }}
        >
          Skill
        </div>
        <div
          onMouseLeave={() => setActiveSection(undefined)}
          style={{
            backgroundColor: 'rgba(0,0,0,.05)',
            color: '#afb7c8',
            maxHeight: '750px',
            borderRadius: '3px',
            overflowY: 'auto',
            overflowX: 'hidden',
            fontSize: '12px'
          }}
        >
          {payload.map((entry, index) => (
            <div
              onMouseEnter={() => setActiveSection(index)}
              style={{
                cursor: 'pointer',
                padding: '2px 6px',
                display: 'flex',
                alignItems: 'center',
                color: index === activeSection ? '#000' : 'inherit'
              }}
              key={`item-${index}`}
            >
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  backgroundColor: entry.color,
                  display: 'inline-block',
                  marginRight: '6px',
                  borderRadius: '4px'
                }}
              />
              {entry.value}
            </div>
          ))}
        </div>
      </div>
    );
  };
  const colorsRange = [
    'rgb(168, 56, 93)',
    'rgb(122, 163, 229)',
    'rgb(162, 126, 168)',
    'rgb(170, 227, 245)',
    'rgb(173, 205, 237)',
    'rgb(169, 89, 99)',
    'rgb(135, 150, 192)',
    'rgb(126, 211, 237)',
    'rgb(80, 171, 204)',
    'rgb(173, 104, 134)'
  ];
  return (
    <div id="CurrentInventory">
      {!props.isLoading ? (
        <>
          {props.data && props.resultFound() ? (
            props.view === 'compined' ? (
              <div
                id="pie-chart"
                className="chart-container"
                style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}
              >
                <ResponsiveContainer width={'100%'} height={600}>
                  <PieChart width={700} height={600}>
                    <Pie
                      startAngle={450}
                      endAngle={90}
                      dataKey="value"
                      isAnimationActive={false}
                      data={props.data}
                      cx="50%"
                      cy="50%"
                      outerRadius={'85%'}
                      fill="#8884d8"
                      label={entry => {
                        return entry.value === 0 ? null : entry.name;
                      }}
                      labelLine={customLabelLine}
                      onMouseLeave={() => setActiveSection(undefined)}
                      blendStroke={true}
                    >
                      {props.data.map((entry, idx) => (
                        <Cell
                          onMouseEnter={() => setActiveSection(idx)}
                          style={{ opacity: idx === activeSection ? 0.8 : 'unset' }}
                          key={`cell-${idx}`}
                          fill={colorsRange[idx % colorsRange.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip content={CustomTooltip} />
                    <Legend
                      layout="vertical"
                      align="right"
                      verticalAlign="top"
                      iconType="square"
                      content={renderLegend}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            ) : props.view === 'grouped' ? (
              <div id="bar-charts" className="charts-container">
                {props.data.map((group, idx) => (
                  <div key={idx} className="barChartCard">
                    <div className="BarChartCardHeader">{group.name}</div>
                    <div className="barChartCardContent">
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <BarChart
                          width={500}
                          height={300}
                          data={group.data}
                          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                          onMouseMove={state => {
                            state.isTooltipActive
                              ? setActiveSection(group.name + state.activeTooltipIndex)
                              : setActiveSection(undefined);
                          }}
                        >
                          <CartesianGrid vertical={false} />
                          <XAxis
                            dataKey="name"
                            interval={0}
                            angle={group.data.length > 4 ? -20 : 0}
                            tick={CustomizedXAxisTick}
                          />
                          <YAxis allowDecimals={false} domain={[0, 'dataMax']} />
                          <Bar dataKey="value" fill="#8884d8" radius={[5, 5, 0, 0]}>
                            {group.data.map((entry, idx) => (
                              <Cell
                                style={{
                                  opacity: group.name + idx === activeSection ? 0.8 : 'unset',
                                  borderBottom: 'none'
                                }}
                                key={`cell-${idx}`}
                                fill={colorsRange[idx % colorsRange.length]}
                              />
                            ))}
                          </Bar>
                          <Tooltip
                            cursor={false}
                            content={state => {
                              return CustomTooltip({
                                active: state.active,
                                payload: [
                                  {
                                    name: state.label,
                                    value:
                                      state.payload && state.payload.length > 0
                                        ? state.payload[0].value
                                        : undefined
                                  }
                                ],
                                label: state.label
                              });
                            }}
                            position={{ y: -55 }}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              props.view === 'with_benchmarks' && (
                <div id="composedChart" className="chart-container">
                  <ResponsiveContainer width="100%" height="90%">
                    <ComposedChart data={props.data}>
                      <CartesianGrid vertical={false} />
                      <XAxis
                        dataKey="name"
                        label={{ value: 'Skills', position: 'insideBottom', offset: 0 }}
                        scale="band"
                        height={70}
                        interval={0}
                        tick={propserties =>
                          CustomizedXAxisTick(propserties, {
                            xOffset: -5,
                            yOffset: 10,
                            textAnchor: 'end',
                            full: true
                          })
                        }
                      />
                      <YAxis
                        label={{ value: 'Availability', angle: -90, position: 'insideLeft' }}
                        domain={[0, 'dataMax']}
                        yAxisId="left"
                        tickCount={11}
                      />
                      <YAxis
                        yAxisId="right"
                        orientation="right"
                        domain={[0, 100]}
                        tickCount={11}
                        unit="%"
                      />
                      <Bar
                        dataKey="availability"
                        maxBarSize={130}
                        barSize={undefined}
                        fill="rgb(1, 87, 155)"
                        radius={[5, 5, 0, 0]}
                        yAxisId="left"
                      />
                      {area.show && (
                        <Area
                          type="linear"
                          dataKey={area.dataKey}
                          fill={area.color}
                          stroke={area.color}
                        />
                      )}
                      <Line
                        dot={false}
                        dataKey="jordanAvailability"
                        fill="#7aa3e5"
                        stroke="#7aa3e5"
                        yAxisId="right"
                      />
                      <Line
                        dot={false}
                        dataKey="indiaAvailability"
                        fill="#a8385d"
                        stroke="#a8385d"
                        yAxisId="right"
                      />
                      <Line
                        dot={false}
                        dataKey="marketDemand"
                        fill="#00bfa5"
                        stroke="#00bfa5"
                        yAxisId="right"
                      />
                      <Tooltip cursor={<CustomCursor />} content={composedChartTooltip} />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              )
            )
          ) : (
            <EmptyState
              stateImage={
                <div>
                  <img src={EmptyReportImg} alt="" />
                </div>
              }
              stateText={
                <div className="empty-text-section">
                  <div
                    style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                    className="empty-text-message"
                  >
                    No results found
                  </div>
                </div>
              }
            />
          )}
          <SpeedDial
            style={{ position: 'absolute', bottom: '16px', right: '16px' }}
            ariaLabel="toggle chart view SpeedDial"
            icon={<RemoveRedEyeIcon />}
            open={openSpeedDial}
            onOpen={handleOpenSpeedDial}
            onClose={handleCloseSpeedDial}
            FabProps={{ color: 'secondary' }}
          >
            <SpeedDialAction
              icon={<PieChartIcon />}
              tooltipTitle={'pie chart'}
              onClick={() => {
                handleCloseSpeedDial();
                props.changeView('compined');
              }}
            />
            <SpeedDialAction
              icon={<BarChartIcon />}
              tooltipTitle={'bar chart'}
              onClick={() => {
                handleCloseSpeedDial();
                props.changeView('grouped');
              }}
            />
            <SpeedDialAction
              icon={<ShowChartIcon />}
              tooltipTitle={'show chart'}
              onClick={() => {
                handleCloseSpeedDial();
                props.changeView('with_benchmarks');
              }}
            />
          </SpeedDial>
        </>
      ) : (
        <LoadingState />
      )}
    </div>
  );
};
export default CurrentInventory;
