import React, { useState, useEffect } from 'react';
import { rolesService } from '../../services/roles';
import { permissionsService } from '../../services/permissions';
import './RoleDialog.scss';
import RoleDialog from './RoleDialog';

const RoleBasicInfoDialogContainer = props => {
  const [role, setRole] = useState({
    id: props?.data?.id || undefined,
    name: props?.data?.name || '',
    permissions: props?.data?.permissions || [],
    employees: props?.data?.employees?.map(e => e.id) || []
  });
  const [isValid, setIsValid] = useState(false);
  const [allPermissions, setAllPermissions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: allPermissions } = await permissionsService.list();
      setAllPermissions(allPermissions);
    };

    fetchData();
  }, []);

  const reset = () => {
    setRole({
      id: undefined,
      name: '',
      permissions: [],
      employees: []
    });
    setIsValid(false);
  };

  const handleNameChange = e => {
    const updatedRole = { ...role, [e.target.name]: e.target.value };
    let isValid = false;
    if (checkValidity(updatedRole)) {
      isValid = true;
    }
    setRole(updatedRole);
    setIsValid(isValid);
  };

  const handlePermissionsChange = permissions => {
    const modeledPermissions = permissions.map(per => ({ name: per }));
    const updatedRole = { ...role, permissions: modeledPermissions };
    let isValid = false;
    if (checkValidity(updatedRole)) {
      isValid = true;
    }
    setRole(updatedRole);
    setIsValid(isValid);
  };

  const handleEmployeesChange = value => {
    const empIds = value.map(emp => +emp.id);
    const updatedRole = { ...role, employees: empIds };
    let isValid = false;
    if (checkValidity(updatedRole)) {
      isValid = true;
    }
    setRole(updatedRole);
    setIsValid(isValid);
  };

  const checkValidity = role => {
    if (role.name?.trim() !== '' && role.permissions?.length !== 0) {
      return true;
    }
    return false;
  };

  const cancel = () => {
    reset();
    props.closeDialog();
  };

  const saveRole = async () => {
    const data = { ...role, name: role.name?.trim() };
    if (data.id && data.id !== null && data.id !== undefined) {
      try {
        await rolesService.update(data);
        reset();
        props.onRoleCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onRoleCreate('fail', errorMessage);
      }
    } else {
      try {
        await rolesService.add(data);
        reset();
        props.onRoleCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onRoleCreate('fail', errorMessage);
      }
    }
  };

  return (
    <RoleDialog
      role={role}
      isValid={isValid}
      cancel={cancel}
      saveRole={saveRole}
      allPermissions={allPermissions}
      handleNameChange={handleNameChange}
      handlePermissionsChange={handlePermissionsChange}
      handleEmployeesChange={handleEmployeesChange}
    />
  );
};

export default RoleBasicInfoDialogContainer;
