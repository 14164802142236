import React from 'react';
import icon from '../../../assets/img/icon.png';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
const TopNav = props => {
  return (
    <nav id="TopNav">
      <div className="topNavRow">
        <div>
          <IconButton color="inherit" onClick={props.menuButtonClicked}>
            <MenuIcon />
          </IconButton>
          <p id="title">Aspire Portal</p>
        </div>
        <div className="spacer" />
        <div>
          <img style={{ width: '2em' }} src={icon} alt="" />
        </div>
      </div>
    </nav>
  );
};
export default TopNav;
