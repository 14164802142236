import StripedDataGrid from 'components/Grid/StripedDataGrid';

const FeedbackList = props => {
  return (
    <StripedDataGrid
      sortModel={props?.sortModel}
      autoWidth
      rows={props?.feedbacks}
      columns={props?.columns}
      onSortModelChange={props?.onSortModelChange}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
            page: props?.page - 1
          }
        }
      }}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      disableRowSelectionOnClick
      onRowClick={params => props?.renderDetails(params.row.id, params.row.status)}
      getRowClassName={params => {
        const rowClass = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
        const statusClass = params.row.status === 'pending' ? 'pending' : '';
        return `${rowClass} ${statusClass}`.trim();
      }}
      onPaginationModelChange={params => {
        props?.setPage(params?.page + 1);
      }}
    />
  );
};
export default FeedbackList;
