import { axiosInstances } from '../../../../utilities/axios/Instances/axios.instances';
// type BenchmarkValue = 'availability_india' | 'availability_jordan' | 'market_demand';
// const MAPPER_SETTINGS = {
//     BENCHMARKS_KEY: 'benchmarks',
//     BENCHMARKS: {
//       AVAILABILITY_INDIA_KEY: 'availability_india',
//       AVAILABILITY_JORDAN_KEY: 'availability_jordan',
//       MARKET_DEMAND_KEY: 'market_demand'
//     }
//   };
// const mapToBenchmarksArray = (object: Skill) =>
// Object.values(MAPPER_SETTINGS.BENCHMARKS).map((benchmark) => ({ name: benchmark, value: object!.benchmarks![benchmark as BenchmarkValue] }));
const update = (data, id) => {
  return axiosInstances.withAuthToken().patch(`/skills/${id}`, data);
};
const add = data => {
  return axiosInstances.withAuthToken().post('/skills', data);
};
const deleteSkill = id => {
  return axiosInstances.withAuthToken().delete(`/skills/${id}`);
};
const get = async id => {
  const { data } = await axiosInstances.withAuthToken().get(`/skills/${id}`);
  return data;
};
const audit = id => {
  return axiosInstances.withAuthToken().get(`/skills/${id}/audit`);
};
export { update, add, deleteSkill, get, audit };
