import { TagsService } from 'modules/shared/services/tags-service';
import React, { useEffect, useState } from 'react';
import Tags from './Tags';
import './Tags.scss';

const TagsContainer = props => {
  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const { data: allTags } = await TagsService.search();
      const filteredTags = allTags.filter(tag => props.tags.includes(tag.id));
      setAllTags(allTags);
      setTags(filteredTags);
      setIsLoading(false);
    };

    fetchData();
  }, [props.tags]);

  //this used to handle the case when both the tags and excluded props change.
  useEffect(() => {
    const checkNextProps = async nextProps => {
      if (nextProps.tags.toString() !== props.tags.toString()) {
        const { data: allTagsData } = await TagsService.search();
        const filteredTags = allTagsData.filter(tag => nextProps.tags.includes(tag.id));
        setAllTags(allTagsData);
        setTags(filteredTags);
        setIsLoading(false);
      }
    };

    checkNextProps(props);
  }, [props.tags]);

  return (
    <Tags
      placeholder={props.placeholder}
      showPrefix={props.showPrefix}
      maxCount={props.maxCount}
      allTags={allTags}
      isLoading={isLoading}
      tags={tags}
      handleTagChange={props.handleTagChange}
      selectable={props.selectable}
      removable={props.removable}
      editable={props.editable}
      dense={props.dense}
    />
  );
};

TagsContainer.defaultProps = {
  placeholder: 'Tags',
  showPrefix: true,
  maxCount: 0,
  excluded: [],
  selectable: false,
  removable: false,
  editable: true,
  dense: false,
  tags: []
};

export default TagsContainer;
