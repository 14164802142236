import { useEffect, useState } from 'react';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import { RoadmapsService } from '../../services/roadmaps';
import SearchBarCotainer from 'modules/shared/components/top-bars/search-bar/SearchBarContainer';
import DetailsAuditSideContainer from 'modules/shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Roadmaps from './Roadmaps';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EmployeeChip from 'modules/shared/components/_employee-views/EmployeeChip/EmployeeChip';
import './roadmaps.scss';
const RoadmapsContainer = props => {
  const [data, setData] = useState([
    {
      id: 1,
      name: 'roadmap1',
      owners: [2396, 60, 2217, 531, 2085],
      description: 'this is roadmap1',
      tags: ['tag1', 'tag2', 'tag3'],
      startDate: '2021-07-05T15:30:43.370Z',
      endDate: '2021-09-08T15:30:43.370Z'
    },
    {
      id: 2,
      name: 'roadmap2',
      owners: [2396, 60, 2217, 531, 2085],
      description: 'this is roadmap2',
      tags: ['tag7', 'tag8', 'tag9'],
      startDate: '2020-06-05T15:30:43.370Z',
      endDate: '2020-08-08T15:30:43.370Z'
    },
    {
      id: 3,
      name: 'roadmap3',
      owners: [2396, 60, 2217],
      description: 'this is roadmap3',
      tags: ['tag1', 'tag2', 'tag3', 'tag4'],
      startDate: '2021-02-05T15:30:43.370Z',
      endDate: '2021-08-08T15:30:43.370Z'
    },
    {
      id: 4,
      name: 'roadmap4',
      owners: [2396, 60, 2217, 531, 2085],
      description: 'this is roadmap4',
      tags: ['tag4', 'tag5', 'tag6'],
      startDate: '2020-03-05T15:30:43.370Z',
      endDate: '2020-06-08T15:30:43.370Z'
    },
    {
      id: 5,
      name: 'roadmap5',
      owners: [2396, 60, 2217, 531, 2085],
      description: 'this is roadmap5',
      tags: ['tag4', 'tag5'],
      startDate: '2021-05-05T15:30:43.370Z',
      endDate: '2021-07-08T15:30:43.370Z'
    }
  ]);
  const [audits, setAudits] = useState([]);
  const [lastFilter, setLastFilter] = useState('');
  const [sideData, setSideData] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    filterRoadmaps('', false);
  }, []);
  const filterRoadmaps = async (name = undefined, toggleLoading = true) => {
    if (toggleLoading) {
      setIsLoading(true);
    }
    const filter = {};
    if (name) filter.name = name;
    const { data } = await RoadmapsService.filter(filter);
    setData(data);
    setIsLoading(false);
    setLastFilter(name || '');
  };
  const openDialog = roadmap => {
    setIsDialogOpen(true);
    setDialogData(roadmap);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };
  const onRoadmapCreate = state => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Roadmap saved successfully');
      setIsDialogOpen(false);
      setDialogData(undefined);
      filterRoadmaps(lastFilter);
    } else {
      setShowErrorToast(true);
      setErrorToastText('Cannot save Roadmap');
    }
  };
  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };

  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };
  const confirmDelete = roadmap => {
    const toDelete = { id: roadmap.id, name: roadmap.name, type: 'Roadmap' };
    setToDelete(toDelete);
    setIsDeleteDialogOpen(true);
  };
  const closeDeleteDialog = async data => {
    if (data && data.id) {
      try {
        await RoadmapsService.deleteRoadmap(data.id);
        setShowSuccessToast(true);
        setSuccessToastText('Roadmap removed successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
        filterRoadmaps(lastFilter);
      } catch {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove Roadmap');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };
  const openSideContent = async roadmap => {
    setSideData(roadmap);
    try {
      const { data: audits } = await RoadmapsService.audit(roadmap.id);
      setAudits(audits);
    } catch {
      setAudits(audits); //ToDo: set a global error message
    }
  };
  const titleBar = (
    <SearchBarCotainer
      backRoute="/"
      title="Roadmaps"
      filter={filterRoadmaps}
      searchBarCommandPlaceholder={
        <>
          <Fab
            style={{ position: 'relative', top: '2em' }}
            onClick={() => openDialog()}
            color="secondary"
            aria-label="Add roadmap"
          >
            {' '}
            <AddIcon />{' '}
          </Fab>
          <Fab
            component={Link}
            to="/performance/roadmaps/wizard"
            size="small"
            style={{
              position: 'relative',
              top: '1.6em',
              backgroundColor: '#ffffff',
              marginLeft: '8px',
              fontSize: '20px'
            }}
          >
            <i className="mdi mdi-auto-fix mdi-20px"></i>
          </Fab>
        </>
      }
    />
  );
  const sideContent = closeDrawer => (
    <DetailsAuditSideContainer
      audit={audits}
      closeDrawer={closeDrawer}
      headerContent={
        <div
          className="DetailsAuditSideHeader"
          style={{
            display: 'flex',
            alignItems: 'center',
            placeContent: 'center flex-start',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          <div
            className="sideName"
            style={{
              font: '500 20px/32px',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {sideData?.name}
          </div>
        </div>
      }
      detailsContent={closeDrawer => (
        <div className="sideDetailsContent" style={{ maxWidth: '30em' }}>
          <div
            className="upperActions"
            style={{ display: 'flex', placeContent: 'center flex-end', marginRight: '0.25em' }}
          >
            <IconButton
              onClick={() => {
                openDialog(sideData);
                closeDrawer();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                confirmDelete(sideData);
                closeDrawer();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <div className="sideDetailsList" style={{ paddingTop: '8px', fontSize: '12px' }}>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Name
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.name}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0',
                textOverflow: 'ellipsis'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Description
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.description}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                minHeight: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Owners
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.owners?.map((owner, idx) => (
                  <EmployeeChip data={owner} key={idx} />
                ))}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0',
                textOverflow: 'ellipsis'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Start Date
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.startDate ? moment(sideData?.startDate).format('ll') : ''}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0',
                textOverflow: 'ellipsis'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                End Date
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.endDate ? moment(sideData?.endDate).format('ll') : ''}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0',
                textOverflow: 'ellipsis'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Tags
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.tags?.join(', ')}
              </span>
            </div>
          </div>
        </div>
      )}
    />
  );

  const mapData = item => {
    const { createdAt, updatedAt, meta, ...c } = item;
    c.tags = c.tags ? c.tags.map(a => ({ name: a })) : [];
    return c;
  };

  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={350}>
      <Roadmaps
        data={data}
        openDialog={openDialog}
        closeDialog={closeDialog}
        onRoadmapCreate={onRoadmapCreate}
        isDialogOpen={isDialogOpen}
        dialogData={dialogData}
        showSuccessToast={showSuccessToast}
        closeSuccessToast={closeSuccessToast}
        showErrorToast={showErrorToast}
        closeErrorToast={closeErrorToast}
        isDeleteDialogOpen={isDeleteDialogOpen}
        toDelete={toDelete}
        confirmDelete={confirmDelete}
        closeDeleteDialog={closeDeleteDialog}
        successToastText={successToastText}
        errorToastText={errorToastText}
        openSideContent={openSideContent}
        isLoading={isLoading}
      />
    </LayoutContainer>
  );
};

export default RoadmapsContainer;
