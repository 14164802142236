import './ShowMessage.scss';
const ShowMessage = ({ children }) => {
  return (
    <div className="feedbackConfirmationContainer">
      <div className="header">
        <div className="logo"></div>
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default ShowMessage;
