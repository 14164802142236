import React from 'react';
import Avatar from '@material-ui/core/Avatar';
const EmployeeDense = props => {
  return (
    <>
      {props.model ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'flex-start',
            flex: '1 0'
          }}
        >
          {/* <img style={{ marginRight: "0.75em", borderRadius: "50%" }} className="circle" src={props.model?.photoUrl} alt='' height="31" /> */}
          <Avatar
            alt={props.model?.firstName}
            src={props.model?.photoUrl}
            style={{ height: '31px', width: '31px', marginRight: '0.75em', fontSize: 'inherit' }}
          />
          {props.bold ? (
            <span
              className="matTitle"
              style={{ fontWeight: 500, fontSize: '20px', lineHeight: '32px' }}
            >
              {props.initials(props.model)}
            </span>
          ) : (
            <span className="matTitle">{props.initials(props.model)}</span>
          )}
        </div>
      ) : null}
    </>
  );
};
export default EmployeeDense;
