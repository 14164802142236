import React, { useEffect, useState } from 'react';
import SearchBarCotainer from 'modules/shared/components/top-bars/search-bar/SearchBarContainer';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import moment from 'moment';
import './ProjectList.scss';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { ProjectsService } from '../../services/projects';
import { clientsService } from 'modules/Administration/clients-manage/services/clients';
import Avatar from '@material-ui/core/Avatar';
import ProjectSideInfo from '../project-side-info/ProjectSideInfo';
import ProjectList from './ProjectList';

const ProjectListContainer = props => {
  const [projects, setProjects] = useState([]);
  const [audits, setAudits] = useState([]);
  const [stats, setStats] = useState({ count: 0, benched: 0 });
  const [client, setClient] = useState(undefined);
  const [lastFilter, setLastFilter] = useState('');
  const [sideData, setSideData] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const clientData = await clientsService.get(+props.match.params.id);
      setClient(clientData);
      filterProjects();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterProjects = async (name = '') => {
    setIsLoading(true);
    const projects = await ProjectsService.filter(+props.match.params.id, { name });
    setProjects(projects);
    setLastFilter(name);
    setIsLoading(false);
  };
  const openProjectDialog = project => {
    setIsDialogOpen(true);
    setDialogData(project);
  };
  const closeProjectDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };
  const onProjectCreate = (state = '', errorMessage) => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Project saved successfully');
      setIsDialogOpen(false);
      setDialogData(undefined);
      filterProjects(lastFilter);
    } else {
      setShowErrorToast(true);
      setErrorToastText(errorMessage);
    }
  };
  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };
  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };
  const closeDeleteDialog = async data => {
    if (data && data?.id) {
      try {
        await ProjectsService.deleteProject(data.id, +props.match.params.id);
        setShowSuccessToast(true);
        setSuccessToastText('Project removed successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
        filterProjects(lastFilter);
      } catch (error) {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove Project');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };
  const confirmDelete = project => {
    const toDelete = { id: project.id, name: project.name, type: 'Project' };
    setToDelete(toDelete);
    setIsDeleteDialogOpen(true);
  };
  const openSideContent = async project => {
    const { allocations } = project;
    const stats = {
      count: allocations.length,
      benched: allocations.reduce((acc, allocation) => (allocation.bench ? (acc += 1) : acc), 0)
    };
    const audits = [
      {
        user: project.createdBy,
        time: project.createdAt,
        action: 'Create'
      }
    ];
    if (project.updatedBy) {
      audits.push({
        user: project.updatedBy,
        time: project.updatedAt,
        action: 'Update'
      });
    }
    setAudits(audits);
    setSideData(project);
    setStats(stats);
  };
  const navigate = route => {
    props.history.push(route);
  };
  const getPercentage = data => {
    if (!data || !data.contract || !data.contract.start || !data.contract.end) return 0;
    const now = moment(),
      start = moment(data.contract.start),
      end = moment(data.contract.end);
    if (now < start) return 0;
    if (now >= end) return 1;
    const total = end.diff(start, 'days');
    const passed = now.diff(start, 'days');
    return passed / total;
  };
  const searchBarTitlePrefix = (
    <Avatar
      style={{
        backgroundColor: 'transparent',
        width: '1.5em',
        height: '1.5em',
        marginRight: '0.5em'
      }}
      alt={client?.name}
      src={client?.imgUrl}
    />
  );
  const searchBarCommandPlaceholder = (
    <Fab
      style={{ position: 'relative', top: '2em' }}
      onClick={() => openProjectDialog()}
      color="secondary"
      aria-label="Add Project"
    >
      {' '}
      <AddIcon />{' '}
    </Fab>
  );
  const titleBar = (
    <SearchBarCotainer
      backRoute="/admin/clients"
      title={client?.name}
      filter={filterProjects}
      searchBarCommandPlaceholder={searchBarCommandPlaceholder}
      searchBarTitlePrefix={searchBarTitlePrefix}
    ></SearchBarCotainer>
  );
  const sideContent = ProjectSideInfo({
    sideData,
    stats: stats,
    navigate: navigate,
    openProjectDialog: openProjectDialog,
    confirmDelete: confirmDelete,
    audits: audits
  });
  const drawerWidth = 350;
  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={drawerWidth}>
      <ProjectList
        projects={projects}
        openProjectDialog={openProjectDialog}
        closeProjectDialog={closeProjectDialog}
        onProjectCreate={onProjectCreate}
        isDialogOpen={isDialogOpen}
        showSuccessToast={showSuccessToast}
        closeSuccessToast={closeSuccessToast}
        showErrorToast={showErrorToast}
        closeErrorToast={closeErrorToast}
        dialogData={dialogData}
        isDeleteDialogOpen={isDeleteDialogOpen}
        closeDeleteDialog={closeDeleteDialog}
        confirmDelete={confirmDelete}
        toDelete={toDelete}
        successToastText={successToastText}
        errorToastText={errorToastText}
        openSideContent={openSideContent}
        navigate={navigate}
        getPercentage={getPercentage}
        isLoading={isLoading}
      />
    </LayoutContainer>
  );
};

export default ProjectListContainer;
