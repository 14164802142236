import React, { useState, useEffect } from 'react';
import { SkillsService } from 'modules/shared/services/skills-service';
import SkillChipList from './SkillChipList';
import './SkillChipList.scss';

const SkillChipListContainer = props => {
  const [skills, setSkills] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const allSkillsData = await SkillsService.filter();
      const filteredSkills = allSkillsData.filter(skill => props.skills.includes(skill.id));
      setAllSkills(allSkillsData);
      setSkills(filteredSkills);
      setIsLoading(false);
    };

    fetchData();
  }, [props.skills]);

  return (
    <SkillChipList
      {...{
        ...props,
        skills: skills,
        allSkills: allSkills,
        isLoading: isLoading
      }}
    />
  );
};

SkillChipListContainer.defaultProps = {
  placeholder: 'Skills',
  showPrefix: true,
  maxCount: 0,
  skills: [],
  selectable: false,
  removable: false,
  editable: true,
  dense: false
};
export default SkillChipListContainer;
