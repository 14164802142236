import React, { useEffect, useState } from 'react';
import EmployeeDense from './EmployeeDense';
import { EmployeesService } from '../../../services/employees';

const EmployeeDenseContainer = props => {
  const [model, setModel] = useState(undefined);

  const initials = emp => {
    return props.initialsOnly
      ? emp
        ? `${emp.firstName[0]}${emp.lastName[0]}`.toUpperCase()
        : undefined
      : getName(emp);
  };

  const getName = emp => {
    return emp ? `${emp.firstName} ${emp.lastName}` : undefined;
  };

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      const { data: employee } = await EmployeesService.getEmpByID(props.data);
      setModel(employee);
    };
    fetchEmployeeDetails();
  }, [props.data]);

  return (
    <>
      <EmployeeDense model={model} getName={getName} initials={initials} bold={props.bold} />
    </>
  );
};

EmployeeDenseContainer.defaultProps = {
  initialsOnly: true,
  bold: false
};

export default EmployeeDenseContainer;
