import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from '../../../../../components/Layout/Footer/Footer';
import SideNavContainer from '../../../../../components/Layout/SideNav/SideNavContainer';
import TopNavContainer from '../../../../../components/Layout/TopNav/TopNavContainer';
import TitleBarContainer from '../../top-bars/title-bar/TitleBarContainer';
import { withRouter } from 'react-router-dom';

const Layout = props => {
  const drawerWidth = props.drawerWidth ? props.drawerWidth : 321;
  const [open, setOpen] = React.useState(props.openDrawerAfterLoading);
  const inHomePage = props?.location?.pathname === '/';
  const useStyles = makeStyles(theme =>
    createStyles({
      root: {
        display: 'flex'
      },
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
      },
      appBarShift: {
        width: `calc(100% - ${
          typeof drawerWidth === 'number' ? drawerWidth + 'px' : drawerWidth
        }) !important`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: drawerWidth
      },
      title: {
        flexGrow: 1
      },
      hide: {
        display: 'none'
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        visibility: open ? 'visible' : 'hidden'
      },
      drawerPaper: {
        width: drawerWidth,
        top: 'auto',
        bottom: '0px',
        position: 'static',
        overflowX: 'hidden'
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start'
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        marginRight: -drawerWidth
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: 0
      },
      fixingContainer: {
        width: 'inherit',
        padding: '.5em 1em 0px 1em',
        position: 'fixed',
        height: '85.5vh',
        overflowY: 'auto',
        zIndex: '100',
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'transparent !important'
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent !important'
        }
      }
    })
  );
  const classes = useStyles();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const mainContent = React.Children.map(props.children, child => {
    if (React.isValidElement(child) && child.type !== 'div') {
      child = React.cloneElement(child, {
        openDrawer: handleDrawerOpen,
        closeDrawer: handleDrawerClose
      });
    }
    return child;
  });
  const sideContent =
    typeof props.sideContent === 'function'
      ? props.sideContent(handleDrawerClose)
      : props.sideContent;
  const titleBar =
    props.titleBar && React.isValidElement(props.titleBar) && props.titleBar.type !== React.Fragment
      ? React.cloneElement(props.titleBar, {
          openDrawer: handleDrawerOpen,
          closeDrawer: handleDrawerClose
        })
      : props.titleBar;
  return (
    <div id="LayoutOverlay2">
      <TopNavContainer menuButtonClicked={props.toggleSideNav} />
      <div id="main-division" className={inHomePage ? 'homePageStyle' : ''}>
        <div>
          <SideNavContainer show={props.sideNavActive} closeSideNav={props.toggleSideNav} />
          <div id="content">
            <div className={classes.root}>
              <CssBaseline />
              <div
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open
                })}
                style={{ height: '64px', position: 'absolute', width: '100%', zIndex: 1 }}
              >
                {titleBar ? (
                  titleBar
                ) : (
                  <TitleBarContainer
                    title={
                      props.title ? props.title : ` Welcome ${props?.currentUser?.firstName} !`
                    }
                  />
                )}
              </div>
              <main
                className={clsx(
                  classes.content,
                  {
                    [classes.contentShift]: open
                  },
                  open ? 'hideContent' : ''
                )}
              >
                <div className="fixingDiv" />
                {mainContent}
              </main>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                <div className={classes.fixingContainer}>{sideContent}</div>
              </Drawer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default withRouter(Layout);
