import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SideNav from './SideNav';
import { logoutAction } from '../../../store/actions';
import './SideNav.scss';
const SideNavContainer = props => {
  const logout = () => {
    props.logout();
    props.history.push('/login');
  };
  const hasPermission = permission => {
    if (props.userPermissions.findIndex(a => a && a.toLowerCase() === 'admin') >= 0) return true;
    if (
      props.userPermissions.length > 0 &&
      props.userPermissions.some(a => a && a.toLowerCase() === permission.toLowerCase())
    )
      return true;
    return false;
  };
  const showAdministrationLabel = () => {
    if (props.userPermissions.findIndex(a => a && a.toLowerCase() === 'admin') >= 0) return true;
    let adminstrationList = ['clients', 'skills', 'user roles', 'tags'];
    return adminstrationList.some(
      ap => props.userPermissions.findIndex(userp => userp.toLowerCase() === ap) >= 0
    );
  };
  const showReportLabel = () => {
    if (props.userPermissions.findIndex(a => a && a.toLowerCase() === 'admin') >= 0) return true;
    let reportList = ['reports_skills', 'reports_system'];
    return reportList.some(
      ap => props.userPermissions.findIndex(userp => userp.toLowerCase() === ap) >= 0
    );
  };
  const showPerformanceLabel = () => {
    if (props.userPermissions.findIndex(a => a && a.toLowerCase() === 'admin') >= 0) return true;
    let adminstrationList = ['initiatives', 'roadmaps'];
    return adminstrationList.some(
      ap => props.userPermissions.findIndex(userp => userp.toLowerCase() === ap) >= 0
    );
  };

  return (
    <>
      <SideNav
        show={props.show}
        currentUser={props.currentUser}
        logout={logout}
        showAdministrationLabel={showAdministrationLabel}
        showReportLabel={showReportLabel}
        hasPermission={hasPermission}
        closeSideNav={props.closeSideNav}
      />
    </>
  );
};

const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  isLoading: store.auth.isLoading,
  userPermissions: store.auth.userPermissions
});
const mapDispatchToProps = {
  logout: logoutAction
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNavContainer));
