import React, { useEffect } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
const PermissionsTree = props => {
  const toModel = (permission, parent) => {
    if (!permission.children || permission.children.length === 0)
      return { ...permission, parent: parent, children: [] };
    const childs = permission.children.map(childPer =>
      toModel(childPer, { ...permission, parent: parent })
    );
    return { ...permission, parent: parent, children: childs };
  };
  const allPermissions = props.allPermissions.map(per => toModel(per));
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  useEffect(() => {
    const oldPermissions = props.role?.permissions?.map(per => per.name) || [];
    setSelected(oldPermissions);
  }, [props.role?.permissions]);
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };
  const handleChange = node => {
    let selectedArr = [...selected];
    const toggleInclude = (node, level) => {
      if (selectedArr.includes(node.name)) {
        //if selected remove selection
        selectedArr = selectedArr.filter(permission => permission !== node.name);
        if (node.parent && selectedArr.includes(node.parent.name)) {
          //if parent selected remove the selection and check grandprarent...
          toggleInclude(node.parent, level + 1);
        }
        if (node.children && node.children.length > 0 && level <= 0) {
          //remove selection from all childs and all grand childs...
          node.children.forEach(
            ch => selectedArr.includes(ch.name) && toggleInclude(ch, level - 1)
          );
        }
      } else {
        selectedArr = [...selectedArr, node.name]; //add node
        if (
          node.parent &&
          !selectedArr.includes(node.parent.name) &&
          node.parent.children?.every(child => selectedArr.includes(child.name))
        ) {
          toggleInclude(node.parent, level + 1);
        }
        if (node.children && node.children.length > 0) {
          node.children.forEach(
            ch => !selectedArr.includes(ch.name) && toggleInclude(ch, level - 1)
          );
        }
      }
    };
    toggleInclude(node, 0);
    setSelected(selectedArr);
    props.handleChange(selectedArr);
  };
  const renderTreeNode = node => (
    <TreeItem
      key={node.name}
      nodeId={node.name}
      label={
        <FormControlLabel
          onClick={e => e.stopPropagation()}
          control={
            <Checkbox
              checked={selected.includes(node.name)}
              onChange={() => handleChange(node)}
              name={node.name}
              icon={
                node.children &&
                node.children.length > 0 &&
                !selected.includes(node.name) &&
                selected.some(per => node.children?.map(child => child.name).includes(per)) ? (
                  <IndeterminateCheckBoxIcon color="secondary" />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
            />
          }
          label={node.name}
        />
      }
      onLabelClick={event => event.preventDefault()}
    >
      {Array.isArray(node.children)
        ? node.children.map(childNode => renderTreeNode(childNode))
        : null}
    </TreeItem>
  );
  const renderTree = nodes =>
    nodes.map((node, idx) => (
      <TreeItem
        key={node.name}
        nodeId={node.name}
        label={
          <FormControlLabel
            onClick={e => {
              e.stopPropagation();
            }}
            control={
              <Checkbox
                checked={selected.includes(node.name)}
                icon={
                  node.children &&
                  node.children.length > 0 &&
                  !selected.includes(node.name) &&
                  selected.some(per => node.children?.map(child => child.name).includes(per)) ? (
                    <IndeterminateCheckBoxIcon color="secondary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )
                }
                name={node.name}
                onChange={() => handleChange(node)}
              />
            }
            label={node.name}
          />
        }
        onLabelClick={event => event.preventDefault()}
      >
        {Array.isArray(node.children)
          ? node.children.map(childNode => renderTreeNode(childNode))
          : null}
      </TreeItem>
    ));
  return (
    <div className="PermissionTree">
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
        disableSelection={true}
      >
        {renderTree(allPermissions)}
      </TreeView>
    </div>
  );
};
export default PermissionsTree;
