import React, { useEffect, useState } from 'react';
import { RoadmapsService } from '../../services/roadmaps';
import RoadmapDialog from './RoadmapDialog';
import './roadmapDialog.scss';
const RoadmapDialogContainer = props => {
  const [roadmap, setRoadmap] = useState({
    id: undefined,
    name: '',
    description: '',
    owners: [],
    startDate: undefined,
    endDate: undefined,
    tags: []
  });
  const [isValid, setIsValid] = useState(false);
  const [dateRangeError, setDateRangeError] = useState(false);

  const handleChange = (name, value) => {
    const updatedRoadmap = { ...roadmap, [name]: value };
    validation(updatedRoadmap, name);
    setRoadmap(updatedRoadmap);
  };

  const validation = (roadmap, validate) => {
    if (
      (validate === 'startDate' || validate === 'endDate') &&
      roadmap.startDate &&
      roadmap.endDate
    ) {
      if (new Date(roadmap.startDate).getTime() < new Date(roadmap.endDate).getTime()) {
        setDateRangeError(false);
      } else {
        setDateRangeError(true);
      }
    } else {
      setDateRangeError(false);
    }
    setIsValid(
      !dateRangeError &&
        roadmap.name?.trim() &&
        (roadmap.owners?.length || 0) > 0 &&
        roadmap.startDate &&
        roadmap.endDate
    );

    return { dateRangeError, isValid };
  };
  const save = async () => {
    const data = { ...roadmap, name: roadmap.name?.trim() };
    if (data && data.id !== null && data.id !== undefined) {
      try {
        await RoadmapsService.update(data);
        reset();
        props.onRoadmapCreate('success');
      } catch {
        props.onRoadmapCreate('fail');
      }
    } else {
      try {
        await RoadmapsService.add(data);
        reset();
        props.onRoadmapCreate('success');
      } catch (error) {
        props.onRoadmapCreate('fail');
      }
    }
  };
  const cancel = () => {
    reset();
    props.closeDialog();
  };
  const reset = () => {
    setRoadmap({
      id: undefined,
      name: '',
      description: '',
      owners: [],
      startDate: undefined,
      endDate: undefined,
      tags: []
    });
    setIsValid(false);
    setDateRangeError(false);
  };
  useEffect(() => {
    if (props.data) {
      setRoadmap(props.data);
      setIsValid(true);
      setDateRangeError(false);
    } else {
      reset();
    }
  }, []);

  return (
    <RoadmapDialog
      roadmap={props.data}
      isValid={isValid}
      dateRangeError={dateRangeError}
      cancel={cancel}
      save={save}
      handleChange={handleChange}
    />
  );
};
export default RoadmapDialogContainer;
