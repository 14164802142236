import React, { useEffect, useState } from 'react';
import { clientsService } from '../../services/clients';
import ClientDialog from './ClientDialog';
import './ClientDialog.scss';
const ClientDialogContainer = props => {
  const [isValid, setIsValid] = useState(false);
  const [client, setClient] = useState({
    id: props?.data?.id || undefined,
    name: props?.data?.name || '',
    imgUrl: props?.data?.imgUrl || '',
    signingDate: props?.data?.signingDate || new Date(),
    tags: props?.data?.tags || []
  });
  const reset = () => {
    setClient({
      id: undefined,
      name: '',
      imgUrl: '',
      signingDate: new Date(),
      tags: []
    });
    setIsValid(false);
  };
  const cancel = () => {
    reset();
    props.closeDialog();
  };
  const checkValidity = client => {
    const { id, ...c } = client;
    if (!(Object.values(c).includes('') || Object.values(c).includes(''))) return true;
    return false;
  };
  const handleChange = e => {
    const updatedClient = { ...client, [e.target.name]: e.target.value };
    setIsValid(false);
    if (checkValidity(updatedClient)) {
      setIsValid(true);
    }
    setClient(updatedClient);
  };
  const handleTagChange = tags => {
    // const modeledTgs = tags.map(tag=>tag.id!);
    // const updatedClient = { ...client, tags:modeledTgs };
    const updatedClient = { ...client, tags };
    setIsValid(false);
    if (checkValidity(updatedClient)) {
      setIsValid(true);
    }
    setClient(updatedClient);
  };
  const handleDateChange = date => {
    //if this caused an error when saving the client then convert the date to ISOString
    const updatedClient = { ...client, signingDate: date };
    setIsValid(false);
    if (checkValidity(updatedClient)) {
      setIsValid(true);
    }
    setClient(updatedClient);
  };
  const saveClient = async () => {
    const data = { ...client, name: client.name?.trim(), tags: client.tags?.map(tag => tag.id) };
    if (data.id && data.id !== null && data.id !== undefined) {
      try {
        const { id, ...rest } = data;
        // rest.tags = data.tags?.map(tag => tag.id);
        await clientsService.update(rest, id);
        reset();
        props.onClientCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onClientCreate('fail', errorMessage);
      }
    } else {
      try {
        await clientsService.add(data);
        reset();
        props.onClientCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onClientCreate('fail', errorMessage);
      }
    }
  };
  useEffect(() => {
    if (props.data) {
      setClient(props.data);
      setIsValid(true);
    } else {
      reset();
    }
  }, []);

  return (
    <ClientDialog
      client={client}
      isValid={isValid}
      handleChange={handleChange}
      handleTagChange={handleTagChange}
      cancel={cancel}
      saveClient={saveClient}
      handleDateChange={handleDateChange}
    />
  );
};

export default ClientDialogContainer;
