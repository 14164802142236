import React from 'react';
// import moment from "moment";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Calendar from 'modules/shared/components/_employee-views/Calendar/DatePicker/Calendar';
import EmployeeChipListContainer from 'modules/shared/components/_employee-views/EmployeeChipList/EmployeeChipListContainer';
const RoadmapDialog = props => {
  return (
    <div id="RoadmapDialog">
      <div className="RoadmapDialogTitle">
        {props.roadmap?.id ? props.roadmap.name : 'New Roadmap'}
      </div>
      <div className="RoadmapDialogContent">
        <TextField
          onChange={e => {
            props.handleChange(e.target.name, e.target.value);
          }}
          defaultValue={props.roadmap?.name}
          name="name"
          required
          autoFocus
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          fullWidth
        />
        <TextField
          onChange={e => {
            props.handleChange(e.target.name, e.target.value);
          }}
          defaultValue={props.roadmap?.name}
          name="description"
          autoFocus
          margin="dense"
          id="Name"
          label="Description"
          type="text"
          fullWidth
        />
        <EmployeeChipListContainer
          handleEmployeesChange={employees =>
            props.handleChange(
              'owners',
              employees.map(emp => emp.id)
            )
          }
          fullWidth={true}
          employees={props.roadmap?.owners || []}
        />
        <div className="formRow-50">
          <Calendar
            handleChange={date => props.handleChange('startDate', date.toISOString())}
            date={props.roadmap?.startDate}
            placeholder="Start Date"
            fullWidth={true}
          />
          <Calendar
            handleChange={date => props.handleChange('endDate', date.toISOString())}
            date={props.roadmap?.endDate}
            placeholder="End Date"
            fullWidth={true}
          />
        </div>
        {/* <TagsContainer  /> */}
      </div>

      <div className="RoadmapDialogActions">
        <Button onClick={props.cancel}>Cancel</Button>
        <Button onClick={props.save} disabled={!props.isValid} color="primary">
          {props.roadmap?.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
};
export default RoadmapDialog;
