import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const filter = async (filter = {}) => {
  const nameFilter = filter.name ? `name=${filter.name}` : '';
  // const {data:rawData} = await axiosInstances.withAuthToken().get<Roadmaps[]>(`/initiatives/?${nameFilter}`);
  // return rawData.map((roadMap:any)=> {const {priority, cost, ...r} = roadMap; return r as Roadmaps});
  return axiosInstances.withAuthToken().get(`/roadmap/?${nameFilter}`);
};
const deleteRoadmap = id => {
  return axiosInstances.withAuthToken().delete(`/roadmap/${id}`);
};
const add = data => {
  return axiosInstances.withAuthToken().post('/roadmap/', data);
};
const get = async id => {
  const { data: rawData } = await axiosInstances.withAuthToken().get(`/roadmap/${id}`);
  const { priority, cost, ...r } = rawData;
  return r;
};
const update = data => {
  return axiosInstances.withAuthToken().patch(`/roadmap/${data.id}`, data);
};
const audit = id => {
  return axiosInstances.withAuthToken().get(`/roadmap/${id}/audit`);
};
export const RoadmapsService = { filter, deleteRoadmap, add, get, update, audit };
