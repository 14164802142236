import { useState, useEffect } from 'react';
import { EmployeesService } from 'modules/shared/services/employees';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
const EmployeeChip = props => {
  const [model, setModel] = useState(undefined);

  useEffect(() => {
    loadData(+props.data);
  }, []);
  useEffect(() => {
    loadData(+props.data);
  }, [props.data]);

  const loadData = async data => {
    const { data: employee } = await EmployeesService.getEmpByID(data);
    setModel(employee);
  };
  const initials = emp => {
    return props.initialsOnly
      ? emp
        ? `${emp.firstName[0]}${emp.lastName[0]}`.toUpperCase()
        : undefined
      : getName(emp);
  };
  const getName = emp => {
    return emp ? `${emp.firstName} ${emp.lastName}` : undefined;
  };
  setModel(undefined);

  return model ? (
    <Chip
      label={initials(model)}
      avatar={<Avatar alt={model.firstName} src={model.photoUrl} />}
    ></Chip>
  ) : null;
};

EmployeeChip.defaultProps = {
  initialsOnly: true,
  bold: false
};
export default EmployeeChip;
