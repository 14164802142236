import { TextareaAutosize, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import './FeedbackDetails.scss';
const FeedbackDetails = props => {
  const questionsText = props.feedbackResponse.questionsFeedbackResponse?.filter(
    question => question?.questionType === 'text'
  );

  const questionsRating = props.feedbackResponse.questionsFeedbackResponse?.filter(
    question => question?.questionType === 'rating'
  );
  return (
    <div id="FeedbackDetails">
      <div className="employeeInfo">
        <div>Feedback For: {props?.feedbackResponse?.employeeName}</div>
      </div>

      <div className="feedbackResponseDetails">
        {questionsText &&
          questionsText?.map((question, index) => (
            <>
              <label className="questionLabel">
                {index + 1}) {question.questionDescription}
              </label>
              <TextareaAutosize
                aria-label="minimum height"
                className="noResize"
                disabled={true}
                value={question?.questionValue}
              />
            </>
          ))}
        {questionsRating &&
          questionsRating?.map((question, index) => (
            <>
              <label className="questionLabel">
                {index + 1}) {question?.questionDescription}
              </label>
              <FormControl disabled={true}>
                <RadioGroup
                  className="radioGroup"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name={`question-${question?.templateQuestionsId}`}
                  value={question?.questionValue}
                >
                  {question?.options?.map(({ value, name }) => (
                    <FormControlLabel key={value} value={name} control={<Radio />} label={name} />
                  ))}
                </RadioGroup>
              </FormControl>
            </>
          ))}
      </div>
    </div>
  );
};

export default FeedbackDetails;
