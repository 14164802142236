// this is not a React component its a function that returns a function that returns JSX.Element
import IconButton from '@material-ui/core/IconButton';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import { popupData } from "../../models/popupDara";
import EmployeeDenseContainer from 'modules/shared/components/_employee-views/EmployeeDense/EmployeeDenseContainer';
import moment from 'moment';
import SkillChipListContainer from 'modules/shared/components/skill-chip-list/SkillChipListContainer';
import TagsContainer from 'modules/shared/components/tags/TagsContainer';
import DetailsAuditSideContainer from 'modules/shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
const ProjectSideInfo = props => {
  const headerContent = (
    <div
      className="DetailsAuditSideHeader"
      style={{
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center flex-start',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
    >
      <div
        className="sideClientName"
        style={{
          font: '500 20px/32px',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {props.sideData?.name}
      </div>
    </div>
  );
  const detailsContent = closeDrawer => (
    <div className="sideDetailsContent" style={{ maxWidth: '30em' }}>
      <div
        className="upperActions"
        style={{ display: 'flex', placeContent: 'center flex-end', marginRight: '0.25em' }}
      >
        <IconButton
          onClick={() =>
            props.navigate(
              `/admin/clients/${props.sideData?.client?.id}/projects/${props.sideData?.id}`
            )
          }
        >
          <TimelapseIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            props.openProjectDialog(props.sideData);
            closeDrawer();
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            props.sideData && props.confirmDelete(props.sideData);
            closeDrawer();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="sideDetailsList" style={{ paddingTop: '8px', fontSize: '12px' }}>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Name
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {props.sideData?.name}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Code
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {props.sideData?.code}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Lead
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            <EmployeeDenseContainer
              initialsOnly={false}
              data={props.sideData?.leadId}
              key={props.sideData?.leadId}
            />
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Start
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {moment(props.sideData?.startDate).format('ll')}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            End
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {moment(props.sideData?.endDate).format('ll')}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Minimum resources
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {props.sideData?.minResource}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Maximum resources
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {props.sideData?.maxResource}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Current resources
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {props.stats.count || 0}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Benched resources
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {props.stats.benched || 0}
          </span>
        </div>
        {props.sideData?.skills && props.sideData?.skills?.length > 0 && (
          <div
            className="sideDetailsListItem"
            style={{
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 16px',
              flex: '1 0'
            }}
          >
            <SkillChipListContainer
              skills={props.sideData.skills.map(skill => skill.id)}
              editable={false}
              dense={true}
            />
          </div>
        )}
        {props.sideData && props.sideData?.tags?.length > 0 && (
          <div
            className="sideDetailsListItem"
            style={{
              minHeight: '48px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 16px',
              flex: '1 0'
            }}
          >
            <TagsContainer editable={false} tags={props.sideData.tags} />
          </div>
        )}
      </div>
    </div>
  );
  return closeDrawer => (
    <DetailsAuditSideContainer
      headerContent={headerContent}
      detailsContent={detailsContent}
      audit={props.audits}
      closeDrawer={closeDrawer}
    />
  );
};
export default ProjectSideInfo;
