import Avatar from 'react-avatar';
import { withRouter } from 'react-router';

const FeedbackCardContainer = props => {
  const navigate = () => {
    if (props.routerLink) {
      props.history.push(props.routerLink);
    }
  };

  const fullName = props.data ? `${props.data.firstName} ${props.data.lastName}` : undefined;
  const isCardActive = props.data.status === 'completed';
  return (
    <div id="FeedbackCard" className={props.isLastCard ? 'lastCard' : ''}>
      {props?.data && (
        <>
          <div
            className={`clickableCard ${isCardActive ? '' : 'disableCard'}`}
            onClick={() => {
              if (isCardActive) {
                return navigate();
              }
              return true;
            }}
          >
            <div className="empInfo">
              <div className="fullName">
                <span className="headline">{fullName}</span>
              </div>
              {props?.data?.photoUrl ? (
                <img className="avatar" src={props.data.photoUrl} alt="" />
              ) : (
                <Avatar className="avatar" name={fullName} />
              )}
              <div className="clientInfo">
                <span className="cLientName">Client Name: {props.data?.requestedName}</span>
                <span className="clientEmail">Client Email: {props.data?.requestedEmail}</span>
              </div>
              <div className="statusInfo">
                <span className={`${isCardActive ? 'statusCompleted ' : 'statusPending'}`}>
                  {props.data.status}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(FeedbackCardContainer);
