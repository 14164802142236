import axios from 'axios';
import store from 'store';
import { logoutAction } from 'store/actions';
let apiHost = 'http://localhost:8080/api/v1';
if (process.env.NODE_ENV === 'production') {
  apiHost = 'https://resources-utilization-api.herokuapp.com/api/v1';
}
const axiosWithToken = () => {
  const instance = axios.create({
    baseURL: apiHost,
    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
  });
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (err) {
      if (err.response?.status === 401) {
        store.dispatch(logoutAction());
      }
      return Promise.reject(err);
    }
  );
  return instance;
};
const axiosWithBaseUrl = () =>
  axios.create({
    baseURL: apiHost
  });
export const axiosInstances = { withAuthToken: axiosWithToken, withBaseUrl: axiosWithBaseUrl };
