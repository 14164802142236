import { axiosInstances } from '../../../utilities/axios/Instances/axios.instances';
const sortSkills = skills => {
  return skills.sort(function (a, b) {
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
};
const filter = async (filter = {}) => {
  const filters = [];
  if (filter && filter.name) filters.push(`name=${filter.name}`);
  if (filter && filter.group) filters.push(`group=${filter.group}`);
  if (filter && filter.department) filters.push(`department=${filter.department}`);
  const { data } = await axiosInstances.withAuthToken().get(`/skills/?${filters.join('&')}`);
  return sortSkills(data);
};

const getSkillsStatistics = id => {
  return axiosInstances.withAuthToken().get(`/skills/statistics`);
};

export const SkillsService = {
  filter,
  getSkillsStatistics
};
