import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import SearchBarCotainer from '../../../../shared/components/top-bars/search-bar/SearchBarContainer';
import LayoutContainer from '../../../../shared/components/_layout/layout-container/LayoutContainer';
import DetailsAuditSideContainer from '../../../../shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import RolesList from './RolesList';
import './RolesList.scss';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { rolesService } from '../../services/roles';
import EmployeeDenseContainer from '../../../../shared/components/_employee-views/EmployeeDense/EmployeeDenseContainer';

const RolesListContainer = ({ history }) => {
  const [roles, setRoles] = useState([]);
  const [audits, setAudits] = useState([]);
  const [lastFilter, setLastFilter] = useState('');
  const [sideData, setSideData] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const onFilter = async (name = '') => {
    setIsLoading(true);
    const roles = await rolesService.filter({ name });
    setRoles(roles);
    setLastFilter(name);
    setIsLoading(false);
  };

  const openRoleDialog = async role => {
    if (role) {
      setIsDialogOpen(true);
      setDialogData(role);
    } else {
      setIsDialogOpen(true);
      setDialogData(role);
    }
  };

  const closeRoleDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };

  const onRoleCreate = (state, errorMessage) => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Role saved successfully');
      setIsDialogOpen(false);
      setDialogData(undefined);
      onFilter(lastFilter);
    } else {
      setShowErrorToast(true);
      setErrorToastText(errorMessage);
    }
  };

  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };

  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };

  const closeDeleteDialog = async data => {
    if (data && data.id) {
      try {
        await rolesService.deleteRole(data.id);
        setShowSuccessToast(true);
        setSuccessToastText('Role removed successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
        onFilter(lastFilter);
      } catch {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove Role');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };

  const confirmDelete = role => {
    const toDelete = { id: role.id, name: role.name, type: 'Role' };
    setToDelete(toDelete);
    setIsDeleteDialogOpen(true);
  };

  const openSideContent = async role => {
    let audits = [
      {
        user: role.createdBy,
        time: role.createdAt,
        action: 'Create'
      }
    ];
    if (role.updatedBy) {
      audits.push({
        user: role.updatedBy,
        time: role.updatedAt,
        action: 'Update'
      });
    }
    setAudits(audits);
    setSideData(role);
  };

  useEffect(() => {
    onFilter();
  }, []);

  const searchBarCommandPlaceholder = (
    <Fab
      style={{ position: 'relative', top: '2em' }}
      onClick={() => openRoleDialog()}
      color="secondary"
      aria-label="Add role"
    >
      {' '}
      <AddIcon />{' '}
    </Fab>
  );

  const titleBar = (
    <SearchBarCotainer
      backRoute="/"
      title="Roles"
      filter={onFilter}
      searchBarCommandPlaceholder={searchBarCommandPlaceholder}
    ></SearchBarCotainer>
  );

  const headerContent = (
    <div
      className="DetailsAuditSideHeader"
      style={{
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center flex-start',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
    >
      <div
        className="sideRoleName"
        style={{
          font: '500 20px/32px',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {sideData?.name}
      </div>
    </div>
  );

  const detailsContent = closeDrawer => (
    <div className="sideDetailsContent" style={{ maxWidth: '30em' }}>
      <div
        className="upperActions"
        style={{ display: 'flex', placeContent: 'center flex-end', marginRight: '0.25em' }}
      >
        <IconButton
          onClick={() => {
            confirmDelete(sideData);
            closeDrawer();
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            openRoleDialog(sideData);
            closeDrawer();
          }}
        >
          <EditIcon />
        </IconButton>
      </div>
      <div className="sideDetailsList" style={{ paddingTop: '8px', fontSize: '12px' }}>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Permissions
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {'Total : ' + (sideData?.permissions?.length || 0)}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{ fontSize: '14px', maxHeight: '10.3em', padding: '0 16px', overflowY: 'auto' }}
        >
          {sideData?.permissions?.map((permission, idx) => (
            <div key={idx} style={{ fontSize: '12px', height: '48px', display: 'flex' }}>
              <span className="spacerSpan" style={{ flex: '1 0' }}></span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {permission.name}
              </span>
            </div>
          ))}
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Employees
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {'Total : ' + (sideData?.employees?.length || 0)}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{ fontSize: '14px', maxHeight: '10.3em', padding: '0 16px', overflowY: 'auto' }}
        >
          {sideData?.employees?.map(emp => (
            <div
              style={{
                fontSize: '12px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0'
              }}
              key={emp.id}
            >
              <span className="spacerSpan" style={{ flex: '1 0' }}></span>
              <EmployeeDenseContainer data={emp.id} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const sideContent = closeDrawer => (
    <DetailsAuditSideContainer
      headerContent={headerContent}
      detailsContent={detailsContent}
      audit={audits}
      closeDrawer={closeDrawer}
    />
  );

  const drawerWidth = 350;

  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={drawerWidth}>
      <RolesList
        roles={roles}
        openRoleDialog={openRoleDialog}
        closeRoleDialog={closeRoleDialog}
        onRoleCreate={onRoleCreate}
        isDialogOpen={isDialogOpen}
        showSuccessToast={showSuccessToast}
        closeSuccessToast={closeSuccessToast}
        showErrorToast={showErrorToast}
        closeErrorToast={closeErrorToast}
        dialogData={dialogData}
        isDeleteDialogOpen={isDeleteDialogOpen}
        closeDeleteDialog={closeDeleteDialog}
        confirmDelete={confirmDelete}
        toDelete={toDelete}
        successToastText={successToastText}
        errorToastText={errorToastText}
        openSideContent={openSideContent}
        isLoading={isLoading}
      />
    </LayoutContainer>
  );
};

export default withRouter(RolesListContainer);
