import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setSideOpenState } from '../../../../../store/actions';
import Layout from './Layout';
import './Layout.scss';

const LayoutContainer = props => {
  const [sideNavActive, setSideNavActive] = useState(false);

  const toggleSideNav = () => {
    setSideNavActive(!sideNavActive);
    props.setSideOpenState(false);
  };
  return (
    <>
      <Layout
        toggleSideNav={toggleSideNav}
        sideNavActive={sideNavActive}
        hasBackdrop={true}
        sideContent={props.sideContent}
        titleBar={props.titleBar}
        title={props.title}
        drawerWidth={props.drawerWidth}
        openDrawerAfterLoading={props.openDrawerAfterLoading}
        currentUser={props?.currentUser}
      >
        {props.children}
      </Layout>
    </>
  );
};

const mapDispatchToProps = {
  setSideOpenState: setSideOpenState
};
const mapStateToProps = store => ({
  currentUser: store.auth.currentUser
});
export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
