import { EmployeesService } from 'modules/shared/services/employees';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TimelineIcon from '@material-ui/icons/Timeline';
import Menu from 'modules/shared/components/_controls/Menu/Menu';
import Avatar from 'react-avatar';
import { useEffect, useState } from 'react';

const AllocationCard = props => {
  const [resource, setResource] = useState({});

  useEffect(() => {
    loadResourceData(props?.data?.id);
  }, [props?.resource?.id]);

  const loadResourceData = async id => {
    const { data: resourceData } = await EmployeesService.getEmpByID(id);
    setResource(resourceData);
  };
  return (
    <div className="resourceCard">
      <div className="resourceCardHeader">
        <div className="resourceCardTitle">
          <div className="allocationCard">
            {props?.model?.photoUrl ? (
              <img className="resourceAvatar" src={resource?.photoUrl} alt="" />
            ) : (
              <Avatar
                className="resourceAvatar"
                name={`${resource?.firstName} ${resource?.lastName}`}
              />
            )}
            <div className="resourceInfo">
              <span className="name">
                {resource?.firstName} {resource?.lastName}
              </span>
              {props?.data?.title && <span className="title">{props?.data?.title}</span>}
            </div>
          </div>
        </div>
        <div className="menuContainer">
          <Menu>
            <div
              className="CustommenuItem"
              onClick={() => {
                props.openSideContent(props?.data);
                props.openDrawer?.();
              }}
            >
              <InfoIcon />
              <span style={{ marginLeft: '16px' }}>Info</span>
            </div>
            <div className="CustommenuItem" onClick={() => props.openAllocationDialog(props?.data)}>
              <EditIcon />
              <span style={{ marginLeft: '16px' }}>Edit</span>
            </div>
            <div className="CustommenuItem" onClick={() => props.confirmDelete(props?.data)}>
              <DeleteIcon />
              <span style={{ marginLeft: '16px' }}>Delete</span>
            </div>
          </Menu>
        </div>
      </div>
      <div className="resourceCardContent">
        <div className="resourceEffort">
          <TimelineIcon style={{ marginRight: '5px' }} />
          {props.data.efforts && props.data.efforts.length > 0
            ? props.getAverage(props?.data?.efforts) + '%'
            : '0%'}
        </div>
      </div>
    </div>
  );
};
export default AllocationCard;
