import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const getTemplates = () => {
  return axiosInstances.withAuthToken().get('/templates');
};
const getClients = () => {
  return axiosInstances.withAuthToken().get('/clients');
};
const getTemplateById = id => {
  return axiosInstances.withAuthToken().get(`/templates/${id}`);
};
const getEmployeeById = id => {
  return axiosInstances.withAuthToken().get(`/employees/${id}`);
};
const getAllFeedbacks = () => {
  return axiosInstances.withAuthToken().get(`/feedback`);
};
const createFeedback = data => {
  return axiosInstances.withAuthToken().post(`/feedback`, data);
};

const getFeedbackResponse = id => {
  return axiosInstances.withAuthToken().get(`/feedback/${id}/response`);
};
const feedbackAuthenticate = data => {
  return axiosInstances.withAuthToken().post(`/feedback/authenticate`, data);
};
const getFeedbackById = id => {
  return axiosInstances.withAuthToken().get(`/feedback/${id}`);
};

const createFeedbackResponse = (feedbackId, data) => {
  return axiosInstances.withAuthToken().post(`/feedback/${feedbackId}/response`, data);
};
export const feedbackService = {
  getTemplates,
  getClients,
  getTemplateById,
  getEmployeeById,
  getAllFeedbacks,
  createFeedback,
  getFeedbackResponse,
  feedbackAuthenticate,
  getFeedbackById,
  createFeedbackResponse
};
