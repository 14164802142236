import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const toModel = item => {
  const { __v, _lastUpdatedBy, ...c } = item;
  return c;
};
const filter = async filter => {
  const nameFilter = filter.name ? `name=${filter.name}` : '';
  const { data: clients } = await axiosInstances.withAuthToken().get(`/clients/?${nameFilter}`);
  return clients.map(client => toModel(client));
};
const update = (data, id) => {
  return axiosInstances.withAuthToken().patch(`/clients/${id}`, data);
};
const add = data => {
  return axiosInstances.withAuthToken().post('/clients/', data);
};
const deleteClient = id => {
  return axiosInstances.withAuthToken().delete(`/clients/${id}`);
};
const get = async id => {
  const { data: client } = await axiosInstances.withAuthToken().get(`/clients/${id}`);
  return toModel(client);
};
export const clientsService = { filter, update, add, deleteClient, get };
