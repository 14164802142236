import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
const TagInfoDialog = props => {
  const [tagFieldsProps, setTagFieldsProps] = useState({
    name: { error: false, helperText: '' }
  });

  const handleBlur = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value?.trim() === '') {
      setTagFieldsProps({
        ...tagFieldsProps,
        [name]: { error: true, helperText: 'Field must not be empty' }
      });
    } else {
      setTagFieldsProps({
        name: { error: false, helperText: '' }
      });
    }
  };
  return (
    <div id="TagInfoDialog">
      <div className="TagDialogTitle">{props.tag.id ? props.tag.name : 'New Tag'}</div>

      <div className="TagDialogContent">
        <FormControl style={{ maxWidth: '30%', marginRight: '0.75em', flex: '1 1 100%' }}>
          <InputLabel htmlFor="Color">Color</InputLabel>
          <Input
            onChange={e => {
              props.handleChange(e);
            }}
            value={props.tag.color}
            name="color"
            required
            autoFocus
            margin="dense"
            id="Color"
            type="color"
          />
        </FormControl>
        <TextField
          onChange={e => {
            props.handleChange(e);
          }}
          value={props.tag.name}
          name="name"
          required
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          style={{ flex: '1 1 0%' }}
          onBlur={handleBlur}
          {...tagFieldsProps?.name}
        />
      </div>

      <div className="TagDialogActions">
        <Button onClick={props.cancel}>Cancel</Button>
        <Button onClick={props.createTag} disabled={!props.isValid} color="primary">
          {props.tag.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
};
export default TagInfoDialog;
