import React from 'react';
import './indeterminate.scss';
const Indeterminate = () => {
  return (
    <div className="indeterminate-progress-bar-container">
      <div className="indeterminate-progress-bar">
        <div className="indeterminate-progress-bar-value"></div>
      </div>
    </div>
  );
};
export default Indeterminate;
