import { useState } from 'react';
import Questions from './Questions/Questions';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { feedbackService } from 'modules/Feedback/services/feedback';
import './Template.scss';
const Template = ({ template, feedbackId }) => {
  const [loading, setLoading] = useState(null);
  const history = useHistory();
  const questionsText = template?.questions?.filter(question => question?.questionType === 'text');
  const questionsRating = template?.questions?.filter(
    question => question?.questionType === 'rating'
  );

  const [answers, setAnswers] = useState({});
  const reshapeAnswers = answers => {
    return Object.entries(answers).map(([questionId, value]) => ({
      questionId: parseInt(questionId),
      value: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const questionsAnswers = reshapeAnswers(answers);
    try {
      setLoading(true);
      const response = await feedbackService.createFeedbackResponse(feedbackId, questionsAnswers);
      history.push(`/response-confirmation`);
      localStorage.removeItem('idToken');
      return response;
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: value
    }));
  };
  return (
    <div className="templateContainer">
      <h2 className="templateName">
        {feedbackId ? `Feedback For: ${template?.employeeName}` : template.name}
      </h2>
      <div className="questionsContainer">
        <form className="formQuestions" onSubmit={handleSubmit}>
          {template && (
            <Questions
              questionsText={questionsText}
              questionsRating={questionsRating}
              feedbackId={feedbackId}
              handleAnswerChange={handleAnswerChange}
              answers={answers}
            />
          )}
          {feedbackId && (
            <Button
              className={loading ? 'loadingBtn showBtn' : 'showBtn'}
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Template;
