import { axiosInstances } from '../../../utilities/axios/Instances/axios.instances';
let allEmployees = [];
let timeIntervalFunc = undefined;
export const stopInterval = () => {
  if (timeIntervalFunc !== undefined) {
    clearInterval(timeIntervalFunc);
  }
};
const sortEmployees = employees => {
  return employees.sort(function (a, b) {
    let x = a.firstName.toLowerCase(); //(a.firstName + a.lastName).toLowerCase()
    let y = b.firstName.toLowerCase(); //(b.firstName + b.lastName).toLowerCase()
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
};
const getEmployees = async () => {
  let { data: employees } = await axiosInstances.withAuthToken().get('/employees/');
  timeIntervalFunc = setInterval(async () => {
    const { data } = await axiosInstances.withAuthToken().get('/employees/');
    allEmployees = sortEmployees(data);
  }, 1000 * 60 * 60);
  allEmployees = sortEmployees(employees);
  return allEmployees;
};
const filter = async (name = '') => {
  const employees = await getEmployees();
  return employees.filter(
    employee =>
      `${employee.firstName} ${employee.lastName}`
        .toLowerCase()
        .indexOf((name || '').toLowerCase()) > -1
  );
};
const getEmpByID = id => {
  return axiosInstances.withAuthToken().get(`/employees/${id}`);
};
const getProjectsAsLead = id => {
  return axiosInstances.withAuthToken().get(`/employees/${id}/allocations/?role=lead`);
};
const getProjectsAsMember = id => {
  return axiosInstances.withAuthToken().get(`/employees/${id}/allocations/`);
};
const getSkills = async id => {
  const { data: empSkills } = await axiosInstances.withAuthToken().get(`/employees/${id}/skills/`);
  return empSkills.skills;
};
const getExpRanges = () => {
  return axiosInstances.withAuthToken().get(`/employees/experiences`);
};
const updateSkills = (id, data) => {
  return axiosInstances.withAuthToken().post(`/employees/${id}/skills/`, data);
};
const uploadEmployees = data => {
  return axiosInstances.withAuthToken().post(`/employees/upload`, data);
};

export const EmployeesService = {
  getEmpByID,
  getProjectsAsLead,
  getProjectsAsMember,
  getSkills,
  updateSkills,
  getEmployees,
  filter,
  getExpRanges,
  uploadEmployees
};
