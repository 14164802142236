import { TextareaAutosize } from '@mui/material';
const QuestionsText = ({ question, index, feedbackId, value, handleAnswerChange }) => {
  const handleChange = e => {
    handleAnswerChange(question?.questionId, e.target.value);
  };
  return (
    <>
      <label className="questionLabel">
        {index}) {question.questionDescription}
      </label>
      <TextareaAutosize
        aria-label="minimum height"
        minRows={3}
        className="noResize"
        disabled={feedbackId === null}
        value={value || ''}
        onChange={handleChange}
      />
    </>
  );
};

export default QuestionsText;
