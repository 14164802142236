import React, { useEffect, useState } from 'react';
import { EmployeesService } from '../../../services/employees';
import EmployeeChipList from './EmployeeChipList';

const EmployeeChipListContainer = props => {
  const [employees, setEmployees] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEmployeesData = async () => {
      const allEmployeesData = await EmployeesService.getEmployees();
      const filteredAllEmployees = allEmployeesData.filter(
        emp => !props.excluded.includes(+emp.id)
      );
      const filteredEmployees = filteredAllEmployees.filter(emp =>
        props.employees.includes(+emp.id)
      );
      setAllEmployees(filteredAllEmployees);
      setEmployees(filteredEmployees);
      setIsLoading(false);
    };
    fetchEmployeesData();
  }, [props.excluded, props.employees]);

  return (
    <EmployeeChipList
      name={props?.name}
      placeholder={props.placeholder}
      showPrefix={props.showPrefix}
      maxCount={props.maxCount}
      allEmployees={allEmployees}
      isLoading={isLoading}
      employees={employees}
      handleEmployeesChange={props.handleEmployeesChange}
      multiple={props.multiple}
      fullWidth={props.fullWidth}
      required={props?.required}
      onBlur={props?.onBlur}
      error={props?.error}
    />
  );
};

EmployeeChipListContainer.defaultProps = {
  placeholder: 'Employees',
  showPrefix: true,
  maxCount: 0,
  excluded: [],
  employees: [],
  selectable: false,
  removable: false,
  multiple: true,
  fullWidth: false
};

export default EmployeeChipListContainer;
