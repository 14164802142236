import React, { useState, useEffect } from 'react';
import SearchBarCotainer from 'modules/shared/components/top-bars/search-bar/SearchBarContainer';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import DetailsAuditSideContainer from 'modules/shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import ClientsList from './ClientsList';
import './ClientsList.scss';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import WorkIcon from '@material-ui/icons/Work';
import { clientsService } from '../../services/clients';
import IconButton from '@material-ui/core/IconButton';
import TagsContainer from 'modules/shared/components/tags/TagsContainer';
const ClientsListContainer = (props, state) => {
  const [clients, setClients] = useState([]);
  const [audits, setAudits] = useState([]);
  const [lastFilter, setLastFilter] = useState('');
  const [sideData, setSideData] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    filterClients(lastFilter);
  }, [lastFilter]);
  const filterClients = async (name = '') => {
    setIsLoading(true);
    const clients = await clientsService.filter({ name });
    setClients(clients);
    setLastFilter(name);
    setIsLoading(false);
  };
  const openClientDialog = client => {
    setIsDialogOpen(true);
    setDialogData(client);
  };
  const closeClientDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };
  const onClientCreate = (state, errorMessage) => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Client saved successfully');
      closeClientDialog();
      filterClients(lastFilter);
    } else {
      setShowErrorToast(true);
      setErrorToastText(errorMessage);
    }
  };
  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };
  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };
  const closeDeleteDialog = async dat => {
    if (dat && dat.id) {
      try {
        await clientsService.deleteClient(dat.id);
        setShowSuccessToast(true);
        setSuccessToastText('Client removed successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
        filterClients(lastFilter);
      } catch {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove Client');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };
  const confirmDelete = client => {
    setToDelete({ id: client.id, name: client.name, type: 'Client' });
    setIsDeleteDialogOpen(true);
  };
  const openSideContent = async client => {
    let audits = [
      {
        user: client.createdBy,
        time: client.createdAt,
        action: 'Create'
      }
    ];
    if (client.updatedBy) {
      audits.push({
        user: client.updatedBy,
        time: client.updatedAt,
        action: 'Update'
      });
    }
    setAudits(audits);
    setSideData(client);
  };
  const navigate = route => {
    props.history.push(route);
  };

  const searchBarCommandPlaceholder = (
    <Fab
      style={{ position: 'relative', top: '2em' }}
      onClick={() => openClientDialog()}
      color="secondary"
      aria-label="Add tag"
    >
      {' '}
      <AddIcon />{' '}
    </Fab>
  );
  const titleBar = (
    <SearchBarCotainer
      backRoute="/"
      title="Clients"
      filter={filterClients}
      searchBarCommandPlaceholder={searchBarCommandPlaceholder}
    ></SearchBarCotainer>
  );
  const headerContent = (
    <div
      className="DetailsAuditSideHeader"
      style={{
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center flex-start',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
    >
      {sideData?.imgUrl && (
        <img
          className="SideClientImage"
          src={sideData.imgUrl}
          alt={sideData.name}
          style={{ marginRight: '0.75em', height: '3em', borderRadius: '50%' }}
        />
      )}
      <div
        className="sideClientName"
        style={{
          font: '500 20px/32px',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {sideData?.name}
      </div>
    </div>
  );
  const detailsContent = closeDrawer => (
    <div className="sideDetailsContent" style={{ maxWidth: '30em' }}>
      <div
        className="upperActions"
        style={{ display: 'flex', placeContent: 'center flex-end', marginRight: '0.25em' }}
      >
        <IconButton onClick={() => navigate(`/admin/clients/${sideData?.id}/projects`)}>
          <WorkIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            openClientDialog(sideData);
            closeDrawer();
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            confirmDelete(sideData);
            closeDrawer();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="sideDetailsList" style={{ paddingTop: '8px', fontSize: '12px' }}>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Name
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {sideData?.name}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Signing Date
          </span>
          {sideData && sideData.signingDate && (
            <span className="liCaption" style={{ flex: '1 0' }}>
              {new Date(sideData.signingDate).toDateString()}
            </span>
          )}
        </div>
        {sideData && sideData?.tags?.length !== 0 && (
          <div
            className="sideDetailsListItem"
            style={{
              minHeight: '48px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 16px',
              flex: '1 0'
            }}
          >
            <TagsContainer editable={false} tags={sideData.tags.map(tag => tag.id)} />
          </div>
        )}
      </div>
    </div>
  );
  const sideContent = closeDrawer => (
    <DetailsAuditSideContainer
      headerContent={headerContent}
      detailsContent={detailsContent}
      audit={audits}
      closeDrawer={closeDrawer}
    />
  );
  const drawerWidth = 350;
  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={drawerWidth}>
      <ClientsList
        clients={clients}
        openClientDialog={openClientDialog}
        closeClientDialog={closeClientDialog}
        onClientCreate={onClientCreate}
        isDialogOpen={isDialogOpen}
        showSuccessToast={showSuccessToast}
        closeSuccessToast={closeSuccessToast}
        showErrorToast={showErrorToast}
        closeErrorToast={closeErrorToast}
        dialogData={dialogData}
        isDeleteDialogOpen={isDeleteDialogOpen}
        closeDeleteDialog={closeDeleteDialog}
        confirmDelete={confirmDelete}
        toDelete={toDelete}
        successToastText={successToastText}
        errorToastText={errorToastText}
        openSideContent={openSideContent}
        navigate={navigate}
        isLoading={isLoading}
      />
    </LayoutContainer>
  );
};
export default ClientsListContainer;
