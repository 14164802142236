import { useEffect, useState } from 'react';
import EmployeeAllSkills from './EmployeesAllSkills';
import './EmployeesAllSkills.scss';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import TitleBarContainer from 'modules/shared/components/top-bars/title-bar/TitleBarContainer';
import { SkillsService } from 'modules/shared/services/skills-service';
import { EmployeesService } from 'modules/shared/services/employees';

const EmployeeSkillsContainer = props => {
  const savedSortModel = localStorage.getItem('dataGridSort');
  const [sortModel, setSortModel] = useState(savedSortModel ? JSON.parse(savedSortModel) : []);
  const handleSortChange = newSortModel => {
    setSortModel(newSortModel);
    localStorage.setItem('dataGridSort', JSON.stringify(newSortModel));
  };
  const savedPage = sessionStorage.getItem('empSkills.currentPage');
  const [page, setPage] = useState(savedPage ? parseInt(savedPage) : 1);
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterForm, setFilterForm] = useState({
    employees: []
  });
  const [skills, setSkills] = useState([]);
  const navigate = route => {
    props.history.push(route, { prevPathname: props?.location?.pathname });
  };
  const handleEmployeeChange = (event, value) => {
    setFilterForm(prevFilterForm => ({
      ...prevFilterForm,
      employees: value
    }));
  };
  const fetchData = async () => {
    setIsLoading(true);
    const employeesData = await EmployeesService.filter();
    setEmployees(employeesData);
    setFilteredEmployees(employeesData);
    const skills = await SkillsService.filter();
    setSkills(skills);
    setIsLoading(false);
  };

  useEffect(() => {
    sessionStorage.setItem('empSkills.currentPage', '1');
    setPage(1);
    fetchData();
  }, [props?.location?.pathname]);

  const handleOptionChange = (event, option) => {
    const updatedValue = event.target.checked
      ? [...filterForm?.employees, option]
      : filterForm?.employees?.filter(emp => `${emp?.firstName} ${emp?.lastName}` !== option?.key);
    if (event.target.checked) {
      const lastAddedOption = updatedValue[updatedValue?.length - 1];
      const lastSelected = employees?.find(
        employee => lastAddedOption?.key === `${employee?.firstName} ${employee?.lastName}`
      );
      updatedValue[updatedValue?.length - 1] = lastSelected;
    }
    handleEmployeeChange(null, updatedValue);
  };
  const clearFilter = () => {
    setFilterForm({
      employees: []
    });
    setFilteredEmployees(employees);
    setIsFiltered(false);
  };
  const deleteEmployee = id => {
    const filteredEmployees = filterForm?.employees?.filter(emp => emp.id !== id);
    setFilterForm(prevFilterForm => ({
      ...prevFilterForm,
      employees: filteredEmployees
    }));
  };
  const applyFilter = () => {
    const searchedEmployeeIds = filterForm?.employees?.map(emp => emp?.id);
    const filteredEmp = employees?.filter(employee => searchedEmployeeIds?.includes(employee?.id));
    setFilteredEmployees(filteredEmp);
    setIsFiltered(true);
  };

  const titleBar = (
    <TitleBarContainer
      style={{ height: '64px', paddingTop: 0 }}
      title="Employees All Skills"
      backRoute="/"
    />
  );
  return (
    <LayoutContainer titleBar={titleBar}>
      <EmployeeAllSkills
        employees={filteredEmployees}
        options={{ employees }}
        navigate={navigate}
        isLoading={isLoading}
        isFiltered={isFiltered}
        handleSortChange={handleSortChange}
        sortModel={sortModel}
        page={page}
        setPage={setPage}
        filterForm={filterForm}
        skills={skills}
        handleEmployeeChange={handleEmployeeChange}
        applyFilter={applyFilter}
        clearFilter={clearFilter}
        deleteEmployee={deleteEmployee}
        handleOptionChange={handleOptionChange}
      />
    </LayoutContainer>
  );
};

export default EmployeeSkillsContainer;
