/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import moment from 'moment';
import { ProjectsService } from '../../services/projects';
import ProjectDialog from './ProjectDialog';
import './ProjectDialog.scss';
import { withRouter } from 'react-router';

const ProjectDialogContainer = props => {
  const mapProjectData = project => {
    const popupData = {
      id: project?.id,
      name: project?.name,
      billable: project?.billable || false,
      leadId: project?.leadId ? [project?.leadId] : [],
      code: project?.code || null,
      startDate: project?.startDate || null,
      endDate: project?.endDate || null,
      minResource: project?.minResource || null,
      maxResource: project?.maxResource || null,
      skills: project?.skills.map(skill => skill?.id) || [],
      tags: project?.tags.map(tag => tag?.id) || []
    };
    return popupData;
  };
  const [project, setProject] = useState(
    mapProjectData(props.data) || {
      id: undefined,
      name: undefined,
      code: null,
      leadId: [],
      startDate: null,
      endDate: null,
      minResource: null,
      maxResource: null,
      billable: true,
      tags: [],
      skills: []
    }
  );
  const [isValid, setIsValid] = useState(!!props.data);
  const [dateRangeError, setDateRangeError] = useState(false);
  const [resoursesRangeError, setResoursesRangeError] = useState(false);

  const handleChange = event => {
    const { value, name, type } = event.target;
    const updatedProject = { ...project, [name]: type === 'number' ? +value : value };
    const { isValid, dateRangeError, resoursesRangeError } = validation(updatedProject, name);
    setProject(updatedProject);
    setIsValid(isValid);
    setDateRangeError(dateRangeError);
    setResoursesRangeError(resoursesRangeError);
  };
  const handleTagChange = tags => {
    const modeledTgs = tags.map(tag => tag.id);
    const updatedProject = { ...project, tags: modeledTgs };
    const { isValid, dateRangeError, resoursesRangeError } = validation(updatedProject);
    setProject(updatedProject);
    setIsValid(isValid);
    setDateRangeError(dateRangeError);
    setResoursesRangeError(resoursesRangeError);
  };
  const handleContractChange = (date, name) => {
    //if this caused an error when saving the client then convert the date to ISOString
    const updatedProject = { ...project, [name]: date };
    const { isValid, dateRangeError, resoursesRangeError } = validation(updatedProject, name);
    setProject(updatedProject);
    setIsValid(isValid);
    setDateRangeError(dateRangeError);
    setResoursesRangeError(resoursesRangeError);
  };
  const handleSkillsChange = value => {
    const modeledSkills = value.map(skill => skill.id);
    const updatedProject = { ...project, skills: modeledSkills };
    const { isValid, dateRangeError, resoursesRangeError } = validation(updatedProject);
    setProject(updatedProject);
    setIsValid(isValid);
    setDateRangeError(dateRangeError);
    setResoursesRangeError(resoursesRangeError);
  };
  const handleEmployeesChange = value => {
    const updatedProject = { ...project, leadId: [+value[0].id] };
    const { isValid, dateRangeError, resoursesRangeError } = validation(updatedProject);
    setProject(updatedProject);
    setIsValid(isValid);
    setDateRangeError(dateRangeError);
    setResoursesRangeError(resoursesRangeError);
  };
  const handleCheckBox = event => {
    const updatedProject = { ...project, billable: event.target.checked };
    setProject(updatedProject);
  };
  const validation = (project, subject) => {
    let isValid = false;
    let isDateRangeError = dateRangeError;
    let isResoursesRangeError = resoursesRangeError;
    const { name, leadId } = project;
    switch (subject) {
      case 'startDate':
      case 'endDate':
        const { startDate, endDate } = project;
        if (startDate && endDate) {
          if (moment(startDate).isBefore(moment(endDate))) {
            isDateRangeError = false;
          } else {
            isDateRangeError = true;
            // isValid = false;
          }
        } else {
          isDateRangeError = false;
        }
        break;
      case 'minResource':
      case 'maxResource':
        const { minResource, maxResource } = project;
        if (minResource && maxResource) {
          if (+maxResource >= +minResource) {
            isResoursesRangeError = false;
          } else {
            isResoursesRangeError = true;
            // isValid = false;
          }
        } else {
          isResoursesRangeError = false;
        }
        break;
      default:
        break;
    }
    if (name && leadId.length > 0 && !dateRangeError && !resoursesRangeError) isValid = true;
    return { isValid, dateRangeError, resoursesRangeError };
  };
  const saveProject = async () => {
    const data = Object.fromEntries(
      Object.entries({ ...project, name: project.name?.trim(), leadId: project.leadId[0] }).filter(
        ([key, value]) => value !== null
      )
    );
    if (data.id && data.id !== null && data.id !== undefined) {
      try {
        const { id, ...rest } = data;
        await ProjectsService.update(rest, +props.match.params.id, +id);
        reset();
        props.onProjectCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onProjectCreate('fail', errorMessage);
      }
    } else {
      try {
        await ProjectsService.add(data, +props.match.params.id);
        reset();
        props.onProjectCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onProjectCreate('fail', errorMessage);
      }
    }
  };
  const cancel = () => {
    reset();
    props.closeDialog();
  };
  const reset = () => {
    setProject({
      id: undefined,
      name: undefined,
      code: null,
      leadId: [],
      startDate: null,
      endDate: null,
      minResource: null,
      maxResource: null,
      billable: true,
      tags: [],
      skills: []
    });
    setIsValid(false);
    setDateRangeError(false);
    setResoursesRangeError(false);
  };

  return (
    <ProjectDialog
      project={project}
      isValid={isValid}
      handleChange={handleChange}
      cancel={cancel}
      saveProject={saveProject}
      dateRangeError={dateRangeError}
      resoursesRangeError={resoursesRangeError}
      handleTagChange={handleTagChange}
      handleContractChange={handleContractChange}
      handleSkillsChange={handleSkillsChange}
      handleEmployeesChange={handleEmployeesChange}
      handleCheckBox={handleCheckBox}
    />
  );
};

export default withRouter(ProjectDialogContainer);
