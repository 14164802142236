import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import Template from '../Template/Template';
import ShowMessage from 'components/ShowMessage/ShowMessage';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import { feedbackService } from 'modules/Feedback/services/feedback';
import './FeedbackResponse.scss';
const FeedbackResponseContainer = () => {
  const { id } = useParams();
  const history = useHistory();
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const idToken = localStorage.getItem('idToken');
  const decodeToken = jwt.decode(idToken);

  useEffect(() => {
    const getTemplate = async () => {
      try {
        if (idToken === 'undefined' || decodeToken?.id !== +id) return;
        const feedback = await feedbackService.getFeedbackById(id);
        setTemplate({
          name: feedback.data.templateName,
          ...feedback.data
        });
      } catch (err) {
        setError('Failed to load feedback template.');
      } finally {
        setLoading(false);
      }
    };

    getTemplate();
  }, [id]);

  if (loading) {
    return <LoadingState />;
  }
  if (error) {
    return (
      <ShowMessage>
        <h1>Sorry!</h1>
        <p>{error}</p>
      </ShowMessage>
    );
  }
  if (idToken === 'undefined' || !idToken) {
    history.push(`/feedback/${id}/login`);
  }

  if (template.status === 'completed') {
    return (
      <ShowMessage>
        <h1>Sorry!</h1>
        <p>You have already submitted this feedback</p>
      </ShowMessage>
    );
  }

  if (decodeToken?.id !== +id) {
    return (
      <ShowMessage>
        <h1>Sorry!</h1>
        <p>You do not access to this feedback</p>
      </ShowMessage>
    );
  }
  return (
    <div className="feedbackResponseContainer">
      <div className="header">
        <div className="logo"></div>
      </div>
      {<Template feedbackId={id} template={template} />}
    </div>
  );
};

export default FeedbackResponseContainer;
