import { Divider } from '@mui/material';
import Avatar from 'react-avatar';
import profileImg from '../../../../assets/img/user-profile.png';

const EmployeeInfo = ({ employee, navigate, isAdmin }) => {
  return (
    <div className="container">
      <div className="card">
        <div className="upper">
          <span className="welcoming">welcome back!</span>
          <img src={profileImg} alt="" />
        </div>
        <div className="user">
          <div className="profile">
            <Avatar
              className="img"
              name={employee?.firstName + ' ' + employee?.lastName}
              size="80"
            />
          </div>
        </div>

        <div className="empInfo">
          <h4 className="name">
            {employee?.firstName + ' ' + employee?.lastName}{' '}
            <span className="employeeId">{employee?.employeeNo}</span>
          </h4>
          <div className="employeeWokDetail">
            <span className="employeeData">{employee?.email || '-'}</span>
          </div>
          <div className="employeeWokDetail">
            <span className="employeeData">{employee?.jobTitle || '-'}</span>
          </div>
        </div>
        <Divider />
        <div className="employeeDetailsWrapper">
          <div className="employeeDetailsList">
            <h4 className="sectionName">Personal Information</h4>
            <div className="employeeDetail">
              <span className="label">Location :</span>
              <span className="detail">{employee?.location || '-'}</span>
            </div>

            <div className="employeeDetail">
              <span className="label">Gender :</span>
              <span className="detail">{employee?.gender || '-'}</span>
            </div>
            <div className="employeeDetail">
              <span className="label">Nationality :</span>
              <span className="detail">{employee?.nationality || '-'}</span>
            </div>
          </div>
          <Divider />
          <div className="employeeDetailsList">
            <h4 className="sectionName">Work Information</h4>
            <div className="employeeDetail">
              <span className="label">Company :</span>
              <span className="detail">{employee?.company || '-'}</span>
            </div>
            <div className="employeeDetail">
              <span className="label">Tenure :</span>
              <span className="detail">{employee?.tenure}</span>
            </div>
            <div className="employeeDetail">
              <span className="label">Department :</span>
              <span className="detail">{employee?.department || '-'}</span>
            </div>
            <div className="employeeDetail">
              <span className="label">Division :</span>
              <span className="detail">{employee?.division || '-'}</span>
            </div>
            <div className="employeeDetail">
              <span className="label">Date of joining :</span>
              <span className="detail">{employee?.dateOfJoining || '-'}</span>
            </div>
            <div className="employeeDetail">
              <span className="label">Company Start Date :</span>
              <span className="detail">{employee?.companyStartDate || '-'}</span>
            </div>

            {employee?.reportingTo?.id && (
              <div className="employeeDetail">
                <span className="label">Reporting To :</span>
                <span
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    isAdmin() ? navigate(`/employees/${employee?.reportingTo?.id}/profile`) : null;
                  }}
                  className="detailLink"
                >
                  {employee?.reportingTo?.name + ' ' + employee?.reportingToEmployeeNo}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeeInfo;
