import React, { useState, useEffect } from 'react';
import SearchBarCotainer from '../../../../shared/components/top-bars/search-bar/SearchBarContainer';
import LayoutContainer from '../../../../shared/components/_layout/layout-container/LayoutContainer';
import DetailsAuditSideContainer from '../../../../shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import * as tagManageService from '../../services/tags-management';
import { TagsService } from '../../../../shared/services/tags-service';
import TagsList from './TagsList';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import './TagsList.scss';

const TagsListContainer = () => {
  const [tags, setTags] = useState([]);
  const [audits, setAudits] = useState([]);
  const [lastFilter, setLastFilter] = useState('');
  const [sideData, setSideData] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const onFilter = async (name = '') => {
    setIsLoading(true);
    const { data: tags } = await TagsService.search(name);
    setTags(tags);
    setLastFilter(name);
    setIsLoading(false);
  };
  useEffect(() => {
    onFilter();
  }, []);
  const openTagDialog = tags => {
    setIsDialogOpen(true);
    setDialogData(tags);
  };
  const closeTagDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };
  const onTagCreate = (state = '', errorMessage) => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Tag saved successfully');
      setIsDialogOpen(false);
      setDialogData(undefined);
      onFilter(lastFilter);
    } else {
      setShowErrorToast(true);
      setErrorToastText(errorMessage);
    }
  };
  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };
  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };
  const closeDeleteDialog = async data => {
    if (data && data?.id) {
      try {
        await tagManageService.deleteTag(data.id);
        setShowSuccessToast(true);
        setShowSuccessToast('Tag removed successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
        onFilter(lastFilter);
      } catch (error) {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove Tag');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };
  const confirmDelete = tag => {
    const toDelete = { id: tag.id, name: tag.name, type: 'Tag' };
    setToDelete(toDelete);
    setIsDeleteDialogOpen(true);
  };
  const openSideContent = async tag => {
    let audits = [
      {
        user: tag.createdBy,
        time: tag.createdAt,
        action: 'Create'
      }
    ];
    if (tag.updatedBy)
      audits.push({
        user: tag.updatedBy,
        time: tag.updatedAt,
        action: 'Update'
      });
    setAudits(audits);
    setSideData(tag);
  };
  const searchBarCommandPlaceholder = (
    <Fab
      style={{ position: 'relative', top: '2em' }}
      onClick={() => openTagDialog()}
      color="secondary"
      aria-label="Add tag"
    >
      {' '}
      <AddIcon />{' '}
    </Fab>
  );
  const titleBar = (
    <SearchBarCotainer
      backRoute="/"
      title="Tags"
      filter={onFilter}
      searchBarCommandPlaceholder={searchBarCommandPlaceholder}
    ></SearchBarCotainer>
  );
  const headerContent = (
    <div
      className="DetailsAuditSideHeader"
      style={{
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center flex-start',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
    >
      {sideData?.color && (
        <div
          className="SideTagImage"
          style={{
            marginRight: '0.75em',
            width: '3em',
            height: '3em',
            borderRadius: '50%',
            backgroundColor: sideData.color
          }}
        />
      )}
      <div
        className="sideTagName"
        style={{
          font: '500 20px/32px',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {sideData?.name}
      </div>
    </div>
  );
  const detailsContent = closeDrawer => (
    <div className="sideDetailsContent" style={{ maxWidth: '30em' }}>
      <div
        className="upperActions"
        style={{ display: 'flex', placeContent: 'center flex-end', marginRight: '0.25em' }}
      >
        <IconButton
          onClick={() => {
            confirmDelete(sideData);
            closeDrawer();
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            openTagDialog(sideData);
            closeDrawer();
          }}
        >
          <EditIcon />
        </IconButton>
      </div>
      <div className="sideDetailsList" style={{ paddingTop: '8px', fontSize: '12px' }}>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Name
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {sideData?.name}
          </span>
        </div>
      </div>
    </div>
  );
  const sideContent = closeDrawer => (
    <DetailsAuditSideContainer
      headerContent={headerContent}
      detailsContent={detailsContent}
      audit={audits}
      closeDrawer={closeDrawer}
    />
  );
  const drawerWidth = 350;
  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={drawerWidth}>
      <TagsList
        tags={tags}
        openTagDialog={openTagDialog}
        closeTagDialog={closeTagDialog}
        onTagCreate={onTagCreate}
        isDialogOpen={isDialogOpen}
        showSuccessToast={showSuccessToast}
        closeSuccessToast={closeSuccessToast}
        showErrorToast={showErrorToast}
        closeErrorToast={closeErrorToast}
        dialogData={dialogData}
        isDeleteDialogOpen={isDeleteDialogOpen}
        closeDeleteDialog={closeDeleteDialog}
        confirmDelete={confirmDelete}
        toDelete={toDelete}
        successToastText={successToastText}
        errorToastText={errorToastText}
        openSideContent={openSideContent}
        isLoading={isLoading}
      />
    </LayoutContainer>
  );
};

export default TagsListContainer;
