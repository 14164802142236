import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Calendar from 'modules/shared/components/_employee-views/Calendar/DatePicker/Calendar';
import EmployeeChipListContainer from 'modules/shared/components/_employee-views/EmployeeChipList/EmployeeChipListContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EmployeeDenseContainer from 'modules/shared/components/_employee-views/EmployeeDense/EmployeeDenseContainer';
import { FormHelperText } from '@material-ui/core';
const AllocationDialog = props => {
  const [allocationFieldsProps, setAllocationFieldsProps] = useState({
    allocationEmployees: { error: false, helperText: '' },
    effortStartDate: { error: false, helperText: '' },
    effortEndDate: { error: false, helperText: '' }
  });

  const handleBlur = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    const hasValue = props?.allocation?.employee?.length !== 0;

    let updatedFieldProps;

    if (value?.trim() === '') {
      updatedFieldProps = {
        [name]: {
          error: name === 'allocationEmployees' ? !hasValue : true,
          helperText:
            !hasValue && name === 'allocationEmployees'
              ? 'Field must not be empty'
              : 'Field must not be empty'
        }
      };
    } else {
      updatedFieldProps = { [name]: { error: false, helperText: '' } };
    }

    setAllocationFieldsProps(prevProps => ({ ...prevProps, ...updatedFieldProps }));
  };
  return (
    <div id="AllocationDialog">
      <div className="ProjectDialogTitle">
        {props.allocation.id
          ? props.allocation.employee[0] && (
              <EmployeeDenseContainer data={props.allocation.employee[0]} initialsOnly={false} />
            )
          : 'New allocation'}
      </div>

      <div className="AllocationDialogContent">
        {!props.allocation.id && (
          <>
            {' '}
            <EmployeeChipListContainer
              handleEmployeesChange={props.handleEmployeesChange}
              employees={props.allocation.employee}
              excluded={props.allocation.empIds}
              fullWidth={true}
              required
              name="allocationEmployees"
              onBlur={handleBlur}
              error={allocationFieldsProps?.allocationEmployees?.error}
            />
            {allocationFieldsProps?.allocationEmployees?.error && (
              <FormHelperText error>
                {allocationFieldsProps?.allocationEmployees?.helperText}
              </FormHelperText>
            )}
          </>
        )}
        <div className="formRow-90-reversed">
          <FormControlLabel
            control={
              <Checkbox
                checked={props.allocation.bench || false}
                onChange={props.handleCheckBox}
                name="bench"
                size="small"
              />
            }
            label="Benched"
            labelPlacement="end"
          />
          <TextField
            onChange={e => {
              props.handleChange(e);
            }}
            defaultValue={props.allocation.title}
            name="title"
            margin="dense"
            label="Title"
            type="text"
            disabled={props.allocation.employee.length > 1}
          />
        </div>
        <div className="utilization">
          <div>Utilization</div>
          <IconButton size="small" color="inherit" onClick={props.newPeriod}>
            <AddCircleIcon />
          </IconButton>
        </div>
        {props.allocation.efforts.map((effort, idx) => (
          <div className="effortField" key={idx}>
            <Calendar
              name="effortStartDate"
              handleChange={date => props.handleEffortChange(date, 'startDate', idx)}
              date={effort.startDate || null}
              placeholder="Start Date"
              fullWidth={true}
              error={
                new Date(effort.startDate || null).getTime() >
                  new Date(effort.endDate || null).getTime() ||
                allocationFieldsProps?.effortStartDate?.error
              }
              required
              onBlur={handleBlur}
              helperText={allocationFieldsProps?.effortStartDate?.helperText}
            />
            <Calendar
              name="effortEndDate"
              handleChange={date => props.handleEffortChange(date, 'endDate', idx)}
              date={effort.endDate || null}
              placeholder="End Date"
              fullWidth={true}
              error={
                new Date(effort.startDate || null).getTime() >
                  new Date(effort.endDate || null).getTime() ||
                allocationFieldsProps?.effortEndDate?.error
              }
              required
              onBlur={handleBlur}
              helperText={allocationFieldsProps?.effortEndDate?.helperText}
            />
            <div className="effortSlider">
              <Slider
                value={effort.effort ? effort.effort : 0}
                step={1}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                onChange={(event, newValue) => props.handleEffortChange(newValue, 'effort', idx)}
                style={{ marginLeft: '8px' }}
                required
              />
              <span style={{ flexBasis: 0, padding: '4px 8px' }}>
                {effort.effort ? `${effort.effort}%` : '0%'}
              </span>
            </div>
            <span style={{ flexBasis: 0 }}>
              <IconButton size="small" color="inherit" onClick={() => props.removePeriod(idx)}>
                <DeleteIcon />
              </IconButton>
            </span>
          </div>
        ))}
        {props.overLapError && <div className="overlapError">Overlapping date ranges</div>}
      </div>

      <div className="AllocationDialogActions">
        <Button onClick={props.cancel}>Cancel</Button>
        <Button onClick={props.saveAllocation} disabled={!props.isValid} color="primary">
          {props.allocation.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
};
export default AllocationDialog;
