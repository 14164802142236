import React from 'react';
import Home from './Home';
import LayoutContainer from '../../modules/shared/components/_layout/layout-container/LayoutContainer';
function HomeContainer() {
  return (
    <>
      <LayoutContainer>
        <Home />
      </LayoutContainer>
    </>
  );
}
export default HomeContainer;
