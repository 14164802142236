import React from 'react';
import './TabbedSideContainer.scss';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const TabbedSideContainer = props => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'space-between'
        }}
      >
        {props.headerContent && props.headerContent}
        <IconButton onClick={props.close}>
          <CloseIcon aria-label="close" />
        </IconButton>
      </div>
      {props.actionContent && props.actionContent}
      {props.mainContent && props.mainContent}
    </>
  );
};
export default TabbedSideContainer;
