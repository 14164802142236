import React, { useEffect, useState } from 'react';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import EmptyEployeeImg from 'assets/img/app_states/empty/Employees.png';
import StripedDataGrid from 'components/Grid/StripedDataGrid';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns = [
  { field: 'employeeNo', headerName: 'Employee ID', width: 150 },
  {
    field: 'fullName',
    headerName: 'Name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 300,
    valueGetter: params => `${params.row.firstName || ''} ${params.row.lastName || ''}`
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300
  },
  {
    field: 'jobTitle',
    headerName: 'Job Title',
    width: 280
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 250
  },
  {
    field: 'department',
    headerName: 'Department',
    width: 250
  }
];
const EmployeesWithMissingSkills = props => {
  const savedPage = sessionStorage.getItem('missingSkills.currentPage');
  const savedSortModel = localStorage.getItem('dataGridSort');
  const [page, setPage] = useState(savedPage ? parseInt(savedPage) : 1);
  const [sortModel, setSortModel] = useState(savedSortModel ? JSON.parse(savedSortModel) : []); // State to store sorting value

  const handleSortChange = newSortModel => {
    setSortModel(newSortModel);
    localStorage.setItem('dataGridSort', JSON.stringify(newSortModel));
  };

  useEffect(() => {
    sessionStorage.setItem('missingSkills.currentPage', page?.toString());
  }, [page]);

  const renderDetails = params => {
    return props.navigate(`/employees/${params}/profile`);
  };
  const exportToolbar = () => (
    <GridToolbarContainer className="rightToolbar">
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div id="employeesWithMissingSkills">
      {!props.isLoading ? (
        !props.error ? (
          props?.employeesList?.length > 0 ? (
            <div className="employeesWrapper">
              <StripedDataGrid
                sortModel={sortModel}
                autoWidth
                rows={props?.employeesList}
                columns={columns}
                onSortModelChange={handleSortChange}
                slots={{ toolbar: exportToolbar }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                      page: page - 1
                    }
                  }
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                disableRowSelect
                onRowClick={params => renderDetails(params.row.id)}
                getRowClassName={params =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                onPaginationModelChange={params => {
                  setPage(params?.page + 1);
                }}
              />
            </div>
          ) : (
            <EmptyState
              stateImage={
                <div>
                  <img src={EmptyEployeeImg} alt="" />
                </div>
              }
              stateText={
                <div className="empty-text-section">
                  <div
                    style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                    className="empty-text-message"
                  >
                    No employees found.
                  </div>
                </div>
              }
            />
          )
        ) : (
          'error'
        )
      ) : (
        <LoadingState />
      )}
    </div>
  );
};
export default EmployeesWithMissingSkills;
