import { useEffect, useState, useRef } from 'react';
import EmployeeSkills from './EmployeeSkills';
import './EmployeeSkills.scss';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import TitleBarContainer from 'modules/shared/components/top-bars/title-bar/TitleBarContainer';
import { getLevel, getLevelName } from 'utilities/helpers/getSkillLevel';
import LEVELS from 'utilities/constants/levels';
import { SkillsService } from 'modules/shared/services/skills-service';
import { EmployeesService } from 'modules/shared/services/employees';

const EmployeeSkillsContainer = props => {
  const savedSortModel = localStorage.getItem('dataGridSort');
  const savedPathname = localStorage.getItem('pathname');
  const [sortModel, setSortModel] = useState(
    savedSortModel && savedPathname === props?.location.pathname ? JSON.parse(savedSortModel) : []
  );
  const handleSortChange = newSortModel => {
    setSortModel(newSortModel);
    localStorage.setItem('dataGridSort', JSON.stringify(newSortModel));
  };
  const savedPage = sessionStorage.getItem('empSkills.currentPage');
  const [page, setPage] = useState(savedPage ? parseInt(savedPage) : 1);
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [experienceRanges, setExperienceRanges] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const groupRef = useRef(null);
  const [filterForm, setFilterForm] = useState({
    group: '',
    skill: [],
    levelsNames: [],
    levels: [],
    yearsOfExperience: null,
    usedInAspire: null
  });
  const [groups, setGroups] = useState([]);
  const [skills, setSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [groupedSkills, setGroupedSkills] = useState({});
  const navigate = route => {
    props.history.push(route, { prevPathname: props?.location?.pathname });
  };
  const handleSkillChange = event => {
    const value = event?.target?.value;

    if (value?.includes('')) {
      setFilterForm({
        skill: [],
        levels: [],
        levelsNames: [],
        yearsOfExperience: null,
        usedInAspire: false
      });
    } else {
      setFilterForm(prevFilterForm => ({
        ...prevFilterForm,
        skill: value
      }));
    }
  };
  const fetchData = async () => {
    setIsLoading(true);
    const employeesData = await EmployeesService.filter();
    setEmployees(employeesData);
    const skills = await SkillsService.filter();
    setSkills(skills);
    setFilteredSkills(skills);

    const groupedSkills = skills.reduce((acc, skill) => {
      const groupName = skill.group;
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(skill);
      return acc;
    }, {});
    const { data: expRanges } = await EmployeesService.getExpRanges();
    setExperienceRanges(expRanges);

    setGroups(Object?.keys(groupedSkills));
    setGroupedSkills(groupedSkills);
    setIsLoading(false);
  };
  useEffect(() => {
    sessionStorage.setItem('empSkills.currentPage', '1');
    setPage(1);
    localStorage.setItem('pathname', props?.location?.pathname);
    fetchData();
  }, [props?.location?.pathname]);
  const handleGroupChange = (event, value, reason) => {
    if (reason === 'clear') {
      setFilterForm({
        group: '',
        skill: [],
        levels: [],
        levelsNames: []
      });
    }
    setFilterForm(prevFilterForm => ({
      ...prevFilterForm,
      skill: '',
      group: value
    }));
    const filteredSkills = value ? groupedSkills[value] : skills;
    setFilteredSkills(filteredSkills);
  };
  const handleLevelChange = async event => {
    const { value } = event.target;
    let skillLevelsName;
    const allLevelNames = LEVELS?.map(lvl => lvl?.level);
    if (value.includes('all') && value.length === allLevelNames.length + 1) {
      skillLevelsName = [];
    } else {
      skillLevelsName = value.includes('all')
        ? allLevelNames
        : typeof value === 'string'
        ? value.split(',')
        : value;
    }
    let skillLevels = skillLevelsName?.map(level => getLevel(level));
    setFilterForm(prevFilterForm => ({
      ...prevFilterForm,
      levelsNames: !value?.includes('') ? skillLevelsName : [],
      levels: !value?.includes('') ? skillLevels : []
    }));
  };
  const handleExperienceChange = event => {
    const value = event?.target?.value;
    setFilterForm(prevFilterForm => ({
      ...prevFilterForm,
      yearsOfExperience: value || null
    }));
  };
  const handleUsedInAspireChange = event => {
    setFilterForm(prevFilterForm => ({
      ...prevFilterForm,
      usedInAspire: event?.target?.checked || null
    }));
  };
  const applyFilter = async () => {
    setIsLoading(true);
    const { skill: filterSkill, levels, yearsOfExperience, usedInAspire } = filterForm;

    const filteredEmployees = employees?.reduce((result, employee) => {
      const filteredSkills = employee.skills?.filter(
        skill =>
          (!filterSkill || filterSkill.includes(skill.name)) &&
          (!filterSkill || levels?.length === 0 || levels.includes(skill?.EmployeeSkill?.level)) &&
          (!filterSkill ||
            yearsOfExperience === undefined ||
            yearsOfExperience === null ||
            yearsOfExperience === skill?.EmployeeSkill?.experienceId) &&
          (!filterSkill ||
            usedInAspire === undefined ||
            usedInAspire === null ||
            skill?.EmployeeSkill?.usedInAspire === true)
      );

      if (filterSkill.every(skillName => filteredSkills.some(skill => skill.name === skillName))) {
        employee.filteredSkills = filteredSkills.map(({ name, EmployeeSkill }) => ({
          name,
          level: getLevelName(EmployeeSkill?.level),
          experienceId: EmployeeSkill?.experienceId,
          usedInAspire: EmployeeSkill?.usedInAspire
        }));
        result.push(employee);
      }

      return result;
    }, []);

    setIsFiltered(true);
    setFilteredEmployees(filteredEmployees || []);
    setIsLoading(false);
  };

  const clearFilter = () => {
    setFilterForm({
      group: '',
      skill: [],
      levelsNames: [],
      levels: [],
      yearsOfExperience: null,
      usedInAspire: false
    });
    groupRef?.current?.querySelector('.MuiAutocomplete-clearIndicator')?.click();
    setFilteredEmployees([]);
    setIsFiltered(false);
  };
  const handleCloseSecondaryBar = () => {
    setFilterForm({
      group: '',
      skill: [],
      levelsNames: [],
      levels: [],
      yearsOfExperience: null,
      usedInAspire: false
    });
    setFilteredEmployees(employees);
    setIsFiltered(false);
  };
  const deleteLevel = level => {
    const levelsNames = filterForm?.levelsNames?.filter(lvl => lvl !== level);
    let skillLevels = levelsNames?.map(level => getLevel(level));
    setFilterForm(prevFilterForm => ({
      ...prevFilterForm,
      levelsNames: levelsNames,
      levels: skillLevels
    }));
  };
  const deleteSkill = skill => {
    const skills = filterForm?.skill?.filter(lvl => lvl !== skill);
    setFilterForm(prevFilterForm => ({
      ...prevFilterForm,
      skill: skills
    }));
  };
  const titleBar = (
    <TitleBarContainer
      style={{ height: '64px', paddingTop: 0 }}
      title="Employees Skills"
      backRoute="/"
      onClose={handleCloseSecondaryBar}
    />
  );
  return (
    <LayoutContainer titleBar={titleBar}>
      <EmployeeSkills
        employees={filteredEmployees}
        navigate={navigate}
        isLoading={isLoading}
        isFiltered={isFiltered}
        selectedSkills={filterForm?.skill || []}
        handleSortChange={handleSortChange}
        sortModel={sortModel}
        page={page}
        setPage={setPage}
        groups={groups}
        handleGroupChange={handleGroupChange}
        filterForm={filterForm}
        handleSkillChange={handleSkillChange}
        filteredSkills={filteredSkills}
        deleteSkill={deleteSkill}
        handleLevelChange={handleLevelChange}
        deleteLevel={deleteLevel}
        handleExperienceChange={handleExperienceChange}
        experienceRanges={experienceRanges}
        handleUsedInAspireChange={handleUsedInAspireChange}
        applyFilter={applyFilter}
        clearFilter={clearFilter}
        groupRef={groupRef}
      />
    </LayoutContainer>
  );
};

export default EmployeeSkillsContainer;
