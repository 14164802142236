import EmployeeBasicContainer from 'modules/shared/components/_employee-views/EmployeeBasic/EmployeeBasicContainer';

const EmployeesGrid = props => {
  const isOddLength = props?.employees?.length % 2 !== 0;

  return (
    <div className="gridWrapper">
      <div className="employeeGridWrapper">
        {props?.employees?.map((employee, idx) => {
          return (
            <EmployeeBasicContainer
              isLastCard={isOddLength && idx === props?.employees?.length - 1}
              key={employee?.id}
              data={employee}
              routerLink={`/employees/${employee?.id}/profile`}
            />
          );
        })}
      </div>
    </div>
  );
};
export default EmployeesGrid;
