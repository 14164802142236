import React, { useEffect, useState } from 'react';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import TitleBarContainer from 'modules/shared/components/top-bars/title-bar/TitleBarContainer';
import './SkillsStatistics.scss';
import SkillsStatistics from './SkillsStatistics';
import { SkillsService } from 'modules/shared/services/skills-service';
import { Button } from '@material-ui/core';
import TabbedSideContainer from 'modules/shared/components/_layout/tabbed-side-container/TabbedSideContainer';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';

const SkillsStatisticsContainer = props => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sideData, setSideData] = useState([]);

  useEffect(() => {
    filterData();
    localStorage.setItem('pathname', props?.location?.pathname);
  }, []);

  const filterData = async () => {
    setIsLoading(true);
    const data = await loadData();
    if (data) {
      setData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setError(true);
    }
  };
  const openSideContent = async data => {
    setSideData(data);
  };

  const loadData = async () => {
    try {
      const result = await SkillsService.getSkillsStatistics();
      return result.data;
    } catch {
      return undefined;
    }
  };
  const resultFound = () => {
    return data && data.length > 0 ? true : false;
  };
  const headerContent = (
    <div>
      <h3>{sideData?.skillName}</h3>
    </div>
  );

  const mainContent = (
    <>
      <h4>level : {sideData?.level}</h4>
      {sideData?.employees?.map(record => (
        <div>
          <Button className="capText" to={`/employees/${record.id}/profile`} component={Link}>
            <Avatar className="avatarStyle" />
            <span>{record.fullName}</span>
          </Button>
        </div>
      ))}
    </>
  );

  const sideContent = closeDrawer => (
    <div>
      <TabbedSideContainer
        headerContent={headerContent}
        mainContent={mainContent}
        close={closeDrawer}
      />
    </div>
  );
  return (
    <LayoutContainer
      sideContent={sideContent}
      titleBar={<TitleBarContainer title="Skills Statistics" backRoute="/" />}
    >
      {' '}
      {!props.isLoading ? (
        <SkillsStatistics
          openSideContent={openSideContent}
          data={data}
          error={error}
          isLoading={isLoading}
          resultFound={resultFound}
        />
      ) : (
        <LoadingState />
      )}
    </LayoutContainer>
  );
};
export default SkillsStatisticsContainer;
