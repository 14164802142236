import React, { useEffect, useState } from 'react';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import { InitiativesService } from '../../services/initiatives';
import SearchBarCotainer from 'modules/shared/components/top-bars/search-bar/SearchBarContainer';
import DetailsAuditSideContainer from 'modules/shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import moment from 'moment';
import Initatives from './Initatives';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EmployeeChip from 'modules/shared/components/_employee-views/EmployeeChip/EmployeeChip';
import './Initatives.scss';
const InitativesContainer = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([
    {
      id: 1,
      name: 'init1',
      owners: [2396, 60, 2217, 531, 2085],
      description: 'this is init1',
      tags: ['tag1', 'tag2', 'tag3'],
      startDate: '2021-07-05T15:30:43.370Z',
      endDate: '2021-09-08T15:30:43.370Z'
    },
    {
      id: 2,
      name: 'init2',
      owners: [2396, 60, 2217, 531, 2085],
      description: 'this is init2',
      tags: ['tag7', 'tag8', 'tag9'],
      startDate: '2020-06-05T15:30:43.370Z',
      endDate: '2020-08-08T15:30:43.370Z'
    },
    {
      id: 3,
      name: 'init3',
      owners: [2396, 60, 2217],
      description: 'this is init3',
      tags: ['tag1', 'tag2', 'tag3', 'tag4'],
      startDate: '2021-02-05T15:30:43.370Z',
      endDate: '2021-08-08T15:30:43.370Z'
    },
    {
      id: 4,
      name: 'init4',
      owners: [2396, 60, 2217, 531, 2085],
      description: 'this is init4',
      tags: ['tag4', 'tag5', 'tag6'],
      startDate: '2020-03-05T15:30:43.370Z',
      endDate: '2020-06-08T15:30:43.370Z'
    },
    {
      id: 5,
      name: 'init5',
      owners: [2396, 60, 2217, 531, 2085],
      description: 'this is init5',
      tags: ['tag4', 'tag5'],
      startDate: '2021-05-05T15:30:43.370Z',
      endDate: '2021-07-08T15:30:43.370Z'
    }
  ]);
  const [audits, setAudits] = useState([]);
  const [lastFilter, setLastFilter] = useState('');
  const [sideData, setSideData] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);

  useEffect(() => {
    filterInitiatives('', false);
  }, []);
  const filterInitiatives = async (name = undefined, toggleLoading = true) => {
    if (toggleLoading) {
      setIsLoading(true);
    }
    const filter = {};
    if (name) filter.name = name;
    const { data } = await InitiativesService.filter(filter);
    setData(data);
    setIsLoading(false);
    setLastFilter(name || '');
  };
  const openDialog = initiative => {
    setIsDialogOpen(true);
    setDialogData(initiative);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };
  const onInitiativeCreate = state => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Initiative saved successfully');
      setIsDialogOpen(false);
      setDialogData(undefined);
      filterInitiatives(lastFilter);
    } else {
      setShowErrorToast(true);
      setErrorToastText('Cannot save Initiative');
    }
  };
  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };

  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };
  const confirmDelete = initiative => {
    const toDelete = { id: initiative.id, name: initiative.name, type: 'Initiative' };
    setToDelete(toDelete);
    setIsDeleteDialogOpen(true);
  };
  const closeDeleteDialog = async data => {
    if (data && data.id) {
      try {
        await InitiativesService.deleteInitiative(data.id);
        setShowSuccessToast(true);
        setSuccessToastText('Initiative removed successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);

        filterInitiatives(lastFilter);
      } catch {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove Initiative');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };
  const openSideContent = async initiative => {
    setSideData(initiative);
    try {
      const { data: audits } = await InitiativesService.audit(initiative.id);
      setAudits(audits);
    } catch {
      setAudits([]); //ToDo: set a global error message
    }
  };
  const titleBar = (
    <SearchBarCotainer
      backRoute="/"
      title="Initiatives"
      filter={filterInitiatives}
      searchBarCommandPlaceholder={
        <Fab
          style={{ position: 'relative', top: '2em' }}
          onClick={() => openDialog()}
          color="secondary"
          aria-label="Add Initiative"
        >
          {' '}
          <AddIcon />{' '}
        </Fab>
      }
    />
  );
  const sideContent = closeDrawer => (
    <DetailsAuditSideContainer
      audit={audits}
      closeDrawer={closeDrawer}
      headerContent={
        <div
          className="DetailsAuditSideHeader"
          style={{
            display: 'flex',
            alignItems: 'center',
            placeContent: 'center flex-start',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          <div
            className="sideName"
            style={{
              font: '500 20px/32px',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {sideData?.name}
          </div>
        </div>
      }
      detailsContent={closeDrawer => (
        <div className="sideDetailsContent" style={{ maxWidth: '30em' }}>
          <div
            className="upperActions"
            style={{ display: 'flex', placeContent: 'center flex-end', marginRight: '0.25em' }}
          >
            <IconButton
              onClick={() => {
                openDialog(sideData);
                closeDrawer();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                confirmDelete(sideData);
                closeDrawer();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <div className="sideDetailsList" style={{ paddingTop: '8px', fontSize: '12px' }}>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Name
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.name}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0',
                textOverflow: 'ellipsis'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Description
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.description}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                minHeight: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Owners
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.owners?.map((owner, idx) => (
                  <EmployeeChip data={owner} key={idx} />
                ))}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0',
                textOverflow: 'ellipsis'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Start Date
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.startDate ? moment(sideData?.startDate).format('ll') : ''}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0',
                textOverflow: 'ellipsis'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                End Date
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.endDate ? moment(sideData?.endDate).format('ll') : ''}
              </span>
            </div>
            <div
              className="sideDetailsListItem"
              style={{
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                flex: '1 0',
                textOverflow: 'ellipsis'
              }}
            >
              <span className="liCaption" style={{ flex: '1 0' }}>
                Tags
              </span>
              <span className="liCaption" style={{ flex: '1 0' }}>
                {sideData?.tags?.join(', ')}
              </span>
            </div>
          </div>
        </div>
      )}
    />
  );

  const mapData = item => {
    const { createdAt, updatedAt, meta, ...c } = item;
    c.tags = c.tags ? c.tags.map(a => ({ name: a })) : [];
    return c;
  };
  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={350}>
      <Initatives
        data={data}
        openDialog={openDialog}
        closeDialog={closeDialog}
        onInitiativeCreate={onInitiativeCreate}
        isDialogOpen={isDialogOpen}
        dialogData={dialogData}
        showSuccessToast={showSuccessToast}
        closeSuccessToast={closeSuccessToast}
        showErrorToast={showErrorToast}
        closeErrorToast={closeErrorToast}
        isDeleteDialogOpen={isDeleteDialogOpen}
        toDelete={toDelete}
        confirmDelete={confirmDelete}
        closeDeleteDialog={closeDeleteDialog}
        successToastText={successToastText}
        errorToastText={errorToastText}
        openSideContent={openSideContent}
        isLoading={isLoading}
      />
    </LayoutContainer>
  );
};

export default InitativesContainer;
