import React, { useState, useEffect } from 'react';
import SearchBarCotainer from '../../../../shared/components/top-bars/search-bar/SearchBarContainer';
import LayoutContainer from '../../../../shared/components/_layout/layout-container/LayoutContainer';
import { SkillsService } from '../../../../shared/services/skills-service';
import SkillList from './SkillList';
import * as skillManagementService from '../../services/skills-management';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SkillsIcon from 'assets/img/app_states/empty/SkillsIcon.png';
import DetailsAuditSideContainer from '../../../../shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import './SkillList.scss';

const SkillListContainer = () => {
  const [groupedSkills, setGroupedSkills] = useState([]);
  const [audits, setAudits] = useState([]);
  const [sideData, setSideData] = useState(undefined);
  const [lastAppliedFilter, setLastAppliedFilter] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const onFilter = async (name = '') => {
    setIsLoading(true);
    const skillArray = await SkillsService.filter({ name: name });
    const groupedSkillsObject = skillArray.reduce((acc, skill) => {
      acc[skill.department.name] = [...(acc[skill.department.name] || []), skill];
      return acc;
    }, {});
    const groupedSkillsArray = [];
    for (const [key, value] of Object.entries(groupedSkillsObject)) {
      groupedSkillsArray.push({ groupName: key, skills: value });
    }
    groupedSkillsArray.sort((a, b) => {
      const x = a.groupName.toLowerCase();
      const y = b.groupName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    setGroupedSkills(groupedSkillsArray);
    setLastAppliedFilter(name);
    setIsLoading(false);
  };
  const openSkillDialog = skill => {
    setIsDialogOpen(true);
    setDialogData(skill);
  };
  const closeSkillDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };
  const onSkillCreate = (state, errorMessage) => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Skill saved successfully');
      setIsDialogOpen(false);
      setDialogData(undefined);
      onFilter(lastAppliedFilter);
    } else {
      setShowErrorToast(true);
      setErrorToastText(errorMessage);
    }
  };
  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };
  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };
  const closeDeleteDialog = async data => {
    if (data && data.id) {
      try {
        await skillManagementService.deleteSkill(data.id);
        setShowSuccessToast(true);
        setSuccessToastText('Skill removed successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
        onFilter(lastAppliedFilter);
      } catch {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove skill');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };
  const confirmDelete = skill => {
    const toDeleteData = { id: skill.id, name: skill.name, type: 'Skill' };
    setToDelete(toDeleteData);
    setIsDeleteDialogOpen(true);
  };
  const openSideContent = async skill => {
    let audits = [
      {
        user: skill.createdBy,
        time: skill.createdAt,
        action: 'Create'
      }
    ];
    if (skill.updatedBy)
      audits.push({
        user: skill.updatedBy,
        time: skill.updatedAt,
        action: 'Update'
      });
    setAudits(audits);
    setSideData(skill);
  };

  useEffect(() => {
    onFilter();
  }, []);

  const searchBarCommandPlaceholder = (
    <Fab
      style={{ position: 'relative', top: '2em' }}
      onClick={() => openSkillDialog()}
      color="secondary"
      aria-label="Create new skill"
    >
      {' '}
      <AddIcon />{' '}
    </Fab>
  );
  const titleBar = (
    <SearchBarCotainer
      backRoute="/"
      title="Skills"
      filter={onFilter}
      searchBarCommandPlaceholder={searchBarCommandPlaceholder}
    ></SearchBarCotainer>
  );
  const headerContent = (
    <div
      className="DetailsAuditSideHeader"
      style={{
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center flex-start',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
    >
      {sideData?.imgUrl ? (
        <img
          className="SideSkillImage"
          src={sideData.imgUrl}
          alt={sideData.name}
          style={{ marginRight: '0.75em', width: '3em', height: '3em', borderRadius: '50%' }}
        />
      ) : (
        <img
          className="SideSkillImage"
          src={SkillsIcon}
          alt=""
          style={{ marginRight: '0.75em', width: '3em', height: '3em', borderRadius: '50%' }}
        />
      )}
      <div
        className="sideSkillName"
        style={{
          font: '500 20px/32px',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {sideData?.name}
      </div>
    </div>
  );
  const detailsContent = closeDrawer => (
    <div className="sideDetailsContent" style={{ maxWidth: '30em' }}>
      <div
        className="upperActions"
        style={{ display: 'flex', placeContent: 'center flex-end', marginRight: '0.25em' }}
      >
        <IconButton
          onClick={() => {
            confirmDelete(sideData);
            closeDrawer();
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            openSkillDialog(sideData);
            closeDrawer();
          }}
        >
          <EditIcon />
        </IconButton>
      </div>
      <div className="sideDetailsList" style={{ paddingTop: '8px', fontSize: '12px' }}>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Name
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {sideData?.name}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Group
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {sideData?.group}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Department
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {sideData?.department?.name}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Availability (Jordan)
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {sideData?.availabilityJordan}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Availability (India)
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {sideData?.availabilityIndia}
          </span>
        </div>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Market demand
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {sideData?.marketDemand}
          </span>
        </div>
      </div>
    </div>
  );
  const sideContent = closeDrawer => (
    <DetailsAuditSideContainer
      headerContent={headerContent}
      detailsContent={detailsContent}
      audit={audits}
      closeDrawer={closeDrawer}
    />
  );
  const drawerWidth = 350;
  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={drawerWidth}>
      <SkillList
        groupedSkills={groupedSkills}
        isDialogOpen={isDialogOpen}
        openSkillDialog={openSkillDialog}
        closeSkillDialog={closeSkillDialog}
        showSuccessToast={showSuccessToast}
        showErrorToast={showErrorToast}
        closeSuccessToast={closeSuccessToast}
        closeErrorToast={closeErrorToast}
        onSkillCreate={onSkillCreate}
        dialogData={dialogData}
        isDeleteDialogOpen={isDeleteDialogOpen}
        closeDeleteDialog={closeDeleteDialog}
        confirmDelete={confirmDelete}
        toDelete={toDelete}
        successToastText={successToastText}
        errorToastText={errorToastText}
        openSideContent={openSideContent}
        isLoading={isLoading}
      />
    </LayoutContainer>
  );
};
export default SkillListContainer;
