import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './LoadingState.scss';
const LoadingState = () => {
  return (
    <div className="loadingState">
      Fetching data ...
      <CircularProgress size={50} style={{ color: '#005792' }} />
    </div>
  );
};
export default LoadingState;
