import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { FormHelperText } from '@material-ui/core';
const SkillBasicInfoDialog = props => {
  const [skillFieldsProps, setSkillFieldsProps] = useState({
    name: { error: false, helperText: '' },
    group: { error: false, helperText: '' },
    department: { error: false, helperText: '' },
    availabilityJordan: { error: false, helperText: '' },
    availabilityIndia: { error: false, helperText: '' },
    marketDemand: { error: false, helperText: '' }
  });

  const handleBlur = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value?.trim() === '') {
      setSkillFieldsProps({
        ...skillFieldsProps,
        [name]: { error: true, helperText: 'Field must not be empty' }
      });
    } else {
      setSkillFieldsProps({
        name: { error: false, helperText: '' },
        group: { error: false, helperText: '' },
        department: { error: false, helperText: '' }
      });
    }
  };
  return (
    <div id="SkillBasicInfoDialog">
      <div className="SkillDialogTitle">{props.skill.id ? props.skill.name : 'New skill'}</div>
      <div className="SkillDialogContent">
        <TextField
          onChange={e => {
            props.handleChange(e);
          }}
          defaultValue={props.skill.name}
          name="name"
          required
          autoFocus
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          fullWidth
          onBlur={handleBlur}
          {...skillFieldsProps?.name}
        />
        <TextField
          onChange={props.handleChange}
          defaultValue={props.skill.group}
          name="group"
          required
          margin="dense"
          id="Group"
          label="Group"
          type="text"
          fullWidth
          onBlur={handleBlur}
          {...skillFieldsProps?.group}
        />
        <FormControl fullWidth required error={skillFieldsProps?.department?.error}>
          <InputLabel id="departmentLabel">Department</InputLabel>
          <Select
            value={props.skill.department?.name || ''}
            name="department"
            labelId="departmentLabel"
            id="Department"
            onBlur={handleBlur}
            onChange={props.handleDepartmentChange}
          >
            {props.departments.map(department => (
              <MenuItem key={department.name} value={department.name}>
                {department.name}
              </MenuItem>
            ))}
          </Select>
          {skillFieldsProps?.department?.error && (
            <FormHelperText>{skillFieldsProps?.department?.helperText}</FormHelperText>
          )}
        </FormControl>
        <TextField
          defaultValue={props.skill.imgUrl}
          onChange={props.handleChange}
          name="imgUrl"
          margin="dense"
          id="ImgUrl"
          label="Image URL"
          type="text"
          fullWidth
        />
        <div className="formGroupHeader">Benchmarks</div>
        <div className="BenchmarksWrapper">
          <FormControl required error={skillFieldsProps?.availabilityJordan?.error}>
            <InputLabel id="jordan">Availability (Jordan)</InputLabel>
            <Select
              value={props.skill.availabilityJordan}
              name="availabilityJordan"
              labelId="jordan"
              id="availabilityJordan"
              onChange={props.handleChange}
              onBlur={handleBlur}
            >
              <MenuItem value={'Low'}>Low</MenuItem>
              <MenuItem value={'Medium'}>Medium</MenuItem>
              <MenuItem value={'High'}>High</MenuItem>
            </Select>
            {skillFieldsProps?.availabilityJordan?.error && (
              <FormHelperText>{skillFieldsProps?.availabilityJordan?.helperText}</FormHelperText>
            )}
          </FormControl>
          <FormControl required error={skillFieldsProps?.availabilityIndia?.error}>
            <InputLabel id="india">Availability (India)</InputLabel>
            <Select
              value={props.skill.availabilityIndia}
              name="availabilityIndia"
              labelId="india"
              id="availabilityIndia"
              onChange={props.handleChange}
              onBlur={handleBlur}
            >
              <MenuItem value={'Low'}>Low</MenuItem>
              <MenuItem value={'Medium'}>Medium</MenuItem>
              <MenuItem value={'High'}>High</MenuItem>
            </Select>
            {skillFieldsProps?.availabilityIndia?.error && (
              <FormHelperText>{skillFieldsProps?.availabilityIndia?.helperText}</FormHelperText>
            )}
          </FormControl>
          <FormControl required error={skillFieldsProps?.marketDemand?.error}>
            <InputLabel id="market">Market demand</InputLabel>
            <Select
              value={props.skill.marketDemand}
              name="marketDemand"
              labelId="market"
              id="marketDemand"
              onChange={props.handleChange}
              onBlur={handleBlur}
            >
              <MenuItem value={'Low'}>Low</MenuItem>
              <MenuItem value={'Medium'}>Medium</MenuItem>
              <MenuItem value={'High'}>High</MenuItem>
            </Select>
            {skillFieldsProps?.marketDemand?.error && (
              <FormHelperText>{skillFieldsProps?.marketDemand?.helperText}</FormHelperText>
            )}
          </FormControl>
        </div>
      </div>

      <div className="SkillDialogActions">
        <Button onClick={props.cancel}>Cancel</Button>
        <Button onClick={props.createSkill} disabled={!props.isValid} color="primary">
          {props.skill.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
};
export default SkillBasicInfoDialog;
