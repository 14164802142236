import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';

const Filters = props => {
  return (
    <div
      id="employeeFilterForm"
      style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}
    >
      <div
        style={{ display: 'flex', width: '100%', alignItems: 'center', margin: '-4rem 0 1rem 0' }}
      >
        <FormControl
          style={{
            flex: '1 0',
            marginRight: '0.25em',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '86px',
            marginTop: '2rem'
          }}
        >
          <Autocomplete
            id="employees-select"
            multiple
            options={props?.options?.employees || []}
            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
            onChange={props?.handleEmployeeChange}
            value={props?.filterForm?.employees || []}
            disableCloseOnSelect
            renderOption={(option, { selected }) => (
              <FormControlLabel
                key={option.key}
                control={
                  <Checkbox
                    color="primary"
                    checked={props?.filterForm?.employees?.some(
                      employee => `${employee?.firstName} ${employee?.lastName}` === option?.key
                    )}
                  />
                }
                label={option?.key}
                onChange={event => props?.handleOptionChange(event, option)}
              />
            )}
            renderInput={params => (
              <TextField {...params} variant="standard" placeholder="Employees" />
            )}
          />
        </FormControl>
        <Fab
          disabled={props?.filterForm?.employees?.length === 0}
          onClick={() => {
            props?.applyFilter();
          }}
          size="small"
          className={props?.filterForm?.employees?.length === 0 ? 'disabledBtn' : 'enabledBtn'}
        >
          <CheckIcon />
        </Fab>
        <Fab
          disabled={props?.filterForm?.employees?.length === 0}
          onClick={props?.clearFilter}
          size="small"
          className={props?.filterForm?.employees?.length === 0 ? 'clear disabled' : 'clear'}
        >
          <ClearIcon />
        </Fab>
      </div>
    </div>
  );
};

export default Filters;
