export const getLevel = value => {
  return {
    Expert: 5,
    Proficient: 4,
    Competent: 3,
    Demonstrating: 2,
    Basic: 1
  }[value];
};
export const getLevelName = value => {
  return {
    5: 'Expert',
    4: 'Proficient',
    3: 'Competent',
    2: 'Demonstrating',
    1: 'Basic'
  }[value];
};
