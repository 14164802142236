import FeedbackAuthentication from './FeedbackAuthentication';
import './FeedbackAuthentication.scss';
function FeedbackAuthenticationContainer() {
  return (
    <div>
      <FeedbackAuthentication />
    </div>
  );
}
export default FeedbackAuthenticationContainer;
