import QuestionsText from './QuestionsTypes/QuestionsText';
import QuestionsRating from './QuestionsTypes/QuestionsRating';
const Questions = ({ questionsText, questionsRating, feedbackId, handleAnswerChange, answers }) => {
  return (
    <div className="questionsOptionsContainer">
      {questionsText &&
        questionsText?.map((question, index) => (
          <QuestionsText
            question={question}
            key={question?.questionId}
            index={index + 1}
            feedbackId={feedbackId}
            value={answers[question?.questionId] || ''}
            handleAnswerChange={handleAnswerChange}
          />
        ))}
      {questionsRating &&
        questionsRating?.map((question, index) => (
          <QuestionsRating
            question={question}
            key={question?.questionId}
            index={index + 1}
            feedbackId={feedbackId}
            value={answers[question?.questionId] || ''}
            handleAnswerChange={handleAnswerChange}
          />
        ))}
    </div>
  );
};

export default Questions;
