import React, { useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { FormHelperText } from '@material-ui/core';
const Calendar = props => {
  const [selectedDate, setSelectedDate] = React.useState(
    props.date ? (typeof props.date === 'string' ? new Date(props.date) : props.date) : null
  );
  const handleDateChange = date => {
    setSelectedDate(date?.toDate() || null);
    props.handleChange(date?.toDate() || new Date());
  };
  useEffect(
    () =>
      setSelectedDate(
        props.date ? (typeof props.date === 'string' ? new Date(props.date) : props.date) : null
      ),
    [props.date]
  );
  return (
    <div className="calendar">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          disableToolbar
          autoOk
          variant="inline"
          format="DD/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={props.placeholder}
          value={selectedDate}
          onChange={handleDateChange}
          required={props.required}
          maxDate={props.max}
          minDate={props.min}
          fullWidth={props.fullWidth}
          error={props.error}
          onBlur={props?.onBlur}
          name={props?.name}
          PopoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          }}
        />
        {props?.error && <FormHelperText error>{props?.helperText}</FormHelperText>}
      </MuiPickersUtilsProvider>
    </div>
  );
};
Calendar.defaultProps = {
  placeholder: 'Date',
  showPrefix: true,
  required: false,
  max: new Date('1/1/2100'),
  min: new Date('1/1/1969'),
  fullWidth: false
};
export default Calendar;
