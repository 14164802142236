const LEVELS = [
  {
    score: 1,
    name: 'Level 1',
    level: 'Basic',
    descriptions: ['Unable to perform', 'Little to no experience']
  },
  {
    score: 2,
    name: 'Level 2',
    level: 'Demonstrating',
    descriptions: [
      'Limited in ability or knowledge',
      'Cannot perform for critical tasks',
      'Needs significant help from others'
    ]
  },
  {
    score: 3,
    name: 'Level 3',
    level: 'Competent',
    descriptions: [
      'Able to perform at a basic level',
      'Has some direct experience',
      'Needs help from time to time'
    ]
  },
  {
    score: 4,
    name: 'Level 4',
    level: 'Proficient',
    descriptions: [
      'Capable and experienced',
      'Demonstrated proficiency',
      'Able to work independently with little help',
      'Will be Expert with more time'
    ]
  },
  {
    score: 5,
    name: 'Level 5',
    level: 'Expert',
    descriptions: [
      'Fully capable and experienced',
      'Sought for help by other departments',
      'Needs no assistance to complete tasks',
      'Demonstrated ability to lead and train others',
      'Seen as a Subject Matter Expert'
    ]
  }
];
export default LEVELS;
