import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import EmployeeSkills from './EmployeeSkills';
import EmployeeProjects from './EmployeeProjects';
import EmployeeInfo from './EmployeeInfo';
import moment from 'moment';

const EmployeeProfile = props => {
  const skillAssessmentDates = Array.prototype.concat.apply(
    [],
    props?.skillsGroups?.map(s => s.skills?.map(ss => ss.lastAssessment))
  );
  const mxAssessDate = skillAssessmentDates.reduce(function (a, b) {
    return a > b ? a : b;
  }, null);

  return (
    <div id="EmployeeProfile">
      {!props.isLoading ? (
        props.employee && props.employee.id ? (
          <div className="wrapper">
            <div className="content">
              <EmployeeInfo
                employee={props?.employee}
                navigate={props?.navigate}
                supervisorData={props.supervisorData}
                isAdmin={props.isAdmin}
              />
              <div className="skillsAndProjects">
                <div className="contentWrap">
                  <div className="skillsCard">
                    <div className="cardTitleWithBtn">
                      <h4 className="sectionName">My Skills</h4>
                      {mxAssessDate && (
                        <h5 className="assessmentDate">
                          last updated: {moment(mxAssessDate).format('DD-MMM-YYYY')}{' '}
                        </h5>
                      )}
                      {props.canEditSkills && (
                        <IconButton
                          onClick={() => props.navigate(`/employees/${props.employee.id}/skills`)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </div>
                    <EmployeeSkills
                      skillsGroups={props.skillsGroups}
                      canEditSkills={props.canEditSkills}
                      getSkillLevel={props.getSkillLevel}
                      employee={props.employee}
                      navigate={props.navigate}
                    />
                  </div>
                  <EmployeeProjects
                    directReports={props?.employee?.direct_reports}
                    isAdmin={props.isAdmin}
                    projects={props?.assignedProjects}
                    navigate={props.navigate}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyState />
        )
      ) : (
        <LoadingState />
      )}
    </div>
  );
};
export default EmployeeProfile;
