import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import DetailsAuditSideContainer from 'modules/shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { ProjectsService } from 'modules/Administration/projects-manage/services/projects';
import { EmployeesService } from 'modules/shared/services/employees';
import { allocationService } from '../../services/allocation';
import { bestMatchService } from '../../services/suggested-allocation';
import TitleBarContainer from 'modules/shared/components/top-bars/title-bar/TitleBarContainer';
import EmployeeDenseContainer from 'modules/shared/components/_employee-views/EmployeeDense/EmployeeDenseContainer';
import './AllocatedList.scss';
import AllocatedList from './AllocatedList';
const AllocatedListContainer = props => {
  const [project, setProject] = useState(undefined);
  const [resources, setResources] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [leadDetails, setLeadDetails] = useState(undefined);
  const [suggested, setSuggested] = useState([]);
  const [empIds, setEmpIds] = useState([]);
  const [audits, setAudits] = useState([]);
  const [sideData, setSideData] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [confirmDeleteData, setConfirmDeleteData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAllocations = async () => {
    const clientId = +props.match.params.id;
    const projectId = +props.match.params.pid;
    const { empIds, employeeDetails, resources } = await loadData();
    const project = await ProjectsService.get(projectId, clientId);
    let { data: leadDetails } = await EmployeesService.getEmpByID(project.leadId);

    let suggested = [];
    if (project.skills && project.skills.length > 0) {
      const filterStartDate = moment(project.startDate) || moment();
      const filterEndDate = moment(project.endDate) || moment().add(2, 'M');
      suggested = await bestMatchService.listBestSuggestions(
        project.skills.map(s => s.id),
        filterStartDate.toDate(),
        filterEndDate.toDate()
      );
    }
    setEmpIds(empIds);
    setEmployeeDetails(employeeDetails);
    setResources(resources);
    setLeadDetails(leadDetails);
    setSuggested(suggested);
    setProject(project);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchAllocations();
  }, []);

  const loadData = async () => {
    const empIds = [];
    const employeeDetailsPromise = [];
    let employeeDetails = [];
    // const isLoading: { isLoading?: boolean } = {};//will be spreaded when setstate happens
    // if (toggleLoading) isLoading["isLoading"] = true;
    const clientId = +props.match.params.id;
    const projectId = +props.match.params.pid;
    const { data: rawResources } = await allocationService.list(clientId, projectId);
    const resources = await Promise.all(
      rawResources.map(async a => {
        empIds.push(a.employeeId);
        const { data: allocationData } = await EmployeesService.getEmpByID(a.employeeId);
        employeeDetailsPromise.push(allocationData);
        return mapEfforts(a);
      })
    );
    const emps = await Promise.all(employeeDetailsPromise);
    emps.forEach(emp => {
      if (emp) employeeDetails.push(emp);
    });
    return { empIds, employeeDetails, resources };
  };
  const mapEfforts = item => {
    item.efforts = item.efforts.sort((a, b) => new Date(a.start) - new Date(b.start));
    return item;
  };
  const openAllocationDialog = allocation => {
    let dialogData = allocation;
    if (allocation && !(allocation instanceof Array)) {
      const employee = employeeDetails.filter(emp => emp.id === allocation.employeeId);
      dialogData = {
        ...dialogData,
        lead: leadDetails,
        project: project,
        empDetails: employee
      };
    }
    setIsDialogOpen(true);
    setDialogData(dialogData);
  };
  const closeAllocationDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };
  const onAllocationCreate = async state => {
    if (state === 'success') {
      setIsLoading(true);
      const { empIds, employeeDetails, resources } = await loadData();
      setEmpIds(empIds);
      setEmployeeDetails(employeeDetails);
      setResources(resources);
      setShowSuccessToast(true);
      setSuccessToastText('Allocation saved successfully');
      setIsDialogOpen(false);
      setDialogData(undefined);
      setIsLoading(false);
    } else {
      setShowErrorToast(true);
      setErrorToastText('Cannot save Allocation');
    }
  };
  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };
  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };
  const confirmDelete = async resource => {
    const empdetails = employeeDetails.filter(emp => {
      return emp.id === resource.employeeId;
    });
    const modeledProject = {
      ...project,
      leadId: leadDetails
    };
    setToDelete({ id: resource.id, project: modeledProject, empDetails: empdetails[0] });
    setConfirmDeleteData({ id: resource.id, type: 'Allocation' });
    setIsDeleteDialogOpen(true);
  };
  const closeDeleteDialog = async data => {
    if (data && data.id) {
      const { id, project, empDetails } = toDelete;
      const clientId = +props.match.params.id;
      const projectId = +props.match.params.pid;
      setIsLoading(true);
      try {
        await allocationService.deleteAllocation(clientId, projectId, id, project, empDetails);
        const { empIds, employeeDetails, resources } = await loadData();
        setEmpIds(empIds);
        setEmployeeDetails(employeeDetails);
        setResources(resources);
        setShowSuccessToast(true);
        setSuccessToastText('Allocation removed successfully!');
        setToDelete(undefined);
        setConfirmDeleteData(undefined);
        setIsDeleteDialogOpen(false);
        setIsLoading(false);
      } catch {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove Allocation');
        setToDelete(undefined);
        setConfirmDeleteData(undefined);
        setIsDeleteDialogOpen(false);
        setIsLoading(false);
      }
    } else {
      setToDelete(undefined);
      setConfirmDeleteData(undefined);
      setIsDeleteDialogOpen(false);
    }
  };
  const openSideContent = async resource => {
    let audits = [
      {
        user: resource.createdBy,
        time: resource.createdAt,
        action: 'Create'
      }
    ];
    if (resource.updatedBy) {
      audits.push({
        user: resource.updatedBy,
        time: resource.updatedAt,
        action: 'Update'
      });
    }
    setAudits(audits);
    setSideData(resource);
  };
  const getAverage = efforts => {
    let sum = 0,
      totalDays = 0;
    for (const entry of efforts) {
      const days = Math.ceil(
        Math.abs(new Date(entry.endDate).getTime() - new Date(entry.startDate).getTime()) /
          (1000 * 3600 * 24)
      );
      totalDays += days;
      sum += (entry.effort || 0) * days;
    }
    return Math.round((sum / totalDays) * 100) / 100 || 0;
  };
  const getSuggestedAllocation = suggested => {
    return { employeeId: +suggested, efforts: [] };
  };
  const filterSuggestedResourceSkills = suggested => {
    return suggested.skills
      .filter(a => project.skills.map(s => s.id).includes(a.skill))
      .map(a => ({
        ...a,
        ...project.skills.filter(s => s.id === a.skill)[0]
      }))
      .map(({ imgUrl, level, name, id }) => ({
        imgUrl,
        level,
        name,
        id
      }))
      .filter(a => a.level > 2);
  };

  const titleBarPrimary = (
    <Fab
      style={{ position: 'relative', top: '2em' }}
      onClick={() => openAllocationDialog(empIds)}
      color="secondary"
      aria-label="Add resource"
    >
      {' '}
      <AddIcon />{' '}
    </Fab>
  );
  const titleBar = (
    <TitleBarContainer
      title={project?.name || 'Project'}
      backRoute={`/admin/clients/${props.match.params.id}/projects`}
      titleBarPrimary={titleBarPrimary}
    />
  );
  const headerContent = (
    <div
      className="DetailsAuditSideHeader"
      style={{
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center flex-start',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
    >
      {sideData?.employeeId && (
        <EmployeeDenseContainer data={sideData.employeeId} bold={true} initialsOnly={false} />
      )}
    </div>
  );
  const detailsContent = closeDrawer => (
    <div className="sideDetailsContent" style={{ maxWidth: '30em' }}>
      <div
        className="upperActions"
        style={{ display: 'flex', placeContent: 'center flex-end', marginRight: '0.25em' }}
      >
        <IconButton
          onClick={() => {
            openAllocationDialog(sideData);
            closeDrawer();
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            confirmDelete(sideData);
            closeDrawer();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="sideDetailsList" style={{ paddingTop: '8px', fontSize: '12px' }}>
        <div
          className="sideDetailsListItem"
          style={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          <span className="liCaption" style={{ flex: '1 0' }}>
            Status
          </span>
          <span className="liCaption" style={{ flex: '1 0' }}>
            {sideData?.bench ? 'Benched' : 'Billable'}
          </span>
        </div>
        <div
          className="sideDetailsSubHeader"
          style={{
            color: 'rgba(0,0,0,.54)',
            fontWeight: 500,
            fontSize: 14,
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            flex: '1 0'
          }}
        >
          Allocations
        </div>
        {sideData?.efforts?.map((effort, idx) => (
          <div
            className="sideDetailsListItem"
            style={{
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 16px',
              flex: '1 0'
            }}
            key={idx}
          >
            <span className="liCaption" style={{ flex: '1 0' }}>{`${new Date(
              effort.startDate
            ).toLocaleDateString()} to ${new Date(effort.endDate).toLocaleDateString()}`}</span>
            <span className="liCaption" style={{ flex: '1 0' }}>
              {effort.effort ? `${effort.effort}%` : '0%'}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
  const sideContent = closeDrawer => (
    <DetailsAuditSideContainer
      headerContent={headerContent}
      detailsContent={detailsContent}
      audit={audits}
      closeDrawer={closeDrawer}
    />
  );
  const drawerWidth = 350;
  return (
    <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={drawerWidth}>
      <AllocatedList
        resources={resources}
        openSideContent={openSideContent}
        confirmDelete={confirmDelete}
        openAllocationDialog={openAllocationDialog}
        closeAllocationDialog={closeAllocationDialog}
        onAllocationCreate={onAllocationCreate}
        isDialogOpen={isDialogOpen}
        dialogData={dialogData}
        showSuccessToast={showSuccessToast}
        closeSuccessToast={closeSuccessToast}
        showErrorToast={showErrorToast}
        closeErrorToast={closeErrorToast}
        isDeleteDialogOpen={isDeleteDialogOpen}
        closeDeleteDialog={closeDeleteDialog}
        confirmDeleteData={confirmDeleteData}
        successToastText={successToastText}
        errorToastText={errorToastText}
        getAverage={getAverage}
        isLoading={isLoading}
        suggested={suggested}
        filterSuggestedResourceSkills={filterSuggestedResourceSkills}
        getSuggestedAllocation={getSuggestedAllocation}
      />
    </LayoutContainer>
  );
};
export default AllocatedListContainer;
