import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import BarChartIcon from '@material-ui/icons/BarChart';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import Dialog from '@material-ui/core/Dialog';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  Line,
  ReferenceArea
} from 'recharts';
import OverallAllocationFilterContainer from '../overall-allocation-filter/OverallAllocationFilterContainer';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import EmptyReportImg from 'assets/img/app_states/empty/Report.png';
import Avatar from 'react-avatar';
import { Divider } from '@material-ui/core';
const CustomCursor = props => {
  const { height, points } = props;
  return (
    <Rectangle
      fill="black"
      stroke={undefined}
      x={points[0].x}
      y={points[0].y}
      width={1}
      height={height}
    />
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#000000b3',
          color: '#fff',
          padding: '3px',
          margin: '0px',
          borderRadius: '4px',
          lineHeight: '1.4em',
          textAlign: 'left',
          fontSize: '13px'
        }}
      >
        {payload.map((entry, idx) => (
          <div key={idx} style={{ padding: '3px 6px', display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: '12px',
                height: '12px',
                backgroundColor: entry.color,
                display: 'inline-block',
                marginRight: '6px',
                borderRadius: '2px'
              }}
            />
            {`Overall: ${
              Math.round((entry.payload[entry.dataKey] + Number.EPSILON) * 1000) / 1000
            }`}
          </div>
        ))}
      </div>
    );
  }
  return null;
};
const customReferenceAreaLabel = ({ viewBox }) => {
  return (
    <>
      (
      <g transform={`translate(${viewBox.x},${viewBox.y})`}>
        <text x={viewBox.width} y={-2} style={{ fontSize: '11px' }} textAnchor="middle" fill="#666">
          Maximum
        </text>
        <text
          x={viewBox.width}
          y={viewBox.height - 2}
          style={{ fontSize: '11px' }}
          textAnchor="middle"
          fill="#666"
        >
          Minimum
        </text>
      </g>
      )
    </>
  );
};
const OverallAllocation = props => {
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);
  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  return (
    <div id="overallAllocation" style={{ position: 'relative' }}>
      {!props.isLoading ? (
        <>
          {!props.errorLoading ? (
            props.data.length > 0 ? (
              <>
                {props.viewMode === 'list' && (
                  <div className="allocationsContainer">
                    {props.data.map((employee, idx) => (
                      <div className="allocationCard" key={idx}>
                        <div className="allocationCardContent">
                          <div className="employeeCard">
                            {props?.model?.photoUrl ? (
                              <img className="resourceAvatar" src={employee?.photoUrl} alt="" />
                            ) : (
                              <Avatar
                                className="resourceAvatar"
                                name={`${employee?.firstName} ${employee?.lastName}`}
                              />
                            )}
                            <div className="resourceInfo">
                              <span className="name">
                                {employee?.firstName} {employee?.lastName}
                              </span>
                            </div>
                          </div>
                          <Divider />
                          <div className="projectInfo">
                            <div className="projectsStats">
                              {`${employee.totalProjects} project${
                                employee.totalProjects > 1 ? 's' : ''
                              } assigned${
                                employee.benchedProjects > 0
                                  ? ` | on bench of ${employee.benchedProjects}`
                                  : ''
                              }`}
                            </div>
                            <div className="avgUtilization">
                              {employee.avgUtilization
                                ? Math.round(employee.avgUtilization) + '%'
                                : '0%'}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {props.viewMode === 'compined-chart' && (
                  <ResponsiveContainer width={'100%'} height={630}>
                    <LineChart
                      data={props.groupedChartData}
                      margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
                    >
                      <CartesianGrid />
                      <XAxis
                        dataKey="name"
                        interval={0}
                        label={{ value: 'Date', position: 'insideBottom', offset: 0 }}
                        height={50}
                        scale="auto"
                        padding={{ left: 50, right: 50 }}
                      />
                      <ReferenceArea
                        y1={75}
                        y2={90}
                        stroke="#a8b2c7"
                        strokeDasharray="5 5"
                        fill="#000"
                        fillOpacity="0.05"
                        ifOverflow="extendDomain"
                        label={customReferenceAreaLabel}
                      />
                      <YAxis
                        label={{ value: 'Effort', angle: -90, position: 'insideLeft' }}
                        domain={[0, props.computeyScaleMaxForLineCart()]}
                        tickCount={11}
                        axisLine={false}
                      />
                      <Tooltip cursor={<CustomCursor />} content={CustomTooltip} />
                      <Line dot={false} type="monotone" dataKey="value" stroke="#5AA454" />
                    </LineChart>
                  </ResponsiveContainer>
                )}

                <SpeedDial
                  style={{ position: 'absolute', bottom: '16px', right: '-3%' }}
                  ariaLabel="toggle chart view SpeedDial"
                  icon={<RemoveRedEyeIcon />}
                  open={openSpeedDial}
                  onOpen={handleOpenSpeedDial}
                  onClose={handleCloseSpeedDial}
                  FabProps={{ color: 'secondary' }}
                >
                  <SpeedDialAction
                    icon={<ViewModuleIcon />}
                    tooltipTitle={'list'}
                    onClick={() => {
                      handleCloseSpeedDial();
                      props.changeView('list');
                    }}
                  />
                  <SpeedDialAction
                    icon={<BarChartIcon />}
                    tooltipTitle={'chart'}
                    onClick={() => {
                      handleCloseSpeedDial();
                      props.changeView('compined-chart');
                    }}
                  />
                </SpeedDial>
              </>
            ) : (
              <EmptyState
                stateImage={
                  <div>
                    <img src={EmptyReportImg} alt="" />
                  </div>
                }
                stateText={
                  <div className="empty-text-section">
                    <div
                      style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                      className="empty-text-message"
                    >
                      No results found
                    </div>
                  </div>
                }
              />
            )
          ) : (
            'Error'
          )}
        </>
      ) : (
        <LoadingState />
      )}

      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={props.isDialogOpen}
        onBackdropClick={() => props.closeDialog()}
      >
        <OverallAllocationFilterContainer
          data={props.dialogData}
          closeDialog={props.closeDialog}
          resetFilter={props.resetFilter}
        />
      </Dialog>
    </div>
  );
};
export default OverallAllocation;
