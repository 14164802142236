import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Rectangle
} from 'recharts';
import EmptyReportImg from 'assets/img/app_states/empty/Report.png';
const CustomCursor = props => {
  const { height, points } = props;
  return (
    <Rectangle
      fill="black"
      stroke={undefined}
      x={points[0].x}
      y={points[0].y}
      width={1}
      height={height}
    />
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#000000b3',
          color: '#fff',
          padding: '3px',
          margin: '0px',
          borderRadius: '4px',
          lineHeight: '1.4em',
          textAlign: 'left',
          fontSize: '13px'
        }}
      >
        {payload.map((entry, idx) => {
          let fieldName = '';
          switch (entry.dataKey) {
            case 'availability':
              fieldName = 'availability';
              break;
            case 'jordanAvailability':
              fieldName = 'Jordan availability';
              break;
            case 'indiaAvailability':
              fieldName = 'India availability';
              break;
            case 'marketDemand':
              fieldName = 'Market demand';
              break;
            default:
              break;
          }
          return (
            <div key={idx} style={{ padding: '3px 6px', display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: entry.color,
                  display: 'inline-block',
                  marginRight: '6px',
                  borderRadius: '2px'
                }}
              />
              {`${fieldName}: ${
                Math.round((entry.payload[entry.dataKey] + Number.EPSILON) * 1000) / 1000
              }`}
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};
const CustomizedXAxisTick = ({ x, y, payload, index, visibleTicksCount }, options) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={options?.xOffset || -5}
        y={options?.yOffset || 10}
        style={{
          transform: visibleTicksCount > 3 ? 'rotate(-40deg)' : undefined,
          fontSize: '11px',
          fontWeight: 500
        }}
        textAnchor={options?.textAnchor || 'middle'}
        fill="#666"
      >
        {options?.full
          ? payload.value
          : payload.value.length > 7 && visibleTicksCount > 3
          ? payload.value.slice(0, 7) + '..'
          : payload.value}
      </text>
    </g>
  );
};
const customLegend = props => {
  const { payload } = props;
  return (
    <div>
      <div
        style={{
          marginLeft: '10px',
          marginBottom: '5px',
          fontWeight: 700,
          color: 'rgba(0,0,0,.87)'
        }}
      >
        Legend
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor: 'rgba(0,0,0,.05)',
          color: '#afb7c8',
          maxHeight: '50px',
          borderRadius: '3px',
          overflowY: 'auto',
          overflowX: 'hidden',
          fontSize: '12px'
        }}
      >
        {payload.map((entry, index) => (
          <div
            style={{ padding: '2px 6px', display: 'flex', alignItems: 'center', color: '#000' }}
            key={`item-${index}`}
          >
            <div
              style={{
                width: '15px',
                height: '15px',
                backgroundColor: entry.color,
                display: 'inline-block',
                marginRight: '6px',
                borderRadius: '4px'
              }}
            />
            {entry.value}
          </div>
        ))}
      </div>
    </div>
  );
};
const SkillsTrends = props => {
  return (
    <div id="skillsTrends">
      {!props.isLoading ? (
        <>
          {props.resultFound() ? (
            <>
              <ResponsiveContainer width={'100%'} height={660}>
                <LineChart data={props.data} margin={{ top: 5, right: 5, bottom: 105, left: 5 }}>
                  <CartesianGrid />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    height={80}
                    tick={propserties =>
                      CustomizedXAxisTick(propserties, {
                        xOffset: -5,
                        yOffset: 10,
                        textAnchor: 'end',
                        full: true
                      })
                    }
                  />
                  <YAxis domain={[0, 100]} tickCount={6} unit="%" />
                  <Tooltip cursor={<CustomCursor />} content={CustomTooltip} />
                  <Legend
                    layout="horizontal"
                    align="right"
                    verticalAlign="bottom"
                    iconType="square"
                    content={customLegend}
                  />
                  <Line dot={false} type="monotone" dataKey="jordanAvailability" stroke="#A6C8FF" />
                  <Line dot={false} type="monotone" dataKey="indiaAvailability" stroke="#FF9696" />
                  <Line dot={false} type="monotone" dataKey="marketDemand" stroke="#5AA454" />
                  <Line dot={false} type="monotone" dataKey="availability" stroke="#000000" />
                </LineChart>
              </ResponsiveContainer>
            </>
          ) : (
            <EmptyState
              stateImage={
                <div>
                  <img src={EmptyReportImg} alt="" />
                </div>
              }
              stateText={
                <div className="empty-text-section">
                  <div
                    style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                    className="empty-text-message"
                  >
                    No results found
                  </div>
                </div>
              }
            />
          )}
        </>
      ) : (
        <LoadingState />
      )}
    </div>
  );
};
export default SkillsTrends;
