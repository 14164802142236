const authKey = 'authToken';
const canAccess = 'canAccessIds';
export const loginStart = () => ({
  type: 'LOGIN_START'
});
export const loginSuccess = ({ currentUser, userPermissions }) => ({
  type: 'LOGIN_SUCCESS',
  payload: { currentUser, userPermissions }
});
export const loginFailed = msg => ({
  type: 'LOGIN_FAIL',
  payload: msg
});
export const authError = errMessage => ({
  type: 'AUTH_ERROR',
  payload: errMessage
});
export const loginRequest = reqBody => ({
  type: 'LOGIN_REQUEST',
  payload: reqBody
});
export const logoutAction = () => {
  sessionStorage.removeItem(authKey);
  sessionStorage.removeItem(canAccess);
  return {
    type: 'SIGNOUT_SUCCESS'
  };
};
export const checkAuthRequest = () => ({
  type: 'CHECK_Auth_REQUEST'
});
export const checkAuthStart = () => ({
  type: 'CHECK_Auth_START'
});
export const checkAuthFailed = msg => ({
  type: 'CHECK_Auth_FAIL',
  payload: msg
});
