import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import EmptyEmployeeImg from 'assets/img/app_states/empty/Employees.png';
import EmployeesList from './EmployeesList';
import EmployeesGrid from './EmployeesGrid';
import moment from 'moment';

const Employees = props => {
  const savedSortModel = localStorage.getItem('dataGridSort');
  const savedPathname = localStorage.getItem('pathname');
  const [sortModel, setSortModel] = useState(
    savedSortModel && savedPathname === props?.location?.pathname ? JSON.parse(savedSortModel) : []
  );
  const handleSortChange = newSortModel => {
    setSortModel(newSortModel);
    localStorage.setItem('dataGridSort', JSON.stringify(newSortModel));
  };
  const isListView = props?.view === 'list';
  const employees = props.employees.map(e => {
    const lastAssessmet = e.skills
      ?.map(skill => skill?.EmployeeSkill)
      .map(s => s.updatedAt)
      .reduce(function (a, b) {
        return a > b ? a : b;
      }, null);

    return {
      id: e.id,
      no: e.employeeNo,
      firstName: e.firstName,
      lastName: e.lastName,
      email: e.email,
      jobTitle: e.jobTitle,
      department: e.department,
      company: e.company,
      lastAssessment: lastAssessmet ? moment(lastAssessmet).format('DD-MMM-YYYY') : '-'
    };
  });
  useEffect(() => {
    sessionStorage.setItem('employees.currentPage', props?.page?.toString());
  }, [props?.page]);
  const renderDetails = params => {
    return props.navigate(`/employees/${params}/profile`);
  };
  const columns = [
    { field: 'no', headerName: 'Employee ID', width: 150 },
    {
      field: 'fullName',
      headerName: 'Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 300,
      valueGetter: params => `${params.row.firstName || ''} ${params.row.lastName || ''}`
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      width: 300
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 325
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 325
    },
    {
      field: 'lastAssessment',
      headerName: 'Last Assessment',
      width: 300
    }
  ];
  const startIndex = (props?.page - 1) * props?.itemsPerPage;
  const endIndex = startIndex + props?.itemsPerPage;
  const currentEmployees = employees.slice(startIndex, endIndex);
  return (
    <Box sx={{ height: '100%', width: '100%%' }}>
      {employees?.length ? (
        isListView ? (
          <EmployeesList
            sortModel={sortModel}
            employees={employees}
            columns={columns}
            onSortModelChange={handleSortChange}
            page={props?.page}
            setPage={props?.setPage}
            renderDetails={renderDetails}
          />
        ) : (
          <EmployeesGrid employees={currentEmployees} setPage={props?.setPage} page={props?.page} />
        )
      ) : (
        <EmptyState
          stateImage={
            <div>
              <img src={EmptyEmployeeImg} alt="" />
            </div>
          }
          stateText={
            <div className="empty-text-section">
              <div
                style={{ marginBottom: '0.75em', font: '400 15px/24px "Poppins", sans-serif' }}
                className="empty-text-message"
              >
                No employees found.
              </div>
            </div>
          }
        />
      )}
    </Box>
  );
};
export default Employees;
