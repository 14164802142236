import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import { Rating } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LEVELS from 'utilities/constants/levels';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '600',
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));
const EmployeeSkills = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getLevelDescription = skillLevel => {
    const levelData = LEVELS.find(item => item.score === skillLevel);
    return levelData ? levelData.level : 'None';
  };
  return (
    <div id="EmployeeSkills">
      {props.data && props.data.length && props.data.length > 0 ? (
        <>
          <div className={classes.root}>
            {props.data.map((skillGroup, idx) => (
              <Accordion
                expanded={expanded === 'skillGroup' + idx}
                onChange={handleChange('skillGroup' + idx)}
                key={'panel' + idx}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>{skillGroup.groupName}</Typography>
                </AccordionSummary>
                <div className="accordionBody">
                  <div className="allEmpSkills">
                    {skillGroup.skills.map((skill, idx) => (
                      <div className="employeeSkillWrapper" key={'skill' + idx}>
                        {skill.imgUrl && <img className="skillImg" src={skill.imgUrl} alt="" />}
                        <span className="skillName">{skill.name}</span>

                        <div className="rating">
                          <Rating
                            value={skill.level}
                            onChange={e => {
                              props.handleRatingChange(
                                skillGroup.groupName,
                                skill.name,
                                +e.currentTarget.value
                              );
                            }}
                          />{' '}
                          {skill.level !== -1 && skill.level !== 0 ? (
                            <select
                              className="checkBox"
                              value={skill.experienceId}
                              onChange={e => {
                                props.handleCheckBox(
                                  skillGroup.groupName,
                                  skill.name,
                                  skill.usedInAspire,
                                  e.target.value
                                );
                              }}
                            >
                              <option disabled selected value>
                                {' '}
                                -- select years of experience --{' '}
                              </option>
                              {props.experienceRanges.map(record => (
                                <option key={record.id} value={record.id}>
                                  {record.name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            (skill.experienceId = null)
                          )}
                          {skill.level !== -1 && skill.level !== 0 ? (
                            <FormControlLabel
                              className="formControlLabel"
                              control={
                                <Checkbox
                                  checked={skill.usedInAspire}
                                  onChange={e => {
                                    props.handleCheckBox(
                                      skillGroup.groupName,
                                      skill.name,
                                      e.target.checked,
                                      skill.experienceId
                                    );
                                  }}
                                  name="Used in Aspire in the past years"
                                  size="small"
                                />
                              }
                              label="Used in Aspire in the past years"
                              labelPlacement="end"
                            />
                          ) : (
                            (skill.usedInAspire = false)
                          )}
                          <span className="skillRatingSummary">
                            {getLevelDescription(skill.level)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <Divider />
                <AccordionActions>
                  {idx !== 0 && (
                    <Button onClick={() => setExpanded('skillGroup' + (idx - 1))} size="small">
                      Previous
                    </Button>
                  )}
                  {idx !== props.data.length - 1 && (
                    <Button
                      onClick={() => setExpanded('skillGroup' + (idx + 1))}
                      size="small"
                      color="primary"
                    >
                      Next
                    </Button>
                  )}
                </AccordionActions>
              </Accordion>
            ))}
          </div>
        </>
      ) : (
        <EmptyState />
      )}
      <div className="pageActions">
        <Button onClick={props.navigateBack} size="medium" style={{ marginRight: '7px' }}>
          Cancel
        </Button>
        <Button onClick={props.saveData} size="medium" variant="contained" color="primary">
          Save
        </Button>
      </div>
      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          Skills saved successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          Cannot save skills
        </Alert>
      </Snackbar>
    </div>
  );
};
export default EmployeeSkills;
