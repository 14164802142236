import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LayoutContainer from '../../../shared/components/_layout/layout-container/LayoutContainer';
import TitleBarContainer from '../../../shared/components/top-bars/title-bar/TitleBarContainer';
import FeedbackDetails from './FeedbackDetails';
import { feedbackService } from 'modules/Feedback/services/feedback';
const FeedbackDetailsContainer = props => {
  const savedPathname = localStorage.getItem('pathname');
  const [feedbackResponse, setFeedbackResponse] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const isAdmin = () => {
    const { userPermissions: permissions } = props;
    return permissions && Array.isArray(permissions) && permissions.includes('admin');
  };
  const canAccess = feedback => {
    return (
      (isAdmin() || feedback.id ? +props.currentUser.id === +feedback?.requesterId : false) &&
      feedback.status === 'completed'
    );
  };

  useEffect(() => {
    const feedbackId = +props.match.params.id;
    loadData(feedbackId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  const loadData = async feedbackId => {
    setIsLoading(true);
    let feedback = await feedbackService.getFeedbackResponse(feedbackId);
    if (!feedback.data || feedback.data === null) {
      feedback = {};
    }
    if (canAccess(feedback?.data)) {
      setFeedbackResponse(feedback?.data);
      setIsLoading(false);
    } else {
      props.history.push('/accessdenied');
    }
  };
  const titleBar = (
    <TitleBarContainer title={'Feedback details'} backRoute={isAdmin() ? savedPathname : '/'} />
  );
  return (
    <LayoutContainer titleBar={titleBar}>
      <FeedbackDetails feedbackResponse={feedbackResponse} isLoading={isLoading} />
    </LayoutContainer>
  );
};

const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  userPermissions: store.auth.userPermissions
});

export default connect(mapStateToProps, null)(FeedbackDetailsContainer);
