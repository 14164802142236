import React from 'react';
import Menu from '../../../../shared/components/_controls/Menu/Menu';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import SkillBasicInfoDialogContainer from '../skill-basic-info-dialog/SkillBasicInfoDialogContainer';
import ConfirmDialogComponent from '../../../../shared/components/confirm-dialog/ConfirmDialogComponent';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import SkillImg from 'assets/img/app_states/empty/SkillsIcon.png';
import EmptySkillsImg from 'assets/img/app_states/empty/Skills.png';
import Button from '@material-ui/core/Button';
const SkillList = props => {
  return (
    <div id="SkillList">
      {!props.isLoading ? (
        props.groupedSkills?.length > 0 ? (
          <>
            <div className="skillsContainer">
              {props.groupedSkills.map((group, idx) => (
                <div className="skillGroup" key={'group' + idx}>
                  <div className="groupName">{group.groupName}</div>
                  {group.skills.map((skill, idx) => (
                    <div className="skillContainer" key={'skill' + idx}>
                      {skill.imgUrl ? (
                        <img className="skillImg" src={skill.imgUrl} alt="" />
                      ) : (
                        <img className="skillImg" src={SkillImg} alt="" />
                      )}

                      <div className="skillName">{skill.name}</div>
                      <div className="menuContainer">
                        <Menu>
                          <div
                            className="CustommenuItem"
                            onClick={() => {
                              props.openSideContent(skill);
                              props.openDrawer?.();
                            }}
                          >
                            <InfoIcon />
                            <span style={{ marginLeft: '16px' }}>Info</span>
                          </div>
                          <div
                            className="CustommenuItem"
                            onClick={() => props.openSkillDialog(skill)}
                          >
                            <EditIcon />
                            <span style={{ marginLeft: '16px' }}>Edit</span>
                          </div>
                          <div
                            className="CustommenuItem"
                            onClick={() => props.confirmDelete(skill)}
                          >
                            <DeleteIcon />
                            <span style={{ marginLeft: '16px' }}>Delete</span>
                          </div>
                        </Menu>
                      </div>
                    </div>
                  ))}
                  <Divider />
                </div>
              ))}
            </div>
          </>
        ) : (
          <EmptyState
            stateImage={
              <div>
                <img src={EmptySkillsImg} alt="" />
              </div>
            }
            stateText={
              <div className="empty-text-section">
                <div
                  style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                  className="empty-text-message"
                >
                  You don't have any skills yet.
                </div>
                <div className="empty-text-action" style={{ textAlign: 'center' }}>
                  <Button
                    style={{ position: 'static', textTransform: 'unset' }}
                    onClick={() => props.openSkillDialog()}
                    variant="contained"
                    color="primary"
                  >
                    Create new skill
                  </Button>
                </div>
              </div>
            }
          />
        )
      ) : (
        <LoadingState />
      )}
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={props.isDialogOpen}
        onClose={props.closeSkillDialog}
        aria-labelledby="form-dialog-title"
      >
        <SkillBasicInfoDialogContainer
          data={props.dialogData}
          closeDialog={props.closeSkillDialog}
          onSkillCreate={props.onSkillCreate}
        />
      </Dialog>

      <Dialog open={props.isDeleteDialogOpen} onClose={() => props.closeDeleteDialog()}>
        <ConfirmDialogComponent data={props.toDelete} close={props.closeDeleteDialog} />
      </Dialog>

      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          {props.successToastText}
        </Alert>
      </Snackbar>

      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          {props.errorToastText}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default SkillList;
