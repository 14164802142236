import React from 'react';
import Avatar from 'react-avatar';

const EmployeeBasic = props => {
  const fullName = props.model ? `${props.model.firstName} ${props.model.lastName}` : undefined;
  return (
    <div id="EmployeeBasic" className={props.isLastCard ? 'lastCard' : ''}>
      {props?.model && (
        <>
          <div className="clickableDiv" onClick={props.navigate}>
            <div className="basicEmpInfo">
              <div className="empFullName">
                <span className="headline">{fullName}</span>
              </div>
              {props?.model?.photoUrl ? (
                <img className="empAvatar" src={props.model.photoUrl} alt="" />
              ) : (
                <Avatar className="empAvatar" name={fullName} />
              )}
              <div className="empJobTitle">
                <span className="subheadline">{props.model?.email}</span>
                <span className="subheadline">
                  {props.title ? props.title : props.model?.jobTitle}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default EmployeeBasic;
