import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
// axios.interceptors.response.use(function(response) {
//     console.log("success response")
//     response.data={...response.data, interceptorMod:"mohammad samara"}
//     return response;
// },function(err) {
//     console.log("bad response");
//     alert("error")
//     if (err.response.status === 401) {
//         store.dispatch(logoutAction());
//     }
//     return Promise.reject(err);
// });
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
