import React from 'react';
import Menu from '../../../../shared/components/_controls/Menu/Menu';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialogComponent from '../../../../shared/components/confirm-dialog/ConfirmDialogComponent';
import TagInfoDialogContainer from '../tag-info-dialog/TagInfoDialogContainer';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import EmptySkillsImg from 'assets/img/app_states/empty/Skills.png';
const TagsList = props => {
  return (
    <div id="tagList">
      {!props.isLoading ? (
        props.tags.length > 0 ? (
          <div className="tagsContainer">
            {props.tags.map((tag, idx) => (
              <div className="tagContainer" key={'tag' + idx}>
                {tag.color && <div className="tagAvatar" style={{ backgroundColor: tag.color }} />}
                <div className="tagName">{tag.name}</div>
                <div className="menuContainer">
                  <Menu>
                    <div
                      className="CustommenuItem"
                      onClick={() => {
                        props.openSideContent(tag);
                        props.openDrawer?.();
                      }}
                    >
                      <InfoIcon />
                      <span style={{ marginLeft: '16px' }}>Info</span>
                    </div>
                    <div className="CustommenuItem" onClick={() => props.openTagDialog(tag)}>
                      <EditIcon />
                      <span style={{ marginLeft: '16px' }}>Edit</span>
                    </div>
                    <div className="CustommenuItem" onClick={() => props.confirmDelete(tag)}>
                      <DeleteIcon />
                      <span style={{ marginLeft: '16px' }}>Delete</span>
                    </div>
                  </Menu>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <EmptyState
            stateImage={
              <div>
                <img src={EmptySkillsImg} alt="" />
              </div>
            }
            stateText={
              <div className="empty-text-section">
                <div
                  style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                  className="empty-text-message"
                >
                  You don't have any tags yet.
                </div>
              </div>
            }
          />
        )
      ) : (
        <LoadingState />
      )}

      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={props.isDialogOpen}
        onClose={() => props.closeTagDialog()}
        aria-labelledby="form-dialog-title"
      >
        <TagInfoDialogContainer
          data={props.dialogData}
          closeDialog={props.closeTagDialog}
          onTagCreate={props.onTagCreate}
        />
      </Dialog>

      <Dialog open={props.isDeleteDialogOpen} onClose={() => props.closeDeleteDialog()}>
        <ConfirmDialogComponent data={props.toDelete} close={props.closeDeleteDialog} />
      </Dialog>

      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          {props.successToastText}
        </Alert>
      </Snackbar>

      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          {props.errorToastText}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default TagsList;
