import React from 'react';
import Menu from '../../../../shared/components/_controls/Menu/Menu';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialogComponent from '../../../../shared/components/confirm-dialog/ConfirmDialogComponent';
import RoleDialogContainer from '../role-dialog/RoleDialogContainer';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import Button from '@material-ui/core/Button';
const RolesList = props => {
  return (
    <div id="RolesList">
      {!props.isLoading ? (
        props.roles.length > 0 ? (
          <div className="rolesContainer">
            {props.roles.map((role, idx) => (
              <div key={idx} className="roleContainer">
                <div className="RoleName">{role.name}</div>
                <div className="menuContainer">
                  <Menu>
                    <div
                      className="CustommenuItem"
                      onClick={() => {
                        props.openSideContent(role);
                        props.openDrawer?.();
                      }}
                    >
                      <InfoIcon />
                      <span style={{ marginLeft: '16px' }}>Info</span>
                    </div>
                    <div className="CustommenuItem" onClick={() => props.openRoleDialog(role)}>
                      <EditIcon />
                      <span style={{ marginLeft: '16px' }}>Edit</span>
                    </div>
                    <div className="CustommenuItem" onClick={() => props.confirmDelete(role)}>
                      <DeleteIcon />
                      <span style={{ marginLeft: '16px' }}>Delete</span>
                    </div>
                  </Menu>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <EmptyState
            stateText={
              <div className="empty-text-section">
                <div
                  style={{ marginBottom: '0.75em', fontSize: '15px' }}
                  className="empty-text-message"
                >
                  You don't have any roles yet.
                </div>
                <div className="empty-text-action" style={{ textAlign: 'center' }}>
                  <Button
                    style={{ position: 'static', textTransform: 'unset' }}
                    onClick={() => props.openRoleDialog()}
                    variant="contained"
                    color="primary"
                  >
                    Create new Role
                  </Button>
                </div>
              </div>
            }
          />
        )
      ) : (
        <LoadingState />
      )}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.isDialogOpen}
        onBackdropClick={props.closeRoleDialog}
        aria-labelledby="form-dialog-title"
      >
        <RoleDialogContainer
          data={props.dialogData}
          closeDialog={props.closeRoleDialog}
          onRoleCreate={props.onRoleCreate}
        />
      </Dialog>

      <Dialog open={props.isDeleteDialogOpen} onBackdropClick={props.closeDeleteDialog}>
        <ConfirmDialogComponent data={props.toDelete} close={props.closeDeleteDialog} />
      </Dialog>

      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          {props.successToastText}
        </Alert>
      </Snackbar>

      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          {props.errorToastText}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default RolesList;
