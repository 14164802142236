import React, { useState } from 'react';
import './EmployeeProjects.scss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { CardHeader, Divider, Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import projectMapper from 'utilities/mappers/project.mapper';
import { getInitials, getRandomColor } from 'utilities/helpers/team';

const EmployeeProjects = ({ projects, navigate, isAdmin, directReports }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expand = () => {
    setIsExpanded(!isExpanded);
  };
  const hasProjects = !!projectMapper(projects)?.length;
  const hasDirectReports = !!directReports?.length;
  return (
    <div
      className="projectsWrapper"
      style={hasProjects || hasDirectReports ? {} : { display: 'none' }}
    >
      <div className="projects">
        {hasProjects && <h4 className="sectionName">My projects</h4>}
        {projectMapper(projects)?.map(({ project, team }) => {
          const limit = team?.length > 5 ? 5 : team?.length;
          const teamMembers = isExpanded ? team : team?.slice(0, limit);
          return (
            <Card className="projectCard" key={project?.id}>
              <CardHeader
                style={{ padding: '.5rem' }}
                titleTypographyProps={{
                  fontFamily: '"Poppins", sans-serif',
                  fontSize: '16.25px',
                  fontWeight: '700'
                }}
                title={project?.name}
              />

              <CardContent className="team">
                {teamMembers?.length > 0 && (
                  <Stack className="teamMembers">
                    {teamMembers?.map((teamMember, idx) => (
                      <Tooltip key={idx} title={teamMember?.firstName + ' ' + teamMember?.lastName}>
                        <Avatar
                          className="teamMemberImg"
                          style={{ backgroundColor: getRandomColor(), cursor: 'pointer' }}
                          size="40"
                          onClick={() => {
                            // eslint-disable-next-line no-unused-expressions
                            isAdmin() ? navigate(`/employees/${teamMember?.id}/profile`) : null;
                          }}
                        >
                          {getInitials(teamMember?.firstName + ' ' + teamMember?.lastName)}
                        </Avatar>
                      </Tooltip>
                    ))}
                    {team?.length > limit && (
                      <Tooltip title={isExpanded ? 'show less' : 'show more'}>
                        <Avatar
                          className="teamMemberImg"
                          style={{ cursor: 'pointer' }}
                          size="40"
                          onClick={() => {
                            expand();
                          }}
                        >
                          {isExpanded ? '-' : '+'}
                          {team?.length - limit}
                        </Avatar>
                      </Tooltip>
                    )}
                  </Stack>
                )}
              </CardContent>
              <Divider />
              <div className="projectDataWrapper">
                <div className="projectData">
                  <span className="label">Client :</span>
                  <span className="data">{project?.client?.name}</span>
                </div>
                <div className="projectData">
                  <span className="label">Team Lead :</span>
                  <span
                    className="dataLink"
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      isAdmin() ? navigate(`/employees/${project?.employee?.id}/profile`) : null;
                    }}
                  >
                    {project?.employee?.firstName + ' ' + project?.employee?.lastName}
                  </span>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
      <div className="employees">
        {hasDirectReports && <h4 className="sectionName">Direct Reports</h4>}

        <div className="empCardsWrapper">
          {directReports?.map(emp => (
            <Card
              className="empCard"
              key={emp?.id}
              onClick={() => {
                navigate(`/employees/${emp?.id}/profile`);
              }}
            >
              <CardContent className="empCardBody">
                <Tooltip title={emp?.first_name + ' ' + emp?.last_name}>
                  <Avatar
                    className="teamMemberImg"
                    size="40"
                    style={{ backgroundColor: getRandomColor(), cursor: 'pointer' }}
                  >
                    {getInitials(emp?.first_name + ' ' + emp?.last_name)}
                  </Avatar>
                </Tooltip>
                <div className="empData">
                  <span className="name">{emp?.first_name + ' ' + emp?.last_name}</span>
                  <span className="email">{emp?.email}</span>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmployeeProjects;
