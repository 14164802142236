import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
const QuestionsRating = ({ question, index, feedbackId, value, handleAnswerChange }) => {
  const handleChange = e => {
    handleAnswerChange(question?.questionId, e.target.value);
  };
  return (
    <>
      <label className="questionLabel">
        {index}) {question?.questionDescription}
      </label>
      <FormControl disabled={feedbackId === null}>
        <RadioGroup
          className="radioGroup"
          aria-labelledby="demo-controlled-radio-buttons-group"
          name={`question-${question?.templateQuestionsId}`}
          value={value || ''}
        >
          {question?.questionOptions?.map(({ value, name }) => (
            <FormControlLabel
              key={value}
              value={name}
              control={<Radio />}
              label={name}
              onChange={handleChange}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default QuestionsRating;
