import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Calendar from 'modules/shared/components/_employee-views/Calendar/DatePicker/Calendar';
import TagsContainer from 'modules/shared/components/tags/TagsContainer';
const ClientDialog = props => {
  const [clientFieldsProps, setClientFieldsProps] = useState({
    name: { error: false, helperText: '' },
    imgUrl: { error: false, helperText: '' }
  });

  const handleBlur = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value?.trim() === '') {
      setClientFieldsProps({
        ...clientFieldsProps,
        [name]: { error: true, helperText: 'Field must not be empty' }
      });
    } else {
      setClientFieldsProps({
        name: { error: false, helperText: '' },
        imgUrl: { error: false, helperText: '' }
      });
    }
  };
  return (
    <div id="ClientDialog">
      <div className="ClientDialogTitle">{props.client.id ? props.client.name : 'New Client'}</div>

      <div className="ClientDialogContent">
        <TextField
          onChange={e => {
            props.handleChange(e);
          }}
          defaultValue={props.client.name}
          name="name"
          required
          autoFocus
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          fullWidth
          onBlur={handleBlur}
          {...clientFieldsProps?.name}
        />
        <Calendar
          placeholder="Contract Sign Date"
          handleChange={props.handleDateChange}
          date={props.client.signingDate}
          fullWidth={true}
        />
        <TextField
          defaultValue={props.client.imgUrl}
          onChange={props.handleChange}
          name="imgUrl"
          required
          margin="dense"
          id="ImgUrl"
          label="Logo"
          type="text"
          fullWidth
          onBlur={handleBlur}
          {...clientFieldsProps?.imgUrl}
        />
        <TagsContainer
          handleTagChange={props.handleTagChange}
          tags={props.client.tags?.map(tag => tag.id)}
        />
      </div>

      <div className="ClientDialogActions">
        <Button onClick={props.cancel}>Cancel</Button>
        <Button onClick={props.saveClient} disabled={!props.isValid} color="primary">
          {props.client.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
};
export default ClientDialog;
