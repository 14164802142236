import { useEffect, useState } from 'react';
import LayoutContainer from '../../../shared/components/_layout/layout-container/LayoutContainer';
import { EmployeesService } from '../../../shared/services/employees';
import Employees from './Employees';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import './EmployeesList.scss';
import TitleBarContainer from 'modules/shared/components/top-bars/title-bar/TitleBarContainer';
import TextField from '@material-ui/core/TextField';
import Upload from 'components/Upload/upload';
import GridViewIcon from '@material-ui/icons/GridOn';
import ViewListIcon from '@material-ui/icons/ViewList';
import Pagination from '@mui/material/Pagination';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';

const EmployeesContainer = props => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [file, setFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [errorToastText, setErrorToastText] = useState('');
  const [loadingUpload, setLoadingUpload] = useState(false);
  const itemsPerPage = 12;
  const savedPage = sessionStorage.getItem('employees.currentPage');
  const savedView = localStorage.getItem('view');
  const [page, setPage] = useState(savedPage ? parseInt(savedPage) : 1);
  const [view, setView] = useState(savedView);

  const filterEmployees = (name = '') => {
    if (name === '') {
      setFilteredEmployees(employees);
    } else {
      const filteredEmployees = employees.filter(
        employee =>
          `${employee.firstName} ${employee.lastName}`.toLowerCase().indexOf(name.toLowerCase()) >
          -1
      );
      setFilteredEmployees(filteredEmployees);
    }
  };

  const clearInputFile = () => {
    document.getElementById('upload-input').value = null;
  };
  const navigate = route => {
    props.history.push(route, { prevPathname: props?.location?.pathname });
  };
  const fetchData = async () => {
    setIsLoading(true);
    const employeesData = await EmployeesService.filter();
    setEmployees(employeesData);
    setFilteredEmployees(employeesData);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
    localStorage.setItem('pathname', props?.location?.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFile = async e => {
    try {
      e.preventDefault();
      if (showErrorToast) return;
      setErrorToastText('');
      setLoadingUpload(true);
      const formData = new FormData();
      formData.append('file', file);

      await EmployeesService.uploadEmployees(formData).then(() => {
        setSuccessToastText('uploaded successfully');
        clearInputFile();
        setShowSuccessToast(true);
        setLoadingUpload(false);
        setOpenDialog(false);

        fetchData();
      });
    } catch (error) {
      setShowErrorToast(true);
      setErrorToastText('failed to upload');
      setOpenDialog(false);
      setLoadingUpload(false);
      clearInputFile();
    }
  };
  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };
  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };
  const handleClose = () => {
    setOpenDialog(false);
    document.getElementById('upload-input').value = null;
  };
  const onInputChange = e => {
    setErrorToastText('');
    setSuccessToastText('');
    const selectedFile = e.target.files[0];
    // Checking if the file type is allowed or not
    const allowedTypes = ['text/csv'];
    if (!allowedTypes.includes(selectedFile?.type)) {
      setShowErrorToast(true);
      setErrorToastText('Select csv file');
      setFile(null);
      document.getElementById('upload-input').value = null;
      return;
    }

    setShowErrorToast(false);
    setOpenDialog(true);
    setFile(e.target.files[0]);
  };
  const handleCloseSecondaryBar = () => {
    setFilteredEmployees(employees);
  };
  const handleChangeView = viewType => {
    localStorage.setItem('view', viewType);
    setView(viewType);
    sessionStorage.setItem('employees.currentPage', '1');
    setPage(1);
  };
  const searchTitleBarSecondary = (
    <>
      <TextField
        onChange={e => filterEmployees(e?.target?.value)}
        placeholder={'Search Employee'}
        autoFocus
        margin="dense"
        fullWidth
      />
    </>
  );
  const titleBarPrimary = toggleMode => (
    <>
      <IconButton
        style={{ color: 'inherit' }}
        onClick={() => {
          toggleMode(true);
        }}
      >
        <SearchIcon />
      </IconButton>
    </>
  );
  const titleBar = (
    <TitleBarContainer
      title="Employees"
      backRoute="/"
      titleBarPrimary={titleBarPrimary}
      onClose={handleCloseSecondaryBar}
      titleBarSecondary={searchTitleBarSecondary}
    />
  );
  return (
    <LayoutContainer titleBar={titleBar}>
      {!isLoading ? (
        <div>
          <div className="controlWrapper">
            <Upload
              submitFile={submitFile}
              onInputChange={onInputChange}
              file={file}
              openDialog={openDialog}
              showErrorToast={showErrorToast}
              showSuccessToast={showSuccessToast}
              errorToastText={errorToastText}
              successToastText={successToastText}
              closeErrorToast={closeErrorToast}
              closeSuccessToast={closeSuccessToast}
              handleClose={handleClose}
              loadingUpload={loadingUpload}
            />
            {filteredEmployees?.length > 0 && (
              <div className="viewOptions">
                <ViewListIcon
                  className={`option ${view === 'list' ? 'active' : ''}`}
                  onClick={() => {
                    handleChangeView('list');
                  }}
                />
                <GridViewIcon
                  className={`option ${view === 'grid' ? 'active' : ''}`}
                  onClick={() => {
                    handleChangeView('grid');
                  }}
                />
              </div>
            )}
          </div>
          <Employees
            employees={filteredEmployees}
            navigate={navigate}
            isLoading={isLoading}
            view={view}
            itemsPerPage={itemsPerPage}
            page={page}
            setPage={setPage}
          />
        </div>
      ) : (
        <LoadingState />
      )}

      {view === 'grid' && (
        <Pagination
          className="pagination"
          page={page}
          onChange={(_event, value) => {
            setPage(value);
          }}
          count={Math.ceil(employees?.length / itemsPerPage)}
          color="primary"
        />
      )}
    </LayoutContainer>
  );
};

export default EmployeesContainer;
