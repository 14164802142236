import { useEffect, useState } from 'react';
import _ from 'lodash';
import CurrentInventory from './CurrentInventory';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import TitleBarContainer from 'modules/shared/components/top-bars/title-bar/TitleBarContainer';
import IconButton from '@material-ui/core/IconButton';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/Check';
import './CurrentInventory.scss';
import { SkillsInventoryService } from '../../services/skills-inventory';
import { Link } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { SkillsService } from 'modules/shared/services/skills-service';
import LEVELS from 'utilities/constants/levels';

const CurrentInventoryContainer = props => {
  const [skills, setSkills] = useState([]);
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [depts, setDepts] = useState([]);
  const [view, setView] = useState('compined');
  const [filterForm, setFilterForm] = useState({
    minLevel: 4,
    maxLevel: 5,
    dept: 1,
    group: 'General'
  });
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    filterInventory();
  }, []);

  const filterInventory = async () => {
    let rawDepartments = [];
    const rawGroups = [];
    const skills = await SkillsService.filter();
    setSkills(skills);
    skills.forEach(skill => {
      if (skill.department) {
        const existingDepartment = rawDepartments?.find(dept => dept?.id === skill?.department?.id);
        if (!existingDepartment) {
          const departmentData = { id: skill?.department?.id, name: skill.department.name };
          rawDepartments = [...rawDepartments, departmentData];
        }
      }
      if (skill.group) {
        rawGroups.push(skill.group);
      }
    });
    const groups = [...new Set(rawGroups)];
    const data = await loadData('compined');
    if (data) {
      setGroups(groups);
      setDepts(rawDepartments);
      setData(data);
      setIsLoading(false);
    } else {
      setGroups(groups);
      setDepts(depts);
      setError(true);
      setIsLoading(false);
    }
  };
  const loadData = async view => {
    switch (view) {
      case 'compined':
        const combinedData = await viewCompinedTechnologies();
        if (combinedData) {
          const data = combinedData.map(el => {
            return { name: el.name, value: el.value };
          });
          return data;
        } else {
          return undefined;
        }
      case 'with_benchmarks':
        const skillList = await viewCompinedTechnologies();
        if (skillList) {
          const employeesCount = 10;
          const multiChartData = skillList.map(skill =>
            SkillsInventoryService.multiLineChartSkillMapper(skill, employeesCount)
          );
          return multiChartData;
        } else {
          return undefined;
        }
      default:
        const groupedData = await viewGroupedTechnologies();
        if (groupedData) {
          return groupedData;
        } else {
          return undefined;
        }
    }
  };
  const changeView = async view => {
    setIsLoading(true);
    const data = await loadData(view);
    if (data) {
      setData(data);
      setView(view);
      setIsLoading(false);
    } else {
      setError(true);
      setIsLoading(false);
    }
  };
  const viewCompinedTechnologies = async () => {
    const filter = { ...filterForm };
    try {
      const { data: inventory } = await SkillsInventoryService.inventory(
        filter.minLevel,
        filter.maxLevel,
        filter.dept,
        filter.group
      );
      const skillsInventoryArray = inventory.map(i => {
        return { ...i, value: +i.count };
      });
      return skillsInventoryArray;
    } catch {
      setError(true);
    }
  };
  const viewGroupedTechnologies = async () => {
    const filter = { ...filterForm };
    try {
      const { data: inventory } = await SkillsInventoryService.inventory(
        filter.minLevel,
        filter.maxLevel,
        filter.dept,
        filter.group
      );
      const skillsInventoryArray = inventory.map(i => {
        return { group: i.group, name: i.name, value: +i.count };
      });
      const grouped = _.groupBy(skillsInventoryArray, skill => {
        return skill.group;
      });
      const groupedSkillsArray = [];
      for (const [key, value] of Object.entries(grouped)) {
        groupedSkillsArray.push({
          name: key,
          data: value.map(skill => ({ name: skill.name, value: skill.value }))
        });
      }
      return groupedSkillsArray;
    } catch {
      setError(true);
    }
  };
  const resultFound = () => {
    if (view === 'compined') return data.some(a => a.value > 0);
    if (view === 'with_benchmarks') return data && data.some(a => a.availability > 0);
    else return data.some(a => a.data.some(b => b.value > 0));
  };
  const handleFilterChange = async e => {
    const { name, value } = e.target;
    setFilterForm(prevFilterForm => ({
      ...prevFilterForm,
      [name]: value
    }));
    if (name === 'dept') {
      try {
        const filteredGroups = [];
        skills.forEach(skill => {
          if (skill.group) {
            if (value === skill?.department?.id) {
              filteredGroups.push(skill.group);
            }
          }
        });
        const groups = [...new Set(filteredGroups)];
        setGroups(groups);
      } catch (error) {
        setError(true);
        setGroups([]);
      }
    }
  };
  const applyFilter = async () => {
    setIsLoading(true);
    const data = await loadData(view);
    setData(data || []);
    setIsLoading(false);
  };

  const titleBarSecondary = (
    <div id="inventoryFilterForm" style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <FormControl style={{ flex: '1 0', marginRight: '0.25em' }}>
        <InputLabel shrink id="minLevel-select-label">
          Minimum level
        </InputLabel>
        <Select
          labelId="minLevel-select-label"
          id="minLevel-select"
          value={filterForm.minLevel}
          onChange={handleFilterChange}
          name="minLevel"
        >
          {LEVELS?.map(lvl => (
            <MenuItem key={lvl?.score} value={lvl?.score}>
              {lvl?.level}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ flex: '1 0', marginRight: '0.25em' }}>
        <InputLabel shrink id="maxLevel-select-label">
          Maximum level
        </InputLabel>
        <Select
          labelId="maxLevel-select-label"
          id="maxLevel-select"
          value={filterForm.maxLevel}
          onChange={handleFilterChange}
          name="maxLevel"
        >
          {LEVELS?.map(lvl => (
            <MenuItem key={lvl?.score} value={lvl?.score}>
              {lvl?.level}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ flex: '1 0', marginRight: '0.25em' }}>
        <InputLabel id="dept-select-label">Department</InputLabel>
        <Select
          labelId="dept-select-label"
          id="dept-select"
          value={filterForm.dept}
          onChange={handleFilterChange}
          name="dept"
        >
          <MenuItem value={''}>All</MenuItem>
          {depts?.map(dept => (
            <MenuItem key={dept?.id} value={dept?.id}>
              {dept?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ flex: '1 0', marginRight: '0.25em' }}>
        <InputLabel id="group-select-label">Group</InputLabel>
        <Select
          labelId="group-select-label"
          id="group-select"
          value={filterForm.group}
          onChange={handleFilterChange}
          name="group"
        >
          <MenuItem value={''}>All</MenuItem>
          {groups?.map(group => (
            <MenuItem key={group} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Fab
        onClick={applyFilter}
        style={{ backgroundColor: '#005792', color: '#fff', cursor: 'pointer' }}
        size="small"
      >
        <CheckIcon />
      </Fab>
    </div>
  );
  const titleBarPrimary = (toggleMode, openDrawer) => (
    <>
      <IconButton onClick={() => toggleMode(true)} style={{ color: 'inherit' }}>
        <FilterListIcon />
      </IconButton>
    </>
  );
  const titleBar = (
    <TitleBarContainer
      title="Skills inventory"
      backRoute="/"
      titleBarPrimary={titleBarPrimary}
      titleBarSecondary={titleBarSecondary}
    />
  );
  return (
    <LayoutContainer titleBar={titleBar}>
      <CurrentInventory
        data={data}
        groups={groups}
        depts={depts}
        view={view}
        error={error}
        changeView={changeView}
        isLoading={isLoading}
        resultFound={resultFound}
      />
    </LayoutContainer>
  );
};
export default CurrentInventoryContainer;
