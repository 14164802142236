const SKILLS_MATRIX_BENEFITS = [
  {
    id: 1,
    title: 'Increased visibility of employee skills',
    description:
      'Skills matrix software provides a clear overview of the skills, expertise, and experience of each employee, making it easier for managers to identify skill gaps and assign tasks accordingly.',
    image: 'employee_skills.png'
  },
  {
    id: 2,
    title: 'Improved team collaboration',
    description:
      'Skills matrix enables team members to collaborate effectively and share their skills, which helps ensure that everyone is contributing their best and working towards the same goals.',
    image: 'team-collaboration.png'
  },
  {
    id: 3,
    title: 'Better succession planning',
    description:
      'With a clear overview of the skills and experience of each employee, managers can plan for the future and identify potential successors for key positions.',
    image: 'planning.png'
  },
  {
    id: 4,
    title: 'Enhanced training and development',
    description:
      'The software helps managers to identify areas where employees need additional training and support, and to develop tailored training plans to meet their needs.',
    image: 'training.png'
  },
  {
    id: 5,
    title: 'Streamlined performance management',
    description:
      'Skills matrix software provides a consistent and systematic approach to performance management, making it easier to monitor progress and identify areas for improvement.',
    image: 'performance.png'
  }
];

export default SKILLS_MATRIX_BENEFITS;
