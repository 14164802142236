import { withRouter } from 'react-router';
import EmployeeBasic from './EmployeeBasic';
import './EmployeeBasic.scss';

const EmployeeBasicContainer = props => {
  const navigate = () => {
    if (props.routerLink) {
      props.history.push(props.routerLink);
    }
  };

  return (
    <EmployeeBasic
      isLastCard={props?.isLastCard}
      model={props?.data}
      title={props.title}
      navigate={navigate}
    />
  );
};
export default withRouter(EmployeeBasicContainer);
