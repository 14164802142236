import _ from 'lodash';
import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const filter = (department = undefined, group = undefined, name = undefined) => {
  return axiosInstances
    .withAuthToken()
    .get(`/skills/?d=${department || ''}&g=${group || ''}&n=${name || ''}`);
};
const groupedList = async () => {
  const { data: skills } = await filter();
  const grouped = _.groupBy(skills, skill => {
    return skill.group;
  });
  const groupedSkillsArray = [];
  for (const [key, value] of Object.entries(grouped)) {
    groupedSkillsArray.push({ name: key, skills: value });
  }
  return groupedSkillsArray;
};
const listGroups = () => {
  return axiosInstances.withAuthToken().get('/skills/groups/');
};
const listDepartments = () => {
  return axiosInstances.withAuthToken().get('/skills/departments/');
};
const inventory = (
  minLevel = undefined,
  maxLevel = undefined,
  department = undefined,
  group = undefined
) => {
  return axiosInstances.withAuthToken().get(`/skills/inventory?minLevel=${minLevel || 1}&maxLevel=${
    maxLevel || 5
  }${department ? `&department=${department}` : ''}
      ${group ? `&group=${group || ''}` : ''}`);
};
const multiLineChartSkillMapper = (skill, employeesCount = 10) => {
  const modeledSkill = {
    name: skill.name,
    availability: +(skill.count / employeesCount) * 100
  };
  modeledSkill['jordanAvailability'] = mapBenchMarkValue(skill.availabilityJordan);
  modeledSkill['indiaAvailability'] = mapBenchMarkValue(skill.availabilityIndia);
  modeledSkill['marketDemand'] = mapBenchMarkValue(skill.marketDemand);
  return modeledSkill;
};
const mapBenchMarkValue = benchMark => {
  switch (benchMark) {
    case 'High':
      return 100;
    case 'Medium':
      return 60;
    default:
      return 20;
  }
};
export const SkillsInventoryService = {
  inventory,
  listGroups,
  listDepartments,
  groupedList,
  filter,
  multiLineChartSkillMapper
};
