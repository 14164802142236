import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import StarIcon from '@material-ui/icons/Star';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckIcon from '@material-ui/icons/Check';
import Divider from '@mui/material/Divider';
import getExperienceDesc from 'utilities/helpers/experienceEnums';
import { Tooltip } from '@material-ui/core';

const EmployeeSkillCard = ({ skillGroup, getSkillLevel }) => {
  const skillGroupLength = skillGroup?.skills?.length;
  const skillsToView = 4;
  const [expand, setExpand] = useState(false);
  const [expandable, setExpandable] = useState(false);
  const [skills, setSkills] = useState(skillGroup?.skills || []);

  useEffect(() => {
    const fillSkills = () => {
      if (skillGroupLength > 0) {
        if (skillGroupLength <= skillsToView) {
          setSkills(skillGroup?.skills);
          setExpandable(false);
        } else {
          setExpandable(true);
          expand
            ? setSkills(skillGroup?.skills)
            : setSkills(skillGroup?.skills?.slice(0, skillsToView));
        }
      }
    };
    fillSkills();
  }, [expand]);

  const handleShowMoreLessChange = () => {
    setExpand(!expand);
  };
  return (
    <Card className="groupCard">
      <CardHeader
        className="title"
        style={{ padding: '.5rem' }}
        titleTypographyProps={{
          fontFamily: '"Poppins", sans-serif',
          fontSize: '14.25px',
          fontWeight: '600'
        }}
        title={skillGroup.groupName}
      />
      <CardContent className="groupCardContent">
        {skills?.map((skill, idx) => {
          return (
            <div className="empSkill" key={idx}>
              <div className="skillInfo">
                <Tooltip title={skill.name}>
                  <span className="skill_name">{skill.name}</span>
                </Tooltip>
                <span className="skillRating">
                  {getSkillLevel(skill.level).map((_, idx) => (
                    <i className="filledStar" key={idx}>
                      <StarIcon />
                    </i>
                  ))}
                </span>
              </div>

              {(skill?.experienceId || skill?.usedInAspire) && (
                <div className="skillDetails">
                  <Divider orientation="vertical" flexItem />
                  <div className="skillInfoWrapper">
                    <div className="info">
                      <span className="label">Experience :</span>
                      {getExperienceDesc(skill?.experienceId) || ' -'}
                    </div>
                    {skill?.usedInAspire && (
                      <div className="info">
                        <CheckIcon className="ticIcon" size="small" />
                        <span className="label">used in aspire</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {expandable && (
          <div className="showMoreLessWrapper" onClick={handleShowMoreLessChange}>
            {!expand ? (
              <span className="showMoreLess">
                show more <ExpandMoreIcon />
              </span>
            ) : (
              <span className="showMoreLess">
                show less <ExpandLessIcon />
              </span>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default EmployeeSkillCard;
