import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { allocationService } from '../../services/allocation';
import AllocationDialog from './AllocationDialog';
import './AllocationDialog.scss';
const AllocationDialogContainer = props => {
  const [isValid, setIsValid] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [employeeError, setEmployeeError] = useState(false);
  const [effortsError, setEffortsError] = useState(false);
  const [overlapError, setOverlapError] = useState(false);
  const [allocation, setAllocation] = useState({
    id: props?.data?.id || undefined,
    employee: props?.data?.employee || [],
    bench: props?.data?.bench || false,
    title: props?.data?.title || null,
    efforts: props?.data?.efforts || [
      {
        startDate: null,
        endDate: null,
        effort: 0
      }
    ],
    empIds: props?.data?.empIds || []
  });
  const reset = () => {
    setAllocation({
      id: undefined,
      employee: [],
      bench: false,
      title: null,
      efforts: [{ startDate: null, endDate: null, effort: 0 }],
      empIds: allocation.empIds
    });
  };
  const cancel = () => {
    reset();
    props.closeDialog();
  };
  const handleChange = e => {
    const updatedAllocation = { ...allocation, [e.target.name]: e.target.value };
    setAllocation(updatedAllocation);
  };
  const handleEffortChange = (value, name, idx) => {
    const efforts = [...allocation.efforts];
    efforts[idx] = { ...efforts[idx], [name]: value };
    const updatedAllocation = { ...allocation, efforts };
    validation(updatedAllocation, ['effort']);
    setAllocation(updatedAllocation);
  };
  const handleEmployeesChange = employees => {
    const empIds = employees.map(emp => emp.id);
    const updatedAllocation = { ...allocation, employee: empIds };
    // const { isValid, dateRangeError } = this.validation(updatedAllocation);
    // this.setState({ allocation: updatedAllocation, isValid, dateRangeError });
    validation(updatedAllocation, ['employee']);
    setAllocation(updatedAllocation);
  };
  const validation = (allocation, validate) => {
    if (validate.includes('employee')) {
      if (allocation.employee.length === 0) {
        setEmployeeError(true);
      } else {
        setEmployeeError(false);
      }
    }
    if (validate.includes('effort')) {
      const canMakeTimeLine = allocation.efforts.every(
        effort =>
          effort.endDate &&
          effort.startDate &&
          new Date(effort.startDate).getTime() < new Date(effort.endDate).getTime()
      );
      if (!canMakeTimeLine) {
        setEffortsError(true);
        setOverlapError(false);
        setIsValid(false);
      } else if (allocation.efforts.length === 1) {
        setEffortsError(false);
        setOverlapError(false);
        setIsValid(true);
      } else {
        const timeLine = allocation.efforts
          .map(effort => {
            return { startDate: effort.startDate, endDate: effort.endDate };
          })
          .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
        for (let i = 0; i < timeLine.length; i++) {
          if (new Date(timeLine[i].endDate) > new Date(timeLine[i + 1]?.startDate)) {
            setEffortsError(true);
            setOverlapError(true);
            setIsValid(false);
            break;
          } else {
            if (i === timeLine.length - 1) {
              setEffortsError(false);
              setOverlapError(false);
              setIsValid(true);
            }
          }
        }
      }
    }
  };
  const handleCheckBox = event => {
    const updatedAllocation = { ...allocation, bench: event.target.checked };
    setAllocation(updatedAllocation);
  };
  const newPeriod = () => {
    setAllocation({
      ...allocation,
      efforts: [...allocation.efforts, { startDate: null, endDate: null, effort: 0 }]
    });
    setIsValid(false);
    setEffortsError(true);
    setOverlapError(false);
  };
  const removePeriod = index => {
    const efforts = [...allocation.efforts];
    efforts.splice(index, 1);
    validation({ ...allocation, efforts }, ['effort']);
    setAllocation({ ...allocation, efforts });
  };

  const saveAllocation = async () => {
    const { employee, id, ...rest } = { ...allocation };
    const efforts = rest.efforts.map(effort => {
      return {
        ...(effort.id && { id: effort.id }),
        effort: effort.effort,
        startDate: effort.startDate,
        endDate: effort.endDate
      };
    });
    const data = {
      ...(rest.title && employee.length === 1 && { title: rest.title }),
      bench: rest.bench,
      efforts
    };
    const clientId = +props.match.params.id;
    const projectId = +props.match.params.pid;
    const promises = [];
    if (id) {
      employee.forEach(emp => {
        promises.push(
          allocationService.patch(clientId, projectId, id, { ...data, employeeId: +emp })
        );
      });
    } else {
      employee.forEach(emp =>
        promises.push(allocationService.add(clientId, projectId, { ...data, employeeId: +emp }))
      );
    }
    try {
      await Promise.all(promises);
      reset();
      props.onAllocationCreate('success');
    } catch {
      props.onAllocationCreate('fail');
    }
  };
  useEffect(() => {
    let empIds = [];
    if (props.data) {
      if (props.data instanceof Array) {
        empIds = [...props.data];

        setAllocation({
          id: undefined,
          employee: [],
          bench: false,
          title: null,
          efforts: [{ startDate: null, endDate: null, effort: 0 }],
          empIds: empIds
        });
        setIsCreating(false);
        setIsValid(false);
        setEmployeeError(true);
        setEffortsError(true);
        setOverlapError(false);
      } else {
        const { id, bench, employeeId, title, efforts, empDetails, lead, project } = props.data;

        setAllocation({
          id,
          bench: bench !== undefined ? bench : false,
          employee: employeeId ? [employeeId] : [],
          title: title || null,
          efforts: efforts || [],
          empDetails: empDetails ? empDetails : [],
          lead,
          project,
          empIds: empIds
        });
        setIsCreating(false);
        setIsValid(true);
        setEmployeeError(false);
        setEffortsError(false);
        setOverlapError(false);
      }
    } else {
      setAllocation({
        id: undefined,
        employee: [],
        bench: false,
        title: null,
        efforts: [{ startDate: null, endDate: null, effort: 0 }],
        empIds: []
      });
      setIsCreating(false);
      setIsValid(false);
      setEmployeeError(true);
      setEffortsError(true);
      setOverlapError(false);
    }
  }, []);

  return (
    <AllocationDialog
      isCreating={isCreating}
      allocation={allocation}
      isValid={isValid}
      effortsError={effortsError}
      cancel={cancel}
      saveAllocation={saveAllocation}
      handleChange={handleChange}
      handleEmployeesChange={handleEmployeesChange}
      handleCheckBox={handleCheckBox}
      handleEffortChange={handleEffortChange}
      newPeriod={newPeriod}
      removePeriod={removePeriod}
      overLapError={overlapError}
    />
  );
};

export default withRouter(AllocationDialogContainer);
