import React from 'react';
import BenefitsContainer from './Benefits/BenefitsContainer';
const Home = () => {
  return (
    <div
      style={{ textAlign: 'center', fontSize: '16px', lineHeight: '28px', letterSpacing: 'normal' }}
    >
      <BenefitsContainer />
    </div>
  );
};
export default Home;
