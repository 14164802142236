import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Main from './Main';
import './App.scss';
import './styles/normalize.scss';
import './styles/globals.scss';
import { checkAuthRequest } from 'store/actions';

const App = props => {
  useEffect(() => {
    props.checkAuth();
    localStorage.setItem('view', 'list');
  }, [props.checkAuth]);

  return (
    <div className="App">
      <>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </>
    </div>
  );
};

const mapStatetoProps = store => ({
  currentUser: store.auth.currentUser
});
const mapDispatchToProps = {
  checkAuth: checkAuthRequest
};
export default connect(mapStatetoProps, mapDispatchToProps)(App);
