import { axiosInstances } from '../../../../utilities/axios/Instances/axios.instances';
const audit = id => {
  return axiosInstances.withAuthToken().get(`/tags/${id}/audit`);
};
const update = (data, id) => {
  return axiosInstances.withAuthToken().patch(`/tags/${id}`, data);
};
const add = data => {
  return axiosInstances.withAuthToken().post(`/tags/`, data);
};
const deleteTag = id => {
  return axiosInstances.withAuthToken().delete(`/tags/${id}`);
};
export { add, deleteTag, update, audit };
