import React from 'react';
import EmployeeSkillCard from './EmployeeSkillCard';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EmptySkillsImg from 'assets/img/app_states/empty/Skills.png';

const EmployeeSkills = ({ skillsGroups, getSkillLevel, navigate, employee }) => {
  return (
    <div className="cardBody" index={1}>
      {skillsGroups.length > 0 ? (
        <div className="skillCardsWrapper">
          {skillsGroups.map((skillGroup, idx) => (
            <EmployeeSkillCard getSkillLevel={getSkillLevel} skillGroup={skillGroup} key={idx} />
          ))}
        </div>
      ) : (
        <EmptyState
          stateImage={
            <div>
              <img src={EmptySkillsImg} alt="" />
            </div>
          }
          stateText={
            <div className="empty-text-section">
              <div
                style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                className="empty-text-message"
              >
                You haven't added any skills yet.
              </div>
              <div className="empty-text-action" style={{ textAlign: 'center' }}>
                <Button
                  style={{
                    position: 'static',
                    textTransform: 'unset',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px'
                  }}
                  onClick={() => navigate(`/employees/${employee.id}/skills`)}
                  variant="contained"
                  color="primary"
                >
                  <AddIcon /> Add skill
                </Button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default EmployeeSkills;
