import React from 'react';
import './Footer.scss';
const Footer = () => {
  return (
    <footer className="footer" style={{ width: '100%' }}>
      <span className="rights">
        Copyright &copy; {new Date().getFullYear()} Aspire Jo, All rights reserved
      </span>
      <span className="version">Version 0.0.1</span>
      <a className="Aspirewebsite" href="http://www.aspire.jo/" rel="noreferrer" target="_blank">
        aspire.jo
      </a>
    </footer>
  );
};
export default Footer;
