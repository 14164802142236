import { axiosInstances } from '../../utilities/axios/Instances/axios.instances';
const authKey = 'authToken';
const isLoggedIn = () => {
  return sessionStorage.getItem(authKey) !== undefined && sessionStorage.getItem(authKey) !== null;
};
const getToken = () => {
  return sessionStorage.getItem(authKey);
};
const getOfficeAuthUrl = () => {
  return axiosInstances.withBaseUrl().get('/auth/login');
};
const login = (username, password) => {
  //call within try catch block
  return processLogin({ username, password });
};
const loginViaCode = code => {
  //call within try catch block
  return processLogin({ code });
};
const processLogin = async reqBody => {
  const { data: res } = await axiosInstances.withBaseUrl().post('/auth/login', reqBody);
  if (res && res.auth_token) {
    sessionStorage.setItem(authKey, res.auth_token);
  } else {
    console.log('res error', res);
    throw new Error('unknown_error');
  }
};
const logout = () => {
  sessionStorage.removeItem(authKey);
};
export const authenticationService = {
  isLoggedIn,
  getToken,
  getOfficeAuthUrl,
  login,
  loginViaCode,
  logout
};
