/* eslint-disable no-loop-func */
import { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { EmployeesService } from 'modules/shared/services/employees';
import { AllocationReportService } from '../../services/allocation-report';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import TitleBarContainer from 'modules/shared/components/top-bars/title-bar/TitleBarContainer';
import './OverallAllocation.scss';
import OverallAllocation from './OverallAllocation';
const OverallAllocationContainer = props => {
  const [filterCriteria, setFilterCriteria] = useState({
    startDate: moment().add(-1, 'months'),
    endDate: moment().add(2, 'months')
  });
  const [dialogData, setDialogData] = useState({
    startDate: moment().add(-1, 'months'),
    endDate: moment().add(2, 'months')
  });
  const [empsList, setEmpsList] = useState([]);
  const [data, setData] = useState([]);
  const [errorLoading, setErrorLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [maxscale, setMaxscale] = useState(100);
  const [maxscaleLineChart, setMaxscaleLineChart] = useState(100);
  const [viewMode, setViewMode] = useState('compined-chart');
  const [generateChartData, setGenerateChartData] = useState([]);
  const [groupedChartData, setGroupedChartData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [colorScheme, setColorScheme] = useState([
    '#5AA454',
    '#A10A28',
    '#C7B42C',
    '#AAAAAA',
    '#9c27b0',
    '#3f51b5',
    '#009688',
    '#673ab7',
    '#03a9f4',
    '#4caf50',
    '#ff9800',
    '#607d8b'
  ]);
  const [overallRefLines, setOverallRefLines] = useState([
    { value: 90, name: 'Maximum' },
    { value: 75, name: 'Minimum' }
  ]);

  useEffect(() => {
    filterOverallAllocation();
  }, []);

  const filterOverallAllocation = async () => {
    const empsList = await EmployeesService.getEmployees();
    const { data, generateChartData, groupedChartData, maxscale, maxscaleLineChart } =
      await applyFilter();
    setEmpsList(empsList);
    setData(data);
    setGenerateChartData(generateChartData);
    setGroupedChartData(groupedChartData);
    setMaxscale(maxscale);
    setMaxscaleLineChart(maxscaleLineChart);
    setIsLoading(false);
  };
  const applyFilter = async filter => {
    setFilterCriteria(filter ? filter : filterCriteria);
    setIsLoading(true);
    const { data: skilledAllocatedEmployees } = await AllocationReportService.findByTechnology(
      filterCriteria
    );
    const filterStart = filterCriteria.startDate,
      filterEnd = filterCriteria.endDate;
    const extendedData = skilledAllocatedEmployees.map(item => {
      const flatEfforts = _.flatMap(item.allocations, allocation => _.flatten(allocation.efforts));
      const totalDays = filterEnd.diff(filterStart, 'days');
      const freeDays =
        totalDays -
        flatEfforts
          .map(effort =>
            moment
              .min([moment(effort.endDate), filterEnd])
              .diff(moment.max([moment(effort.startDate), filterStart]), 'days')
          )
          .reduce((sum, val) => (sum += val));
      const avgUtilization =
        flatEfforts
          .map(
            effort =>
              effort.effort *
              moment
                .min([moment(effort.endDate), filterEnd])
                .diff(moment.max([moment(effort.startDate), filterStart]), 'days')
          )
          .reduce((sum, val) => (sum += val)) / totalDays;
      const totalProjects = _.uniqBy(item.allocations, p => p.projectId).length;
      const benchedProjects = _.uniqBy(
        item.allocations.filter(item => item.bench === true),
        p => p.project
      ).length;
      return { ...item, freeDays, avgUtilization, totalProjects, benchedProjects };
    });
    const filteredData = extendedData.filter(a => {
      if (filterCriteria.minUtil && filterCriteria.maxUtil) {
        return (
          a.avgUtilization >= filterCriteria.minUtil && a.avgUtilization <= filterCriteria.maxUtil
        );
      } else if (filterCriteria.minUtil) {
        return a.avgUtilization >= filterCriteria.minUtil;
      } else if (filterCriteria.maxUtil) {
        return a.avgUtilization <= filterCriteria.maxUtil;
      } else return true;
    });
    const sortedData = _.sortBy(filteredData, a => a.avgUtilization);
    const { perEmp, grouped, maxscale, maxscaleLineChart } = generateChart(
      sortedData,
      filterCriteria
    );
    return {
      data: sortedData,
      generateChartData: perEmp,
      groupedChartData: grouped,
      maxscale,
      maxscaleLineChart
    };
  };
  const resetFilter = async () => {
    const filterCriteria = {
      startDate: moment().add(-1, 'months'),
      endDate: moment().add(2, 'months')
    };
    const { data, generateChartData, groupedChartData, maxscale, maxscaleLineChart } =
      await applyFilter(filterCriteria);
    setFilterCriteria(filterCriteria);
    setData(data);
    setGenerateChartData(generateChartData);
    setGroupedChartData(groupedChartData);
    setMaxscale(maxscale);
    setMaxscaleLineChart(maxscaleLineChart);
    setIsLoading(false);
    setIsDialogOpen(false);
  };
  const generateChart = (raw, filterCriteria) => {
    const minDate = filterCriteria ? filterCriteria.startDate : filterCriteria.startDate;
    const maxDate = filterCriteria ? filterCriteria.endDate : filterCriteria.endDate;
    const legends = [];
    let empsCount = 0;
    const groups = { name: 'Overall', series: [] };
    //let maxscale = maxscale;
    // let maxscaleLineChart = maxscaleLineChart;
    raw.forEach(emp => {
      empsCount++;
      const series = [];
      let currentMonth = moment(minDate).startOf('month');
      while (currentMonth.isBefore(maxDate)) {
        const name = `${moment(currentMonth).format('MM/YY')}`,
          sum = _.flatMap(emp.allocations, al => _.flatten(al.efforts))
            .filter(c =>
              currentMonth.isBetween(
                moment(c.startDate).startOf('month'),
                moment(c.endDate).startOf('month'),
                null,
                '[]'
              )
            )
            .map(x => x.effort)
            .reduce((ac, val) => (ac += Math.max(0, val)), 0),
          value = sum < 0 ? 0 : sum;
        series.push({ name, value });
        if (value > maxscale) setMaxscale(value);
        const groupedSeries = groups.series.find(a => a.name === name);
        if (!groupedSeries) {
          groups.series.push({ name, value });
        } else {
          groupedSeries.value = groupedSeries.value + value;
        }
        currentMonth = currentMonth.add(1, 'M');
      }
      const empDetails = empsList.find(a => a.id === +emp.employee);
      if (empDetails) {
        legends.push({ name: `${empDetails.firstName} ${empDetails.lastName}`, series });
      }
    });
    groups.series = groups.series.map(a => {
      if (empsCount !== 0) {
        if (a.value / empsCount > maxscale) setMaxscale(a.value / empsCount);
        if (a.value / empsCount > maxscaleLineChart) setMaxscaleLineChart(a.value / empsCount);
        return { name: a.name, value: a.value / empsCount };
      }
      if (a.value > maxscale) setMaxscale(a.value);
      if (a.value > maxscaleLineChart) setMaxscaleLineChart(a.value);
      return { name: a.name, value: 0 };
    });
    return { perEmp: legends, grouped: [groups], maxscale, maxscaleLineChart };
  };
  const openDialog = () => {
    setIsDialogOpen(true);
    setDialogData({ ...filterCriteria });
  };
  const closeDialog = async newFilter => {
    if (!newFilter || JSON.stringify(filterCriteria) === JSON.stringify(newFilter)) {
      setIsDialogOpen(false);
    } else {
      const { data, generateChartData, groupedChartData, maxscale, maxscaleLineChart } =
        await applyFilter(newFilter);
      setFilterCriteria(newFilter);
      setData(data);
      setGenerateChartData(generateChartData);
      setGroupedChartData(groupedChartData);
      setMaxscale(maxscale);
      setMaxscaleLineChart(maxscaleLineChart);
      setIsLoading(false);
      setIsDialogOpen(false);
    }
  };
  const changeView = view => {
    setViewMode(view);
  };
  const computeyScaleMax = () => {
    return Math.ceil(maxscale / 10) * 10;
  };
  const computeyScaleMaxForLineCart = () => {
    return Math.ceil(maxscaleLineChart / 10) * 10;
  };
  const titleBar = (
    <IconButton onClick={openDialog} style={{ color: 'inherit' }}>
      <FilterListIcon />
    </IconButton>
  );

  return (
    <LayoutContainer
      titleBar={
        <TitleBarContainer title="Allocations report" backRoute="/" titleBarPrimary={titleBar} />
      }
    >
      <OverallAllocation
        viewMode={viewMode}
        changeView={changeView}
        errorLoading={errorLoading}
        isLoading={isLoading}
        isDialogOpen={isDialogOpen}
        dialogData={dialogData}
        closeDialog={closeDialog}
        computeyScaleMaxForLineCart={computeyScaleMaxForLineCart}
        groupedChartData={groupedChartData[0]?.series || []}
        data={data}
        resetFilter={resetFilter}
      />
    </LayoutContainer>
  );
};
export default OverallAllocationContainer;
