import React, { useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import defaultSkillImg from '../../../../assets/img/app_states/empty/SkillsIcon.png';

const SkillChipList = props => {
  const [values, setValues] = React.useState([]);
  useEffect(() => setValues([...props.skills]), [props.skills]);
  const handleChange = value => {
    setValues(value);
    props.handleSkillsChange?.(value);
    //check if reached max number of employees before adding a new one
  };
  return (
    <div className="SkillChipList">
      {props.editable ? (
        <div className="form-group">
          <Autocomplete
            loading={props.isLoading}
            value={values}
            multiple
            id="tags-standard"
            options={props.allSkills}
            onChange={(e, newValue) => {
              handleChange(newValue);
            }}
            filterSelectedOptions
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.name}
                  avatar={<Avatar alt={option.name} src={option.imgUrl || defaultSkillImg} />}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={option => (
              <>
                <Avatar alt={option.name} src={option.imgUrl || defaultSkillImg} />
                {/* <div style={{ width: "20px", height: "20px", borderRadius: "50%", marginRight: "0.75em", backgroundColor: option.color }} /> */}
                <span style={{ marginLeft: '0.5em' }}>{option.name}</span>
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Skills"
                placeholder={props.placeholder || 'add'}
              />
            )}
          />
        </div>
      ) : (
        <>
          {!props.dense ? (
            <div className="SkillsList">
              {props.skills.map((skill, idx) => (
                <Chip
                  style={{ margin: '4px', fontWeight: 500, fontSize: '14px' }}
                  label={skill.name}
                  key={idx}
                />
              ))}
            </div>
          ) : (
            <div className="SkillsList-dense">
              {props.skills.map((skill, idx) => (
                <Tooltip title={skill.name} key={idx}>
                  <div className="dense">
                    <Avatar src={skill.imgUrl} alt={skill.name} />
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default SkillChipList;
