import React from 'react';
import TopNav from './TopNav';
import './TopNav.scss';
const TopNavContainer = props => {
  return (
    <>
      <TopNav menuButtonClicked={props.menuButtonClicked} />
    </>
  );
};

export default TopNavContainer;
