import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { SkillsService } from '../../../shared/services/skills-service';
import EmployeeProfile from './EmployeeProfile';
import './EmployeeProfile.scss';
import { EmployeesService } from '../../../shared/services/employees';
import LayoutContainer from '../../../shared/components/_layout/layout-container/LayoutContainer';
import TitleBarContainer from '../../../shared/components/top-bars/title-bar/TitleBarContainer';

const EmployeeProfileContainer = props => {
  const [employee, setEmployee] = useState({});
  const [supervisorData, setSupervisorData] = useState({});
  const [assignedProjects, setAssignedProjects] = useState(undefined);
  const [skills, setSkills] = useState([]);
  const [canEditSkills, setCanEditSkills] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const savedPathname = localStorage.getItem('pathname');
  const [canAccessIds, setCanAccessIds] = useState(
    JSON.parse(sessionStorage.getItem('canAccessIds')) || []
  );
  const isAdmin = () => {
    const { userPermissions: permissions } = props;
    return permissions && Array.isArray(permissions) && permissions.includes('admin');
  };
  const hasEmployeePermission = () => {
    const { userPermissions: permissions } = props;
    return permissions && Array.isArray(permissions) && permissions.includes('employees');
  };
  const canAccess = employee => {
    return (
      isAdmin() ||
      (employee.id ? +props.currentUser.id === +employee.id : false) ||
      (employee.reportingTo.id ? +props.currentUser.id === +employee.reportingTo.id : false) ||
      canAccessIds.includes(+employee.reportingTo.id) ||
      hasEmployeePermission()
    );
  };
  const navigate = route => {
    props.history.push(route);
  };
  useEffect(() => {
    const empId = +props.match.params.id;
    loadData(empId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);
  const loadSupervisorData = async data => {
    const { data: employee } = await EmployeesService.getEmpByID(data?.id);
    setSupervisorData(employee);
  };
  const getSkillLevel = s => {
    const a = [];
    for (let i = 0; i < s; i++) a.push(1);
    return a;
  };

  const loadData = async empId => {
    try {
      setIsLoading(true);
      let { data: employee } = await EmployeesService.getEmpByID(empId);
      if (!employee || employee === null) {
        employee = {};
      }
      if (canAccess(employee)) {
        if (!canAccessIds.includes(empId)) {
          setCanAccessIds([...canAccessIds, empId]);
          sessionStorage.setItem('canAccessIds', JSON.stringify(canAccessIds));
        }
        const { data: lead } = await EmployeesService.getProjectsAsLead(empId);
        const { data: member } = await EmployeesService.getProjectsAsMember(empId);
        const assignedProjects = { lead, member };
        const empSkills = await EmployeesService.getSkills(empId);
        const skills = await SkillsService.filter();
        let leveledSkills = skills
          .map(skill => ({
            ...skill,
            level: (empSkills.find(empSkill => empSkill.id === skill.id) || { level: 0 }).level,
            usedInAspire: empSkills.find(empSkill => empSkill.id === skill.id)?.usedInAspire,
            experienceId: empSkills.find(empSkill => empSkill.id === skill.id)?.experienceId,
            lastAssessment: empSkills.find(empSkill => empSkill.id === skill.id)?.lastAssessment
          }))
          .filter(skill => skill.level && skill.level > 0);
        const groupedSkillsObject = _.groupBy(leveledSkills, skill => {
          return skill.group;
        });
        const groupedSkillsArray = [];
        for (const [key, value] of Object.entries(groupedSkillsObject)) {
          groupedSkillsArray.push({ groupName: key, skills: value });
        }
        const sortedGroupedSkills = groupedSkillsArray?.sort(
          (a, b) => b?.skills?.length - a?.skills?.length
        );

        let canEditSkills = false;
        if (+props.currentUser.id === +empId || isAdmin()) {
          canEditSkills = true;
        }
        setEmployee(employee);
        employee?.reportingTo?.id && loadSupervisorData(employee?.reportingTo);
        setAssignedProjects(assignedProjects);
        setSkills(sortedGroupedSkills);
        setCanEditSkills(canEditSkills);
        setIsLoading(false);
      } else {
        props.history.push('/accessdenied');
      }
    } catch (error) {
      if (error.response.status) {
        props.history.push('/accessdenied');
      }
    }
  };
  const titleBar = (
    <TitleBarContainer
      title={employee?.displayName || 'Employee Profile'}
      backRoute={isAdmin() ? savedPathname : '/'}
    />
  );
  return (
    <LayoutContainer titleBar={titleBar}>
      <EmployeeProfile
        employee={employee}
        supervisorData={supervisorData}
        canEditSkills={canEditSkills}
        skillsGroups={skills}
        getSkillLevel={getSkillLevel}
        assignedProjects={assignedProjects}
        navigate={navigate}
        isLoading={isLoading}
        isAdmin={isAdmin}
      />
    </LayoutContainer>
  );
};

const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  userPermissions: store.auth.userPermissions
});

export default connect(mapStateToProps, null)(EmployeeProfileContainer);
