import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PermissionsTree from '../permissions-tree/PermissionsTree';
import WarningAmberIcon from '@material-ui/icons/WarningTwoTone';
import EmployeeChipListContainer from '../../../../shared/components/_employee-views/EmployeeChipList/EmployeeChipListContainer';
const RoleDialog = props => {
  const havePermissions = props?.role?.permissions?.length !== 0;
  return (
    <div id="RoleDialog">
      <div className="RoleDialogTitle">{props.role.id ? props.role.name : 'New Role'}</div>

      <div className="RoleDialogContent">
        <TextField
          onChange={e => {
            props.handleNameChange(e);
          }}
          defaultValue={props.role.name}
          name="name"
          required
          autoFocus
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          fullWidth
        />
        {!havePermissions && (
          <div className="warning">
            <WarningAmberIcon />
            You need to select the permissions that are granted to this role!
          </div>
        )}
        <PermissionsTree
          allPermissions={props.allPermissions}
          handleChange={props.handlePermissionsChange}
          role={props.role}
        />
        <EmployeeChipListContainer
          handleEmployeesChange={props.handleEmployeesChange}
          employees={props.role.employees}
        />
      </div>

      <div className="RoleDialogActions">
        <Button onClick={props.cancel}>Cancel</Button>
        <Button onClick={props.saveRole} disabled={!props.isValid} color="primary">
          {props.role.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
};
export default RoleDialog;
