export const getInitials = name => {
  const words = name.split(' ');
  const initials = words.map(word => word.charAt(0).toUpperCase());
  return initials.join('');
};
export const getRandomColor = () => {
  const red = Math.floor(Math.random() * 80);
  const green = Math.floor(Math.random() * 155);
  const blue = Math.floor(Math.random() * 128) + 128;
  return `rgb(${red}, ${green}, ${blue})`;
};
