import React, { useState } from 'react';
import './OverallAllocationFilter.scss';
import OverallAllocationFilter from './OverallAllocationFilter';
const OverallAllocationFilterContainer = props => {
  const [filter, setFilter] = useState(props.data);
  const [isValid, setIsValid] = useState(true);
  const [dateRangeError, setDateRangeError] = useState(false);
  const [skillLevelError, setSkillLevelError] = useState(false);
  const [allocationRangeError, setAllocationRangeError] = useState(false);
  const handleChange = (name, value) => {
    if (name === 'skills') {
      value = value.map(skill => skill.id);
    }
    const updatedFilter = { ...filter, [name]: value };
    validation(updatedFilter, name);
    setFilter(updatedFilter);
    // const { isValid, dateRangeError, allocationRangeError, skillLevelError } = this.validation(updatedFilter, name);
    // this.setState({ filter: updatedFilter, isValid, dateRangeError, allocationRangeError, skillLevelError });
  };
  const validation = (filter, name) => {
    switch (name) {
      case 'startDate':
      case 'endDate':
        if (filter.endDate && filter.startDate && filter.startDate.isAfter(filter.endDate)) {
          setDateRangeError(true);
        } else {
          setDateRangeError(false);
        }
        break;
      case 'minUtil':
      case 'maxUtil':
        if (filter.minUtil && filter.maxUtil) {
          if (+filter.minUtil >= +filter.maxUtil || +filter.minUtil < 0 || +filter.maxUtil < 0) {
            setAllocationRangeError(true);
          } else {
            setAllocationRangeError(false);
          }
        } else {
          setAllocationRangeError(false);
        }
        break;
      case 'minLevel':
      case 'maxLevel':
        if (filter.minLevel && filter.maxLevel && +filter.minLevel >= +filter.maxLevel) {
          setSkillLevelError(true);
        } else {
          setSkillLevelError(false);
        }
        break;
    }
    if (dateRangeError || allocationRangeError || skillLevelError) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    return { isValid, dateRangeError, allocationRangeError, skillLevelError };
  };
  const applyFilter = () => {
    props.closeDialog(filter);
  };
  const cancel = () => {
    props.closeDialog();
  };

  return (
    <OverallAllocationFilter
      filter={filter}
      isValid={isValid}
      handleChange={handleChange}
      applyFilter={applyFilter}
      cancel={cancel}
      resetFilter={props.resetFilter}
    />
  );
};

export default OverallAllocationFilterContainer;
