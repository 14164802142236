const initialState = {
  isLoggedIn: false,
  isAuthChecked: false,
  currentUser: null,
  userPermissions: [],
  isLoading: false,
  redirect: false,
  unauthorized: false,
  invalidUser: false,
  unknownError: false
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'LOGIN_START': {
      return {
        ...state,
        isLoading: true,
        unauthorized: false,
        invalidUser: false,
        unknownError: false
      };
    }
    case 'LOGIN_SUCCESS': {
      return {
        ...state,
        isLoggedIn: true,
        isAuthChecked: true,
        currentUser: payload.currentUser,
        userPermissions: payload.userPermissions,
        isLoading: false
      };
    }
    case 'AUTH_ERROR': {
      switch (payload) {
        case 'unauthorized': {
          return {
            ...state,
            unauthorized: true,
            isLoading: false
          };
        }
        case 'invalid_user': {
          return {
            ...state,
            invalidUser: true,
            isLoading: false
          };
        }
        // case 'unknown_error':{
        //     return {
        //         ...state, unknownError:true
        //     };
        // }
        default: {
          return {
            ...state,
            unknownError: true,
            isLoading: false
          };
        }
      }
    }
    case 'CHECK_Auth_FAIL': {
      return {
        ...state,
        isAuthChecked: true
      };
    }
    case 'SIGNOUT_SUCCESS': {
      return {
        ...state,
        isLoggedIn: false,
        currentUser: null,
        isLoading: false
      };
    }
    case 'DISABLE_REDIRECT': {
      return {
        ...state,
        redirect: false
      };
    }
    default: {
      return state;
    }
  }
}
