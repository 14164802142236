import React, { useEffect, useState } from 'react';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import TitleBarContainer from 'modules/shared/components/top-bars/title-bar/TitleBarContainer';
import './SkillsTrends.scss';
import { SkillsInventoryService } from '../../services/skills-inventory';
import { EmployeesService } from 'modules/shared/services/employees';
import SkillsTrends from './SkillsTrends';

const SkillsTrendsContainer = () => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    filterData();
  }, []);

  const filterData = async () => {
    const data = await loadData();
    if (data) {
      setData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setError(true);
    }
  };

  const loadData = async () => {
    try {
      const allEmployees = await EmployeesService.getEmployees();
      const employeesCount = allEmployees.length;

      const { data: skillList } = await SkillsInventoryService.inventory(
        4, // minLevel
        5, // maxLevel
        1, // software engineering dept
        'general' // general group
      );
      const multiLineData = skillList.map(skill =>
        SkillsInventoryService.multiLineChartSkillMapper(skill, employeesCount)
      );
      return multiLineData;
    } catch {
      return undefined;
    }
  };
  const resultFound = () => {
    return data && data.length > 0
      ? data.some(
          a =>
            a.availability > 0 ||
            a.indiaAvailability > 0 ||
            a.jordanAvailability > 0 ||
            a.marketDemand > 0
        )
      : false;
  };

  return (
    <LayoutContainer titleBar={<TitleBarContainer title="Skills trends" backRoute="/" />}>
      <SkillsTrends data={data} error={error} isLoading={isLoading} resultFound={resultFound} />
    </LayoutContainer>
  );
};
export default SkillsTrendsContainer;
