import { useState, useEffect } from 'react';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import TitleBarContainer from 'modules/shared/components/top-bars/title-bar/TitleBarContainer';
import { EmployeesService } from 'modules/shared/services/employees';
import { EmployeesSkillsService } from '../../services/employees-skills';
import './EmployeesWithMissingSkills.scss';
import EmployeesWithMissingSkills from './EmployeesWithMissingSkills';
import IconButton from '@material-ui/core/IconButton';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
const EmplyeesWithMissingSkillsContainer = props => {
  const [employeesList, setEmployeesList] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    filterEmployeeMissingSkills();
    localStorage.setItem('pathname', props?.location?.pathname);
  }, []);

  const navigate = route => {
    props.history.push(route, { prevPathname: props?.location?.pathname });
  };

  const filterEmployeeMissingSkills = async (name = '') => {
    try {
      const { data: empsWithSkillsIds } = await EmployeesSkillsService.findBySkills();
      const allEmployees = await EmployeesService.getEmployees();
      const employeesList = allEmployees
        .reduce((acc, curr) => {
          if (!empsWithSkillsIds.some(({ employeeId }) => employeeId === curr?.id)) {
            acc.push(curr);
          }
          return acc;
        }, [])
        .sort((a, b) =>
          `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
        );
      if (name === '') setEmployeesList(employeesList);
      else {
        const filteredEmployees = employeesList.filter(
          employee =>
            `${employee.firstName} ${employee.lastName}`.toLowerCase().indexOf(name.toLowerCase()) >
            -1
        );
        setEmployeesList(filteredEmployees);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setError(true);
    }
  };
  const handleCloseSecondaryBar = () => {
    filterEmployeeMissingSkills('');
  };
  const searchTitleBar = (
    <TextField
      onChange={e => filterEmployeeMissingSkills(e?.target?.value)}
      placeholder={'Search Employee'}
      autoFocus
      margin="dense"
      fullWidth
    />
  );
  const titleBarPrimary = toggleMode => (
    <IconButton
      style={{ color: 'inherit' }}
      onClick={() => {
        toggleMode(true);
      }}
    >
      <SearchIcon />
    </IconButton>
  );

  return (
    <LayoutContainer
      titleBar={
        <>
          <TitleBarContainer
            title="Employees with missing skills"
            backRoute="/"
            titleBarSecondary={searchTitleBar}
            titleBarPrimary={titleBarPrimary}
            onClose={handleCloseSecondaryBar}
          />
        </>
      }
    >
      <EmployeesWithMissingSkills
        employeesList={employeesList}
        isLoading={isLoading}
        error={error}
        navigate={navigate}
      />
    </LayoutContainer>
  );
};
export default EmplyeesWithMissingSkillsContainer;
