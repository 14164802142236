import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
const CustomMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const MenuItems = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      child = React.cloneElement(child, {
        style: {
          display: 'flex',
          minWidth: '76px',
          width: '100%',
          maxWidth: '244px',
          padding: '6px 16px'
        }
      });
    }
    return (
      <MenuItem
        style={{ color: props.color ? props.color : 'rgba(0,0,0,.54)', padding: '0px' }}
        onClick={handleClose}
      >
        {child}
      </MenuItem>
    );
  });
  return (
    <div>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: '8px' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {MenuItems}
      </Menu>
    </div>
  );
};
export default CustomMenu;
