import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './EmployeeSkills.scss';
import Typography from '@material-ui/core/Typography';
import TitleBarContainer from '../../../shared/components/top-bars/title-bar/TitleBarContainer';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import TabbedSideContainer from '../../../shared/components/_layout/tabbed-side-container/TabbedSideContainer';
import EmployeeSkillsRatingHelp from '../empoyee-skills-rating-help/EmployeeSkillsRatingHelp';
import EmployeeSkills from './EmployeeSkills';
import { SkillsService } from '../../../shared/services/skills-service';
import _ from 'lodash';
import { EmployeesService } from '../../../shared/services/employees';
import LayoutContainer from '../../../shared/components/_layout/layout-container/LayoutContainer';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';

const EmployeesSkillsContainer = props => {
  const [data, setData] = useState([]);
  const [experienceRanges, setExperienceRanges] = useState([]);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = props.match.params;
  const isMobileView = window.innerWidth <= 620;
  const loadData = async empId => {
    if (canAccess(empId)) {
      setIsLoading(true);
      const { data: expRanges } = await EmployeesService.getExpRanges();
      setExperienceRanges(expRanges);
      const empSkills = await EmployeesService.getSkills(empId);
      const skills = await SkillsService.filter();
      let leveledSkills = skills.map(skill => ({
        ...skill,
        level: (empSkills.find(empSkill => empSkill.id === skill.id) || { level: 0 }).level,
        usedInAspire: (
          empSkills.find(empSkill => empSkill.id === skill.id) || { usedInAspire: false }
        ).usedInAspire,
        experienceId: (
          empSkills.find(empSkill => empSkill.id === skill.id) || { experienceId: null }
        ).experienceId
      }));
      const groupedSkillsObject = _.groupBy(leveledSkills, skill => {
        return skill.group;
      });
      const groupedSkillsArray = [];
      for (const [key, value] of Object.entries(groupedSkillsObject)) {
        groupedSkillsArray.push({ groupName: key, skills: value });
      }
      setData(groupedSkillsArray);
      setIsLoading(false);
    } else {
      props.history.push('/accessdenied');
    }
  };
  const isAdmin = () => {
    const { userPermissions: permissions } = props;
    return permissions && Array.isArray(permissions) && permissions.includes('admin');
  };
  const canAccess = empId => {
    return isAdmin() || +empId === +props.currentUser.id;
  };
  const handleRatingChange = (groupName, skillName, rating) => {
    const oldGroupDataIndex = data.findIndex(group => group.groupName === groupName);
    const oldGroupData = data[oldGroupDataIndex];
    const oldSkillDataIndex = oldGroupData?.skills.findIndex(skill => skill.name === skillName);
    const oldRaiting = oldGroupData?.skills[oldSkillDataIndex].level;
    if (oldRaiting === rating) {
      rating = -1;
    }
    const newSkillData = { ...oldGroupData?.skills[oldSkillDataIndex], level: rating };
    const skillsArrSlice1 = oldGroupData?.skills.slice(0, oldSkillDataIndex);
    const skillsArrSlice2 = oldGroupData?.skills.slice(oldSkillDataIndex + 1);
    const updatedGroup = {
      ...oldGroupData,
      skills: [...skillsArrSlice1, newSkillData, ...skillsArrSlice2]
    };
    const newGroupsArraySlice1 = data.slice(0, oldGroupDataIndex);
    const newGroupsArraySlice2 = data.slice(oldGroupDataIndex + 1);
    const updatedData = [...newGroupsArraySlice1, updatedGroup, ...newGroupsArraySlice2];
    setData(updatedData);
    //sol2: deep copy the state using a library then modify freely
    //sol3: try to have another field in the state that tracks the form changes
  };
  const handleCheckBox = (groupName, skillName, checked, expId) => {
    const oldGroupDataIndex = data.findIndex(group => group.groupName === groupName);
    const oldGroupData = data[oldGroupDataIndex];
    const oldSkillDataIndex = oldGroupData?.skills.findIndex(skill => skill.name === skillName);
    const newSkillData = {
      ...oldGroupData?.skills[oldSkillDataIndex],
      usedInAspire: checked,
      experienceId: expId
    };
    const skillsArrSlice1 = oldGroupData?.skills.slice(0, oldSkillDataIndex);
    const skillsArrSlice2 = oldGroupData?.skills.slice(oldSkillDataIndex + 1);
    const updatedGroup = {
      ...oldGroupData,
      skills: [...skillsArrSlice1, newSkillData, ...skillsArrSlice2]
    };
    const newGroupsArraySlice1 = data.slice(0, oldGroupDataIndex);
    const newGroupsArraySlice2 = data.slice(oldGroupDataIndex + 1);
    const updatedData = [...newGroupsArraySlice1, updatedGroup, ...newGroupsArraySlice2];
    setData(updatedData);
  };

  const saveData = async () => {
    const dataToPost = [];
    data.forEach(skillGroup => {
      skillGroup.skills.forEach(skill => {
        if (skill.level !== 0) {
          if (skill.level === -1) skill.level = 0;
          dataToPost.push({
            id: skill.id,
            level: +skill.level,
            usedInAspire: skill.usedInAspire,
            experienceId: skill.experienceId
          });
        }
      });
    });
    try {
      await EmployeesService.updateSkills(+props.match.params.id, dataToPost);
      setShowSuccessToast(true);
      setTimeout(() => {
        props.history.push(`/employees/${props.match.params.id}/profile`);
      }, 1000);
    } catch (error) {
      setShowErrorToast(true);
      if (error.response) {
        //handle the 401 throw an interceptor to logout
        if (error.response.status === 401) {
          console.log('UnAuthorized!');
        } else if (error.response.status <= 400) {
          console.log('http error');
        } else {
          console.log('UnKnown error: ', error);
        }
      }
    }
  };
  const navigateBack = () => {
    props.history.goBack();
  };
  useEffect(() => {
    loadData(+props.match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  const titleBarPrimary = (toggleMode, openDrawer) => (
    <IconButton style={{ color: 'inherit' }} onClick={openDrawer} aria-label="Help">
      <HelpOutlineIcon />
    </IconButton>
  );
  const titleBar = (
    <TitleBarContainer
      title="Employee Skills"
      backRoute={`/employees/${id}/profile`}
      titleBarPrimary={titleBarPrimary}
    />
  );
  const headerContent = (
    <Typography variant="h6" noWrap>
      Ranking system
    </Typography>
  );
  const mainContent = <EmployeeSkillsRatingHelp />;
  const sideContent = closeDrawer => (
    <div>
      <TabbedSideContainer
        close={closeDrawer}
        headerContent={headerContent}
        mainContent={mainContent}
      />
    </div>
  );

  return (
    <LayoutContainer
      titleBar={titleBar}
      sideContent={sideContent}
      drawerWidth={321}
      openDrawerAfterLoading={!isMobileView}
    >
      {!isLoading ? (
        <EmployeeSkills
          data={data}
          showSuccessToast={showSuccessToast}
          showErrorToast={showErrorToast}
          closeSuccessToast={() => setShowSuccessToast(false)}
          closeErrorToast={() => setShowErrorToast(false)}
          navigateBack={navigateBack}
          saveData={saveData}
          handleRatingChange={handleRatingChange}
          isLoading={isLoading}
          handleCheckBox={handleCheckBox}
          experienceRanges={experienceRanges}
        />
      ) : (
        <LoadingState />
      )}
    </LayoutContainer>
  );
};

const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  userPermissions: store.auth.userPermissions
});
export default connect(mapStateToProps, null)(EmployeesSkillsContainer);
