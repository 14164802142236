import StripedDataGrid from 'components/Grid/StripedDataGrid';

const EmployeesList = props => {
  return (
    <StripedDataGrid
      sortModel={props?.sortModel}
      autoWidth
      rows={props?.employees}
      columns={props?.columns}
      onSortModelChange={props?.onSortModelChange}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
            page: props?.page - 1
          }
        }
      }}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      disableRowSelectionOnClick
      onRowClick={params => props?.renderDetails(params.row.id)}
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      onPaginationModelChange={params => {
        props?.setPage(params?.page + 1);
      }}
    />
  );
};
export default EmployeesList;
