import { Box } from '@material-ui/core';
import LEVELS from 'utilities/constants/levels';
import StripedDataGrid from 'components/Grid/StripedDataGrid';
import './SkillsStatistics.scss';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
const SkillsStatistics = props => {
  const skills = props.data.map(skill => {
    const skillLevels = Object.entries(skill.levels)
      .filter(([key, value]) => key.startsWith('level'))
      .map(([key, value]) => value.count);

    const total = skillLevels.reduce((acc, level) => acc + level, 0);

    return {
      id: Math.random(),
      skillName: skill.skillName,
      ...Object.fromEntries(skillLevels.map((level, idx) => [`skillLevel${idx + 1}`, level])),
      total: total
    };
  });
  function drawer(rowName, columnName) {
    if (columnName !== 'total' && columnName !== 'skillName') {
      const employees = props?.data?.find(obj => obj.skillName === rowName).levels[
        `level${columnName.slice(-1)}`
      ]?.employees;
      const levelName = LEVELS[columnName.slice(-1) - 1].level;
      const data = { employees: employees, skillName: rowName, level: levelName };
      props.openSideContent(data);
      props.openDrawer?.();
    }
  }

  const columns = [
    { field: 'skillName', headerName: 'Skill', width: 300, cellClassName: 'disableHover' },
    ...LEVELS.map(rank => ({
      field: `skillLevel${rank.score}`,
      headerName: rank.level,
      width: 120,
      cellClassName: 'cellHover'
    })),
    { field: 'total', headerName: 'Total', width: 150, cellClassName: 'disableHover' }
  ];

  function exportToolbar() {
    return (
      <GridToolbarContainer className="rightToolbar">
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      {!props.isLoading ? (
        <Box sx={{ maxWidth: 1050 }}>
          <StripedDataGrid
            rows={skills}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              }
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableRowSelectionOnClick
            slots={{ toolbar: exportToolbar }}
            getRowClassName={params =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            onCellClick={params => drawer(params.row.skillName, params.field)}
          />
        </Box>
      ) : (
        <LoadingState />
      )}
    </>
  );
};

export default SkillsStatistics;
