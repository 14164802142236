import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Calendar from 'modules/shared/components/_employee-views/Calendar/DatePicker/Calendar';
import TagsContainer from 'modules/shared/components/tags/TagsContainer';
import EmployeeChipListContainer from 'modules/shared/components/_employee-views/EmployeeChipList/EmployeeChipListContainer';
import SkillChipListContainer from 'modules/shared/components/skill-chip-list/SkillChipListContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormHelperText } from '@material-ui/core';
const ProjectDialog = props => {
  const [projectFieldsProps, setProjectFieldsProps] = useState({
    name: { error: false, helperText: '' },
    lead: { error: false, helperText: '' }
  });

  const handleBlur = e => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    const hasValue = props.project.leadId?.length !== 0;

    let updatedFieldProps;

    if (value?.trim() === '') {
      updatedFieldProps = {
        [name]: {
          error: name === 'lead' ? !hasValue : true,
          helperText:
            !hasValue && name === 'lead' ? 'Field must not be empty' : 'Field must not be empty'
        }
      };
    } else {
      updatedFieldProps = { [name]: { error: false, helperText: '' } };
    }

    setProjectFieldsProps(prevProps => ({ ...prevProps, ...updatedFieldProps }));
  };

  const handleDateStart = date => {
    props.handleContractChange(date, 'startDate');
  };
  const handleDateEnd = date => {
    props.handleContractChange(date, 'endDate');
  };
  return (
    <div id="ProjectDialog">
      <div className="ProjectDialogTitle">
        {props.project.id ? props.project.name : 'New Project'}
      </div>

      <div className="ProjectDialogContent">
        <TextField
          onChange={props.handleChange}
          defaultValue={props.project.name}
          name="name"
          required
          autoFocus
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          fullWidth
          onBlur={handleBlur}
          {...projectFieldsProps?.name}
        />
        <div className="formRow-90">
          <TextField
            onChange={props.handleChange}
            defaultValue={props.project.code}
            name="code"
            margin="dense"
            id="Code"
            label="Code"
            type="text"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.project.billable || false}
                onChange={props.handleCheckBox}
                name="billable"
                size="small"
              />
            }
            label="Billable"
            labelPlacement="start"
          />
        </div>
        <EmployeeChipListContainer
          name="lead"
          handleEmployeesChange={props.handleEmployeesChange}
          multiple={false}
          employees={props.project.leadId}
          fullWidth={true}
          placeholder="Lead"
          required
          onBlur={handleBlur}
          error={projectFieldsProps?.lead?.error}
        />
        {projectFieldsProps?.lead?.error && (
          <FormHelperText error>{projectFieldsProps?.lead?.helperText}</FormHelperText>
        )}

        <div className="formRow-50">
          <Calendar
            handleChange={handleDateStart}
            date={props.project.startDate || undefined}
            placeholder="Start Date"
            fullWidth={true}
            error={props.dateRangeError}
          />
          <Calendar
            handleChange={handleDateEnd}
            date={props.project.endDate || undefined}
            placeholder="End Date"
            fullWidth={true}
            error={props.dateRangeError}
          />
        </div>
        <div className="formRow-50">
          <TextField
            onChange={props.handleChange}
            defaultValue={props.project.minResource}
            name="minResource"
            margin="dense"
            label="Minimum resources"
            type="number"
            error={props.resoursesRangeError}
          />
          <TextField
            onChange={props.handleChange}
            defaultValue={props.project.maxResource}
            name="maxResource"
            margin="dense"
            label="Maximum resources"
            type="number"
            error={props.resoursesRangeError}
          />
        </div>
        <SkillChipListContainer
          skills={props.project.skills}
          handleSkillsChange={props.handleSkillsChange}
        />
        <TagsContainer handleTagChange={props.handleTagChange} tags={props.project.tags} />
      </div>

      <div className="ProjectDialogActions">
        <Button onClick={props.cancel}>Cancel</Button>
        <Button onClick={props.saveProject} disabled={!props.isValid} color="primary">
          {props.project.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  );
};
export default ProjectDialog;
