import FeedbackCardContainer from './FeedbackCardContainer';
const FeedbackGrid = props => {
  const isOddLength = props?.feedbacks?.length % 2 !== 0;

  return (
    <div className="gridWrapper">
      <div className="employeeGridWrapper">
        {props?.feedbacks?.map((feedback, idx) => {
          return (
            <FeedbackCardContainer
              isLastCard={isOddLength && idx === props?.feedbacks?.length - 1}
              key={feedback?.id}
              data={feedback}
              routerLink={`/feedback/${feedback?.id}`}
            />
          );
        })}
      </div>
    </div>
  );
};
export default FeedbackGrid;
