import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialogComponent from 'modules/shared/components/confirm-dialog/ConfirmDialogComponent';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import EmployeeChip from 'modules/shared/components/_employee-views/EmployeeChip/EmployeeChip';
import ButtonBase from '@material-ui/core/ButtonBase';
import RoadmapDialogContainer from '../roadmaps-dialog/RoadmapDialogContainer';
const Roadmaps = props => {
  return (
    <div id="Roadmaps">
      {!props.isLoading || true ? (
        props.data.length > 0 ? (
          <div className="roadmapsContainer">
            {props.data.map((roadmap, idx) => (
              <ButtonBase
                className="roadmapCard"
                onClick={() => {
                  props.openSideContent(roadmap);
                  props.openDrawer?.();
                }}
                focusRipple
                key={idx}
              >
                <div className="roadmapCardHeader">
                  <div className="roadmapCardTitle">{roadmap.name}</div>
                </div>
                <div className="roadmapCardContent">
                  {roadmap.owners?.map((owner, idx) => (
                    <div style={{ padding: '0px 0.25em 0.25em 0px' }} key={idx}>
                      <EmployeeChip data={+owner} initialsOnly={false} key={idx} />
                    </div>
                  ))}
                </div>
              </ButtonBase>
            ))}
          </div>
        ) : (
          <EmptyState />
        )
      ) : (
        <LoadingState />
      )}

      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={props.isDialogOpen}
        onBackdropClick={props.closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <RoadmapDialogContainer
          data={props.dialogData}
          closeDialog={props.closeDialog}
          onRoadmapCreate={props.onRoadmapCreate}
        />
      </Dialog>

      <Dialog open={props.isDeleteDialogOpen} onBackdropClick={props.closeDeleteDialog}>
        <ConfirmDialogComponent data={props.toDelete} close={props.closeDeleteDialog} />
      </Dialog>

      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          {props.successToastText}
        </Alert>
      </Snackbar>

      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          {props.errorToastText}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default Roadmaps;
