import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  root: {
    width: '100%'
  },
  '.MuiDataGrid-columnHeaderRow': {
    width: '100%'
  },
  '.MuiDataGrid-columnHeadersInner': {
    width: '100%'
  },
  '.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
    backgroundColor: '#baddf48e !important'
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#baddf48e !important'
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: ' #baddf42e',
    maxHeight: 'none !important',
    '&:hover, &.Mui-hovered': {
      backgroundColor: ' #baddf49e'
    }
  },
  [`& .${gridClasses.row}.even`]: {
    maxHeight: 'none !important',
    '&:hover, &.Mui-hovered': {
      backgroundColor: ' #baddf49e'
    }
  },
  [`& .pending`]: {
    cursor: 'not-allowed'
  }
}));
export default StripedDataGrid;
