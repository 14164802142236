import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabbedSideContainer from '../tabbed-side-container/TabbedSideContainer';
import AuditViewer from '../../audit-viewer/AuditViewer';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}
const DetailsAuditSideContainer = props => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const mainContent = (
    <div>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
        >
          <Tab style={{ flex: '1 0', minWidth: 'auto' }} label="Details" {...a11yProps(0)} />
          <Tab style={{ flex: '1 0', minWidth: 'auto' }} label="Activities" {...a11yProps(1)} />
        </Tabs>
      </div>

      <TabPanel value={value} index={0} dir={theme.direction}>
        {props.detailsContent
          ? typeof props.detailsContent === 'function'
            ? props.detailsContent(props.closeDrawer)
            : props.detailsContent
          : null}
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {props.audit.length > 0 ? <AuditViewer data={props.audit} /> : 'no data'}
      </TabPanel>
    </div>
  );
  return (
    <TabbedSideContainer
      close={props.closeDrawer}
      headerContent={props.headerContent}
      actionContent={props.actionsContent}
      mainContent={mainContent}
    />
  );
};
export default DetailsAuditSideContainer;
