import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
const TitleBar = props => {
  return (
    <div id="titleBarContainer" className={`${props.color}TitleBar`} style={props.style}>
      {props.mode ? (
        <div id="secondaryTitleBar">
          {props.titleBarSecondary}
          <div style={{ flexGrow: 1, flexShrink: 1, flexBasis: '0%' }}></div>
          <IconButton
            color="inherit"
            onClick={() => {
              props.toggleMode(false);
              props?.onClose?.();
            }}
          >
            {' '}
            <CloseIcon />{' '}
          </IconButton>
        </div>
      ) : (
        <div id="primaryTitleBar">
          {props.hasBackLink && (
            <IconButton color="inherit" onClick={props.navigateBack}>
              <ArrowBackIcon />
            </IconButton>
          )}
          {props.titleBarPrimaryTitlePrefix ? props.titleBarPrimaryTitlePrefix : null}
          <div className="primaryTitleBarTitle">{props.title}</div>
          <div style={{ flexGrow: 1, flexShrink: 1, flexBasis: '0%' }}></div>
          {props.titleBarPrimary
            ? typeof props.titleBarPrimary === 'function'
              ? props.titleBarPrimary(props.toggleMode, props.openDrawer)
              : props.titleBarPrimary
            : null}
        </div>
      )}
    </div>
  );
};
export default TitleBar;
