import React from 'react';
import './AuditViewer.scss';
const AuditViewer = props => {
  return (
    <div className="auditViewer">
      {props.data.map((audit, idx) => (
        <div className="audit" key={'audit' + idx}>
          <div className="auditWrapper">
            <div className="auditUser">{audit.user}</div>
            <div className="auditDetails">
              <span>
                {new Date(audit.time).toDateString() +
                  ', ' +
                  new Date(audit.time).toLocaleTimeString() +
                  ' '}
              </span>
              | <span>{audit.action}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default AuditViewer;
