import { useState } from 'react';
import jwt from 'jsonwebtoken';
import { useHistory, useParams } from 'react-router-dom';
import iconDark from '../../../../assets/img/icon-dark.png';
import { TextField, Button } from '@mui/material';
import { feedbackService } from 'modules/Feedback/services/feedback';
const FeedbackAuthentication = () => {
  const { id } = useParams();
  const history = useHistory();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const idToken = localStorage.getItem('idToken');
  const decodeToken = jwt.decode(idToken);

  if (decodeToken?.id) {
    history.push(`/feedback/${decodeToken?.id}/response`);
  }
  const validateCode = () => {
    if (code.trim() === '') {
      setError('Code is required.');
      return false;
    } else if (code.trim()?.length < 5) {
      setError('Code length should be equal 5 digits.');
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validateCode()) return;

    try {
      const response = await feedbackService.feedbackAuthenticate({ code, id: +id });
      localStorage.setItem('idToken', response?.data);
      history.push(`/feedback/${id}/response`);
    } catch (err) {
      if (err.response.status === 403) {
        setError('You have already submitted this feedback.');
      } else {
        setError('Invalid code. Please try again.');
      }
    }
  };

  return (
    <div className="login">
      <div className="w-100 login-head hide-on-small-only">&nbsp;</div>
      <div className="card">
        <div className="cardHeader">
          <div className="logo"></div>
          <div className="cardHeaderText">
            <div className="cardTitle">Feedback Login</div>
          </div>
        </div>
        <div className="cardBody">
          <div className="wrapper">
            <img src={iconDark} alt="" className="responsive-img w-5" />
          </div>
          <form onSubmit={handleSubmit} action="#" className="p-x-1">
            <div className="formRow">
              <TextField
                label="Enter Code"
                variant="outlined"
                fullWidth
                value={code}
                onChange={e => setCode(e.target.value)}
                error={!!error}
                helperText={error}
                className="input-field"
              />
            </div>

            <Button variant="contained" color="primary" className="w-100" type="submit">
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeedbackAuthentication;
