import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const filter = (filter = {}) => {
  const nameFilter = filter.name ? `name=${filter.name}` : '';
  return axiosInstances.withAuthToken().get(`/initiatives/?${nameFilter}`);
};
const deleteInitiative = id => {
  return axiosInstances.withAuthToken().delete(`/initiatives/${id}`);
};
const add = data => {
  const { tags, ...d } = data;
  const tagNames = tags.map(i => i.name);
  const reqBody = { ...d, tags: tagNames };
  return axiosInstances.withAuthToken().post('/initiatives/', reqBody);
};
const get = async id => {
  const { data: initiative } = await axiosInstances.withAuthToken().get(`/initiatives/${id}`);
  initiative.tags = initiative.tags.map(t => ({ name: t }));
  return initiative;
};
const update = data => {
  const { tags, ...d } = data;
  const tagNames = tags.map(i => i.name);
  const reqBody = { ...d, tags: tagNames };
  return axiosInstances.withAuthToken().patch(`/initiatives/${data.id}`, reqBody);
};
const audit = id => {
  return axiosInstances.withAuthToken().get(`/initiatives/${id}/audit`);
};
export const InitiativesService = { filter, deleteInitiative, add, get, update, audit };
