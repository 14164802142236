import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const map = (skills, filter) => ({
  from: filter?.startDate ?? undefined,
  to: filter?.endDate ?? undefined,
  minLevel:
    skills?.length > 0 ? (filter?.minLevel <= 1 ? 0 : filter?.minLevel) : undefined ?? undefined,
  maxLevel:
    skills?.length > 0 ? (filter?.maxLevel <= 1 ? 0 : filter?.maxLevel) : undefined ?? undefined,
  bench: filter?.bench ?? undefined
});
const findByTechnology = filter => {
  let params = '?';
  const { skills, ...filters } = filter;
  if (skills) {
    params = params + `ids=${JSON.stringify(skills)}`;
  }
  const queryParamsValues = map(skills, filters);
  Object.getOwnPropertyNames(queryParamsValues).forEach(key => {
    if (queryParamsValues[key]) params = params + `&${key}=${queryParamsValues[key]}`; // filter[key] might need to JSON.stringify
  });
  return axiosInstances.withAuthToken().get(`/employees/skills/${params}`);
};
export const AllocationReportService = { findByTechnology };
