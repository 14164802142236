import defaultImg from '../../../assets/img/app_states/No-Connection.png';
const Benefit = ({ data }) => {
  return (
    <div className="benefitCard">
      {/* <img
        className="benefitImg"
        src={require(`../../../assets/img/${data?.image}`).default}
        alt={data?.title}
        width={100}
      /> */}
      {/* <h3 className="benefitTitle">{data?.title}</h3> */}
      <p className="benefitDescription">{data?.description}</p>
    </div>
  );
};
export default Benefit;
