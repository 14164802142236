import React, { useEffect, useState } from 'react';
import SearchBarCotainer from 'modules/shared/components/top-bars/search-bar/SearchBarContainer';
import LayoutContainer from 'modules/shared/components/_layout/layout-container/LayoutContainer';
import DetailsAuditSideContainer from 'modules/shared/components/_layout/detail-audit-side-container/DetailsAuditSideContainer';
import DepartmentsList from './DepartmentsList';
import './DepartmentsList.scss';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { departmentsService } from '../../services/departments';
import IconButton from '@material-ui/core/IconButton';
const DepartmentsListContainer = props => {
  const [departments, setDepartments] = useState([]);
  const [audits, setAudits] = useState([]);
  const [lastFilter, setLastFlter] = useState('');
  const [sideData, setSideData] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [successToastText, setSuccessToastText] = useState('');
  const [errorToastText, setErrorToastText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const filterDepartments = async (name = '') => {
    setIsLoading(true);
    const departments = await departmentsService.filter({ name });
    setDepartments(departments);
    setLastFlter(name);
    setIsLoading(false);
  };
  const openDepartmentDialog = department => {
    setIsDialogOpen(true);
    setDialogData(department);
  };
  const closeDepartmentDialog = () => {
    setIsDialogOpen(false);
    setDialogData(undefined);
  };
  const onDepartmentCreate = (state, errorMessage) => {
    if (state === 'success') {
      setShowSuccessToast(true);
      setSuccessToastText('Department saved successfully');
      closeDepartmentDialog();

      filterDepartments(lastFilter);
    } else {
      setShowErrorToast(true);
      setErrorToastText(errorMessage);
    }
  };
  const closeSuccessToast = () => {
    setShowSuccessToast(false);
    setSuccessToastText('');
  };
  const closeErrorToast = () => {
    setShowErrorToast(false);
    setErrorToastText('');
  };
  const closeDeleteDialog = async data => {
    if (data && data.id) {
      try {
        await departmentsService.deleteDepartment(data.id);
        setShowSuccessToast(true);
        setSuccessToastText('Department removed successfully!');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
        filterDepartments(lastFilter);
      } catch {
        setShowErrorToast(true);
        setErrorToastText('Cannot remove Department');
        setToDelete(undefined);
        setIsDeleteDialogOpen(false);
      }
    }
    setIsDeleteDialogOpen(false);
  };
  const confirmDelete = department => {
    setToDelete({ id: department.id, name: department.name, type: 'Department' });
    setIsDeleteDialogOpen(true);
  };
  const openSideContent = async department => {
    let audits = [
      {
        user: department.createdBy,
        time: department.createdAt,
        action: 'Create'
      }
    ];
    if (department.updatedBy)
      audits.push({
        user: department.updatedBy,
        time: department.updatedAt,
        action: 'Update'
      });
    setAudits(audits);
    setSideData(department);
  };
  const navigate = route => {
    props.history.push(route);
  };

  {
    useEffect(() => {
      filterDepartments();
    }, []);
    const searchBarCommandPlaceholder = (
      <Fab
        style={{ position: 'relative', top: '2em' }}
        onClick={() => openDepartmentDialog()}
        color="secondary"
        aria-label="Add tag"
      >
        {' '}
        <AddIcon />{' '}
      </Fab>
    );
    const titleBar = (
      <SearchBarCotainer
        backRoute="/"
        title="Departments"
        filter={filterDepartments}
        searchBarCommandPlaceholder={searchBarCommandPlaceholder}
      ></SearchBarCotainer>
    );
    const headerContent = (
      <div
        className="DetailsAuditSideHeader"
        style={{
          display: 'flex',
          alignItems: 'center',
          placeContent: 'center flex-start',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        <div
          className="sideDepartmentName"
          style={{
            font: '500 20px/32px',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {sideData?.name}
        </div>
      </div>
    );
    const detailsContent = closeDrawer => (
      <div className="sideDetailsContent" style={{ maxWidth: '30em' }}>
        <div
          className="upperActions"
          style={{ display: 'flex', placeContent: 'center flex-end', marginRight: '0.25em' }}
        >
          <IconButton
            onClick={() => {
              openDepartmentDialog(sideData);
              closeDrawer();
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
        <div className="sideDetailsList" style={{ paddingTop: '8px', fontSize: '12px' }}>
          <div
            className="sideDetailsListItem"
            style={{
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 16px',
              flex: '1 0'
            }}
          >
            <span className="liCaption" style={{ flex: '1 0' }}>
              Name
            </span>
            <span className="liCaption" style={{ flex: '1 0' }}>
              {sideData?.name}
            </span>
          </div>
          <div
            className="sideDetailsListItem"
            style={{
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              padding: '0 16px',
              flex: '1 0'
            }}
          ></div>
        </div>
      </div>
    );
    const sideContent = closeDrawer => (
      <DetailsAuditSideContainer
        headerContent={headerContent}
        detailsContent={detailsContent}
        audit={audits}
        closeDrawer={closeDrawer}
      />
    );
    const drawerWidth = 350;
    return (
      <LayoutContainer titleBar={titleBar} sideContent={sideContent} drawerWidth={drawerWidth}>
        <DepartmentsList
          departments={departments}
          openDepartmentDialog={openDepartmentDialog}
          closeDepartmentDialog={closeDepartmentDialog}
          onDepartmentCreate={onDepartmentCreate}
          isDialogOpen={isDialogOpen}
          showSuccessToast={showSuccessToast}
          closeSuccessToast={closeSuccessToast}
          showErrorToast={showErrorToast}
          closeErrorToast={closeErrorToast}
          dialogData={dialogData}
          isDeleteDialogOpen={isDeleteDialogOpen}
          closeDeleteDialog={closeDeleteDialog}
          confirmDelete={confirmDelete}
          toDelete={toDelete}
          successToastText={successToastText}
          errorToastText={errorToastText}
          openSideContent={openSideContent}
          navigate={navigate}
          isLoading={isLoading}
        />
      </LayoutContainer>
    );
  }
};
export default DepartmentsListContainer;
