import React, { useEffect, useState } from 'react';
import SKILLS_MATRIX_BENEFITS from 'utilities/constants/skills_matrix_benefits';
import Benefit from './Benefit';
import './Benefit.scss';

const BenefitsContainer = () => {
  const [currentSlideId, setCurrentSlideId] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      SKILLS_MATRIX_BENEFITS?.length - 1 > currentSlideId
        ? setCurrentSlideId(prev => +prev + 1)
        : setCurrentSlideId(0);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentSlideId]);

  return (
    <div id="BenefitsContainer">
      <div className="wrapper">
        <div className="slider">
          <div className="slides">
            <Benefit
              key={SKILLS_MATRIX_BENEFITS[currentSlideId]?.id}
              data={SKILLS_MATRIX_BENEFITS[currentSlideId]}
            />
          </div>
          <div className="buttons">
            {SKILLS_MATRIX_BENEFITS?.map(benefit => (
              <div
                className={
                  currentSlideId === benefit?.id - 1 ? 'benefit active' : 'benefit notActive'
                }
                onClick={() => {
                  setCurrentSlideId(benefit?.id - 1);
                }}
              >
                <img
                  key={benefit.id}
                  src={require(`../../../assets/img/${benefit?.image}`).default}
                  alt={benefit?.title}
                  width={120}
                />
                <h3>{benefit?.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsContainer;
