import { call, fork, put, take } from 'redux-saga/effects';
import { authError, loginStart, loginSuccess, checkAuthStart, checkAuthFailed } from '../actions';
import { getCurrentUser, login } from '../api';
const authKey = 'authToken';
const canAccess = 'canAccessIds';
function* watchLoginSaga() {
  while (true) {
    const action = yield take('LOGIN_REQUEST');
    yield fork(handleLogin, action);
    yield take(['AUTH_ERROR', 'SIGNOUT_SUCCESS']);
  }
}
function* watchCheckAuthSaga() {
  yield take('CHECK_Auth_REQUEST');
  yield call(handleCheckAuth);
}
function* handleLogin(action) {
  const reqBody = action.payload;
  yield put(loginStart());
  let res;
  try {
    const { data } = yield call(login, reqBody);
    if (data && data.auth_token) {
      sessionStorage.setItem(authKey, data.auth_token);
      //@ts-ignore
      if (data.auth_token) {
        const { data: currentUser } = yield call(getCurrentUser);
        yield put(loginSuccess({ currentUser, userPermissions: currentUser.permissions }));
      } else {
        sessionStorage.removeItem(authKey);
        sessionStorage.removeItem(canAccess);
        throw new Error('invalid_user');
      }
    } else {
      console.log('res error', res);
      throw new Error('unknown_error');
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(authError('unauthorized'));
      }
    } else {
      yield put(authError(error.message));
    }
  }
}
function* handleCheckAuth() {
  yield put(checkAuthStart());
  const token = sessionStorage.getItem(authKey);
  if (token) {
    const { data: currentUser } = yield call(getCurrentUser);
    yield put(loginSuccess({ currentUser, userPermissions: currentUser.permissions }));
  } else {
    yield put(checkAuthFailed('no token'));
  }
}
export { watchLoginSaga, watchCheckAuthSaga };
