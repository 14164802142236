import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import auth from './reducers/auth';
import layoutContent from './reducers/layoutContent';
const reducers = combineReducers({ auth, layoutContent });
const sagaMiddleware = createSagaMiddleware();
const middlewares = [];
middlewares.push(sagaMiddleware);
const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middlewares)));
sagaMiddleware.run(rootSaga);
export default store;
