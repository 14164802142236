import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarIcon from '@material-ui/icons/Star';
import ResourcesImage from 'assets/img/app_states/empty/Resources.png';
import ConfirmDialogComponent from 'modules/shared/components/confirm-dialog/ConfirmDialogComponent';
import EmployeeBasicContainer from 'modules/shared/components/_employee-views/EmployeeBasic/EmployeeBasicContainer';
import AllocationDialogContainer from '../allocation-dialog/AllocationDialogContainer';
import Button from '@material-ui/core/Button';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import AllocationCard from './AllocationCard';
const AllocatedList = props => {
  return (
    <div id="AllocatedList">
      {!props.isLoading ? (
        props.resources.length > 0 ? (
          <div className="resourcesContainer">
            {props.resources.map(resource => (
              <AllocationCard
                key={resource?.id}
                data={resource}
                openSideContent={props?.openSideContent}
                openDrawer={props?.openDrawer}
                openAllocationDialog={props?.openAllocationDialog}
                confirmDelete={props?.confirmDelete}
                getAverage={props?.getAverage}
              />
            ))}
          </div>
        ) : (
          <div className="emptyContainer">
            <div className="empty-allocation-wrapper">
              <EmptyState
                stateImage={
                  <div>
                    <img src={ResourcesImage} alt="" />
                  </div>
                }
                stateText={
                  <div className="empty-text-section">
                    <div
                      style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                      className="empty-text-message"
                    >
                      This project doesn't have any assigned resources yet.
                    </div>
                    <div className="empty-text-action" style={{ textAlign: 'center' }}>
                      <Button
                        style={{ position: 'static', textTransform: 'unset' }}
                        onClick={() => props.openAllocationDialog()}
                        variant="contained"
                        color="primary"
                      >
                        Start assigning resources
                      </Button>
                    </div>
                  </div>
                }
              />
            </div>
            <div className="verticalDevider"></div>
            <div className="suggested-wrapper">
              <div className="suggested-title">Suggested resources</div>
              <div className="suggestedCardsContainer">
                {props.suggested.map((suggest, idx) => (
                  <div className="suggested-card" key={'suggesredCard' + idx}>
                    <div className="suggested-card-content">
                      <div className="employeeInfo">
                        <EmployeeBasicContainer data={suggest.employee} />
                      </div>
                      <div className="allocationAmount">
                        {suggest.avgUtilization
                          ? suggest.avgUtilization === -Infinity
                            ? '-∞%'
                            : Math.round(suggest.avgUtilization) + '%'
                          : '0%'}
                      </div>
                      <div className="suggestedSkills">
                        {props.filterSuggestedResourceSkills(suggest).map((skill, index) => (
                          <div className="skillsWrapper" key={idx + 'skill' + index}>
                            {skill.imgUrl && <img src={skill.imgUrl} alt="" />}
                            {skill.level === 3 && <StarOutlineIcon />}
                            {skill.level === 4 && <StarHalfIcon />}
                            {skill.level === 5 && <StarIcon />}
                          </div>
                        ))}
                      </div>
                      <div className="suggestedCardAction">
                        <div className="projectsStats">
                          {`${suggest.totalProjects} project${
                            suggest.totalProjects > 1 ? 's' : ''
                          } assigned${
                            suggest.benchedProjects > 0
                              ? ` | on bench of ${suggest.benchedProjects}`
                              : ''
                          }`}
                        </div>
                        <div className="spacerDiv" />
                        <Button
                          onClick={() =>
                            props.openAllocationDialog(
                              props.getSuggestedAllocation(suggest.employee)
                            )
                          }
                          variant="outlined"
                          color="primary"
                        >
                          Assign
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      ) : (
        <LoadingState />
      )}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.isDialogOpen}
        onBackdropClick={props.closeAllocationDialog}
        aria-labelledby="form-dialog-title"
      >
        <AllocationDialogContainer
          data={props.dialogData}
          closeDialog={props.closeAllocationDialog}
          onAllocationCreate={props.onAllocationCreate}
        />
      </Dialog>

      <Dialog open={props.isDeleteDialogOpen} onBackdropClick={props.closeDeleteDialog}>
        <ConfirmDialogComponent data={props.confirmDeleteData} close={props.closeDeleteDialog} />
      </Dialog>

      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          {props.successToastText}
        </Alert>
      </Snackbar>

      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          {props.errorToastText}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default AllocatedList;
