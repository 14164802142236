import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import TitleBar from './TitleBar';
import './TitleBar.scss';

const TitleBarContainer = props => {
  const [isSecondaryMode, setIsSecondaryMode] = useState(false);
  const [themeSubject, setThemeSubject] = useState('primary');
  const [hasBackLink] = useState(props.backRoute ? true : false);

  const toggleMode = isSecondary => {
    setIsSecondaryMode(isSecondary === true);
    setThemeSubject(isSecondary === true ? 'secondary' : 'primary');
    props.onModeToggle && props.onModeToggle(isSecondary !== true);
  };

  const navigateBack = () => {
    if (props.history.location.pathname.endsWith('/profile'))
      props.backRoute && props.history.push(props.backRoute);
    else props.history.goBack();
  };

  return (
    <>
      <TitleBar
        style={props.style}
        title={props.title}
        color={themeSubject}
        hasBackLink={hasBackLink}
        mode={isSecondaryMode}
        toggleMode={toggleMode}
        navigateBack={navigateBack}
        titleBarPrimaryTitlePrefix={props.titleBarPrimaryTitlePrefix}
        titleBarPrimary={props.titleBarPrimary}
        titleBarSecondary={props.titleBarSecondary}
        openDrawer={props.openDrawer}
        closeDrawer={props.closeDrawer}
        onClose={props.onClose}
      />
    </>
  );
};

export default withRouter(TitleBarContainer);
