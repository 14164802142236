import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import EmptyEmployeeImg from 'assets/img/app_states/empty/Employees.png';
import FeedbackList from './FeedbackList';
import FeedbackGrid from './FeedbackGrid';
import Dialog from '@material-ui/core/Dialog';
import FeedbackRequest from '../feedback-request/FeedbackRequest';
const Feedbacks = props => {
  const savedSortModel = localStorage.getItem('dataGridSort');
  const savedPathname = localStorage.getItem('pathname');
  const [sortModel, setSortModel] = useState(
    savedSortModel && savedPathname === props?.location?.pathname ? JSON.parse(savedSortModel) : []
  );
  const handleSortChange = newSortModel => {
    setSortModel(newSortModel);
    localStorage.setItem('dataGridSort', JSON.stringify(newSortModel));
  };
  const isListView = props?.view === 'list';

  useEffect(() => {
    sessionStorage.setItem('feedbacks.currentPage', props?.page?.toString());
  }, [props?.page]);
  const renderDetails = (id, status) => {
    if (status === 'pending') return;
    return props.navigate(`/feedback/${id}`);
  };

  const columns = [
    { field: 'employeeNo', headerName: 'Employee ID', width: 150 },
    {
      field: 'fullName',
      headerName: 'Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 350,
      valueGetter: params => `${params.row.firstName || ''} ${params.row.lastName || ''}`
    },
    {
      field: 'requestedName',
      headerName: 'Requested Name',
      width: 350
    },
    {
      field: 'requestedEmail',
      headerName: 'Requested Email',
      width: 350
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: params => {
        let statusStyle = {};
        switch (params.value) {
          case 'completed':
            statusStyle = { color: '#388e3c', fontWeight: 'bold' };
            break;
          case 'pending':
            statusStyle = { color: '#f8b400' };
            break;
          default:
            statusStyle = { color: 'black' };
        }
        return <span style={statusStyle}>{params.value}</span>;
      }
    }
  ];
  const startIndex = (props?.page - 1) * props?.itemsPerPage;
  const endIndex = startIndex + props?.itemsPerPage;
  const currentFeedbacks = props?.feedbacks.slice(startIndex, endIndex);
  return (
    <Box sx={{ height: '100%', width: '80%', margin: 'auto' }}>
      {!props.isLoading ? (
        props?.feedbacks?.length ? (
          isListView ? (
            <FeedbackList
              sortModel={sortModel}
              feedbacks={props?.feedbacks}
              columns={columns}
              onSortModelChange={handleSortChange}
              page={props?.page}
              setPage={props?.setPage}
              renderDetails={renderDetails}
            />
          ) : (
            <FeedbackGrid
              feedbacks={currentFeedbacks}
              setPage={props?.setPage}
              page={props?.page}
            />
          )
        ) : (
          <EmptyState
            stateImage={
              <div>
                <img src={EmptyEmployeeImg} alt="empty-employee" />
              </div>
            }
            stateText={
              <div className="empty-text-section">
                <div
                  style={{ marginBottom: '0.75em', font: '400 15px/24px "Poppins", sans-serif' }}
                  className="empty-text-message"
                >
                  No employees found.
                </div>
              </div>
            }
          />
        )
      ) : (
        <LoadingState />
      )}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.isDialogOpen}
        onClose={props.closeClientDialog}
        aria-labelledby="form-dialog-title"
      >
        <FeedbackRequest
          currentUser={props.currentUser}
          closeDialog={props.closeClientDialog}
          fetchFeedbackData={props.fetchFeedbackData}
        />
      </Dialog>
    </Box>
  );
};
const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  userPermissions: store.auth.userPermissions
});
export default connect(mapStateToProps, null)(Feedbacks);
