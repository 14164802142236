import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { authenticationService } from '../../services/security/authenticationService';
import { loginRequest } from '../../store/actions';
import Login from './Login';

const LoginContainer = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authUrl, setAuthUrl] = useState('');
  const [returnUrl, setReturnUrl] = useState('');
  const [adminLoginView, setAdminLoginView] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    props.login({ username, password });
  };
  const toggleView = showAdminLogin => {
    setAdminLoginView(showAdminLogin);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (!props.isLoggedIn) {
        //getting query params and parse them into object
        const queryObject = {};
        const queryString = props.location.search.replace('?returnUrl=/?', '');
        const queryIterator = new URLSearchParams(queryString);
        for (let entry of queryIterator.entries()) {
          queryObject[entry[0]] = entry[1];
        }
        const code = queryObject.code;
        const returnUrl = queryObject.returnUrl || '/';
        if (!code) {
          const { data } = await authenticationService.getOfficeAuthUrl();
          setAuthUrl(data.data);
          setReturnUrl(returnUrl);
        } else {
          setReturnUrl(returnUrl);
          props.login({ code });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {props.isLoggedIn ? (
        <Redirect to={returnUrl} />
      ) : (
        <Login
          authUrl={authUrl}
          isLoading={props.isLoading}
          unauthorized={props.unauthorized}
          unknownError={props.unknownError}
          invalidUser={props.invalidUser}
          handleChange={handleChange}
          login={handleSubmit}
          adminLoginView={adminLoginView}
          toggleView={toggleView}
        />
      )}
    </>
  );
};

const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  isLoading: store.auth.isLoading,
  unauthorized: store.auth.unauthorized,
  unknownError: store.auth.unknownError,
  invalidUser: store.auth.invalidUser,
  isLoggedIn: store.auth.isLoggedIn
});
const mapDispatchToProps = {
  login: loginRequest
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
