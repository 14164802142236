import { axiosInstances } from '../../../../utilities/axios/Instances/axios.instances';
const add = data => {
  return axiosInstances.withAuthToken().post(`/auth/roles`, data);
};
const update = data => {
  return axiosInstances.withAuthToken().patch(`/auth/roles/${data.id}`, data);
};
const deleteRole = id => {
  return axiosInstances.withAuthToken().delete(`/auth/roles/${id}`);
};
const audit = id => {
  return axiosInstances.withAuthToken().get(`/auth/roles/${id}/audit`);
};
const filter = async filter => {
  const nameFilter = filter.name ? `name=${filter.name}` : '';
  const { data: roles } = await axiosInstances.withAuthToken().get(`/auth/roles/?${nameFilter}`);
  return roles;
};
export const rolesService = { add, update, deleteRole, audit, filter };
