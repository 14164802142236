import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import LEVELS from 'utilities/constants/levels';

const Filters = props => {
  return (
    <div
      id="employeeFilterForm"
      style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}
    >
      <div
        style={{ display: 'flex', width: '100%', alignItems: 'center', margin: '-4rem 0 1rem 0' }}
      >
        <FormControl style={{ flex: '1 0', marginRight: '0.25em', marginTop: '-0.4rem' }}>
          <Autocomplete
            id="group-select"
            name="group"
            ref={props?.groupRef}
            options={props?.groups}
            onChange={props?.handleGroupChange}
            getOptionDisabled={option => option.disabled}
            renderInput={params => <TextField {...params} label="Group" />}
          />
        </FormControl>
        <FormControl
          style={{
            flex: '1 0',
            marginRight: '0.25em',
            display: 'flex',
            flexDirection: 'column',
            height: '86px',
            marginTop: '2rem'
          }}
        >
          <InputLabel id="level-select-label">Skills</InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            labelId="skills-select-label"
            id="skills-select"
            multiple
            value={props?.filterForm?.skill || []}
            onChange={props?.handleSkillChange}
            renderValue={selected => selected?.join(', ')}
            name="skill"
          >
            <MenuItem value={''} disabled={props?.filterForm?.skill?.length === 0}>
              Clear Filter
            </MenuItem>
            {props?.filteredSkills?.map(skill => (
              <MenuItem key={skill?.id} value={skill?.name}>
                <Checkbox
                  color="primary"
                  checked={props?.filterForm?.skill.indexOf(skill?.name) > -1}
                />
                <ListItemText primary={skill?.name} />{' '}
              </MenuItem>
            ))}
          </Select>
          {props?.filterForm?.skill?.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '.3rem',
                textTransform: 'capitalize',
                maxHeight: '35px',
                overflow: 'hidden auto',
                border: '1px solid #eee'
              }}
            >
              {props?.filterForm?.skill?.map(skill => (
                <Chip onDelete={() => props?.deleteSkill(skill)} key={skill} label={skill} />
              ))}
            </Box>
          )}
        </FormControl>
        <FormControl
          style={{
            flex: '1 0',
            marginRight: '0.25em',
            display: 'flex',
            flexDirection: 'column',
            height: '86px',
            marginTop: '2rem'
          }}
        >
          <InputLabel id="level-select-label">Level</InputLabel>
          <Select
            disabled={props?.filterForm?.skill?.length === 0}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            labelId="level-select-label"
            id="level-select"
            multiple
            value={props?.filterForm?.levelsNames}
            onChange={props?.handleLevelChange}
            renderValue={selected => selected?.join(', ')}
            name="levels"
          >
            <MenuItem value={''} disabled={props?.filterForm?.levels?.length === 0}>
              Clear Filter
            </MenuItem>
            <MenuItem key={'all'} value={'all'}>
              <Checkbox color="primary" checked={props?.filterForm?.levelsNames?.length === 5} />
              <ListItemText primary={'All'} />
            </MenuItem>
            {LEVELS?.map(lvl => (
              <MenuItem key={lvl?.score} value={lvl?.level}>
                <Checkbox
                  color="primary"
                  checked={props?.filterForm?.levelsNames.indexOf(lvl?.level) > -1}
                />
                <ListItemText primary={lvl?.level} />
              </MenuItem>
            ))}
          </Select>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '.3rem',
              textTransform: 'capitalize',
              maxHeight: '35px',
              overflow: 'hidden auto',
              border: '1px solid #eee'
            }}
          >
            {props?.filterForm?.levelsNames.map(value => (
              <Chip onDelete={() => props?.deleteLevel(value)} key={value} label={value} />
            ))}
          </Box>
        </FormControl>
        <FormControl style={{ flex: '1 0', marginRight: '0.25em', marginBottom: '5.5px' }}>
          <InputLabel id="level-select-label">Years of experience</InputLabel>
          <Select
            disabled={props?.filterForm?.skill?.length === 0}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            labelId="experience-select-label"
            id="experience-select"
            value={props?.filterForm?.yearsOfExperience}
            onChange={props?.handleExperienceChange}
            name="experience"
          >
            <MenuItem value={''} disabled={!props?.filterForm?.yearsOfExperience}>
              Clear Filter
            </MenuItem>

            {props?.experienceRanges?.map(option => (
              <MenuItem key={option?.id} value={option?.id}>
                {option?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          disabled={props?.filterForm?.skill?.length === 0}
          name="usedInAspire"
          id="aspire-checkbox-label"
          label={'Used in Aspire'}
          checked={props?.filterForm?.usedInAspire}
          control={<Checkbox color="primary" />}
          style={{ margin: '0.5rem 0.5rem 0 0.5rem' }}
          onChange={props?.handleUsedInAspireChange}
        ></FormControlLabel>
        <Fab
          disabled={props?.filterForm?.skill?.length === 0}
          onClick={() => {
            props?.applyFilter();
            props?.addSkillColumns();
          }}
          size="small"
          className={props?.filterForm?.skill?.length === 0 ? 'disabledBtn' : 'enabledBtn'}
        >
          <CheckIcon />
        </Fab>
        <Fab
          disabled={props?.filterForm?.skill?.length === 0}
          onClick={props?.clearFilter}
          size="small"
          className={props?.filterForm?.skill?.length === 0 ? 'clear disabled' : 'clear'}
        >
          <ClearIcon />
        </Fab>
      </div>
    </div>
  );
};

export default Filters;
