import React from 'react';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';
import './EmptyState.scss';
const EmptyState = props => {
  return (
    <div id="emptyStateContainer">
      {props.stateImage ? props.stateImage : props.icon}
      {props.stateText ? props.stateText : <div className="emptyStateMessage">{props.message}</div>}
    </div>
  );
};
EmptyState.defaultProps = {
  icon: <AirplanemodeInactiveIcon />,
  message: 'No results found'
};
export default EmptyState;
