import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import WorkIcon from '@material-ui/icons/Work';
import Menu from 'modules/shared/components/_controls/Menu/Menu';
import ConfirmDialogComponent from 'modules/shared/components/confirm-dialog/ConfirmDialogComponent';
import ClientDialogContainer from '../client-dialog/ClientDialogContainer';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import EmptyClientImg from 'assets/img/app_states/empty/Clients.png';
import EmptyState from 'modules/shared/components/_states/EmptyState/EmptyState';
import Button from '@material-ui/core/Button';
const ClientsList = props => {
  return (
    <div id="ClientsList">
      {!props.isLoading ? (
        props.clients.length > 0 ? (
          <div className="clientsContainer">
            {props.clients.map((client, idx) => (
              <div key={idx} className="clientCard">
                <div className="clientCardHeader">
                  <div className="clientCardTitle">{client.name}</div>
                  <div className="menuContainer">
                    <Menu>
                      <div
                        className="CustommenuItem"
                        onClick={() => {
                          props.openSideContent(client);
                          props.openDrawer?.();
                        }}
                      >
                        <InfoIcon />
                        <span style={{ marginLeft: '16px' }}>Info</span>
                      </div>
                      <div
                        className="CustommenuItem"
                        onClick={() => props.navigate(`/admin/clients/${client?.id}/projects`)}
                      >
                        <WorkIcon />
                        <span style={{ marginLeft: '16px' }}>Projects List</span>
                      </div>
                      <div
                        className="CustommenuItem"
                        onClick={() => props.openClientDialog(client)}
                      >
                        <EditIcon />
                        <span style={{ marginLeft: '16px' }}>Edit</span>
                      </div>
                      <div className="CustommenuItem" onClick={() => props.confirmDelete(client)}>
                        <DeleteIcon />
                        <span style={{ marginLeft: '16px' }}>Delete</span>
                      </div>
                    </Menu>
                  </div>
                </div>
                {client.imgUrl && (
                  <img src={client.imgUrl} alt={client.name} className="clientCardImage" />
                )}
              </div>
            ))}
          </div>
        ) : (
          <EmptyState
            stateImage={
              <div>
                <img src={EmptyClientImg} alt="" />
              </div>
            }
            stateText={
              <div className="empty-text-section">
                <div
                  style={{ marginBottom: '0.75em', font: '400 15px/24px' }}
                  className="empty-text-message"
                >
                  You don't have any clients yet.
                </div>
                <div className="empty-text-action" style={{ textAlign: 'center' }}>
                  <Button
                    style={{ position: 'static', textTransform: 'unset' }}
                    onClick={() => props.openClientDialog()}
                    variant="contained"
                    color="primary"
                  >
                    Create new client
                  </Button>
                </div>
              </div>
            }
          />
        )
      ) : (
        <LoadingState />
      )}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.isDialogOpen}
        onClose={props.closeClientDialog}
        aria-labelledby="form-dialog-title"
      >
        <ClientDialogContainer
          data={props.dialogData}
          closeDialog={props.closeClientDialog}
          onClientCreate={props.onClientCreate}
        />
      </Dialog>

      <Dialog open={props.isDeleteDialogOpen} onBackdropClick={props.closeDeleteDialog}>
        <ConfirmDialogComponent data={props.toDelete} close={props.closeDeleteDialog} />
      </Dialog>

      <Snackbar
        open={props.showSuccessToast}
        autoHideDuration={1000}
        onClose={props.closeSuccessToast}
      >
        <Alert onClose={props.closeSuccessToast} severity={'success'}>
          {props.successToastText}
        </Alert>
      </Snackbar>

      <Snackbar open={props.showErrorToast} autoHideDuration={1000} onClose={props.closeErrorToast}>
        <Alert onClose={props.closeErrorToast} severity={'error'}>
          {props.errorToastText}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default ClientsList;
