import React, { useState, useEffect } from 'react';
import * as skillService from '../../services/skills-management';
import * as departmentService from '../../services/departments';
import SkillBasicInfoDialog from './SkillBasicInfoDialog';
import './SkillBasicInfoDialog.scss';

const SkillBasicInfoDialogContainer = props => {
  const [skill, setSkill] = useState({
    id: props?.data?.id || undefined,
    name: props?.data?.name || '',
    department: props?.data?.department || undefined,
    group: props?.data?.group || '',
    imgUrl: props?.data?.imgUrl || '',
    availabilityJordan: props?.data?.availabilityJordan || '',
    availabilityIndia: props?.data?.availabilityIndia || '',
    marketDemand: props?.data?.marketDemand || ''
  });
  const [isValid, setIsValid] = useState(false);
  const [departments, setDepartments] = useState([]);

  const reset = () => {
    setSkill({
      id: undefined,
      name: '',
      department: undefined,
      group: '',
      imgUrl: '',
      availabilityJordan: '',
      availabilityIndia: '',
      marketDemand: ''
    });
  };

  const handleChange = e => {
    const updatedSkill = { ...skill, [e.target.name]: e.target.value };
    let isValid = false;
    if (checkValidity(updatedSkill)) {
      isValid = true;
    }
    setSkill(updatedSkill);
    setIsValid(isValid);
  };

  const handleDepartmentChange = e => {
    const DepartmentObj = departments.find(department => department.name === e.target.value);
    const updatedSkill = { ...skill, [e.target.name]: DepartmentObj };
    let isValid = false;
    if (checkValidity(updatedSkill)) {
      isValid = true;
    }
    setSkill(updatedSkill);
    setIsValid(isValid);
  };

  const checkValidity = skill => {
    const { imgUrl, ...s } = skill;
    if (!Object.values(s).includes('') && skill.department) {
      return true;
    } else {
      return false;
    }
  };

  const cancel = () => {
    reset();
    props.closeDialog();
  };

  const createSkill = async () => {
    const data = {
      ...skill,
      name: skill.name?.trim(),
      group: skill.group?.trim(),
      department: skill.department?.id
    };

    if (data.id && data.id !== null && data.id !== undefined) {
      try {
        const { id, ...rest } = data;
        await skillService.update(rest, id);
        reset();
        props.onSkillCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onSkillCreate('fail', errorMessage);
      }
    } else {
      try {
        await skillService.add(data);
        reset();
        props.onSkillCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onSkillCreate('fail', errorMessage);
      }
    }
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const { data: departments } = await departmentService.get();
        setDepartments(departments);
      } catch (error) {
        console.log({ ...error });
      }
    };

    fetchDepartments();
  }, []);

  return (
    <SkillBasicInfoDialog
      skill={skill}
      isValid={isValid}
      handleChange={handleChange}
      handleDepartmentChange={handleDepartmentChange}
      cancel={cancel}
      createSkill={createSkill}
      departments={departments}
    />
  );
};

export default SkillBasicInfoDialogContainer;
