import React, { useEffect, useState } from 'react';
import { departmentsService } from '../../services/departments';
import DepartmentDialog from './DepartmentDialog';
import './DepartmentDialog.scss';
const DepartmentDialogContainer = props => {
  const [department, setDepartment] = useState({
    id: props?.data?.id || undefined,
    name: props?.data?.name || ''
  });
  const [isValid, setIsValid] = useState(false);
  const reset = () => {
    setDepartment({ id: undefined, name: '' });
    setIsValid(false);
  };

  const cancel = () => {
    reset();
    props.closeDialog();
  };
  const checkValidity = department => {
    const { id, ...c } = department;
    if (!(Object.values(c).includes('') || Object.values(c).includes(undefined))) return true;
    return false;
  };
  const handleChange = e => {
    const updatedDepartment = { ...department, [e.target.name]: e.target.value };
    setIsValid(false);
    if (checkValidity(updatedDepartment)) {
      setIsValid(true);
    }
    setDepartment(updatedDepartment);
  };
  const saveDepartment = async () => {
    const data = { id: department.id, name: department.name?.trim() };
    if (data.id && data.id !== null && data.id !== undefined) {
      try {
        const { id, ...rest } = data;
        // rest.tags = data.tags?.map(tag => tag.id);
        await departmentsService.update(rest, id);
        reset();
        props.onDepartmentCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onDepartmentCreate('fail', errorMessage);
      }
    } else {
      try {
        await departmentsService.add(data);
        reset();
        props.onDepartmentCreate('success');
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        props.onDepartmentCreate('fail', errorMessage);
      }
    }
  };
  useEffect(() => {
    if (props.data) {
      setDepartment(props.data);
      setIsValid(true);
    } else {
      reset();
    }
  }, []);

  return (
    <DepartmentDialog
      department={department}
      isValid={isValid}
      handleChange={handleChange}
      cancel={cancel}
      saveDepartment={saveDepartment}
    />
  );
};
export default DepartmentDialogContainer;
