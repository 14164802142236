import React from 'react';
import './EmployeeSkillsRatingHelp.scss';
import LEVELS from 'utilities/constants/levels';
const EmployeeSkillsRatingHelp = () => {
  return (
    <div id="EmployeeSkillsRatingHelp">
      {LEVELS.map((rank, idx) => (
        <div key={idx}>
          <div className="subHeader">{rank.level}</div>
          {rank.descriptions.map((desc, idx) => (
            <div key={'desc' + idx} className="rankDescription">
              {desc}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default EmployeeSkillsRatingHelp;
