import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import './upload.scss';
import ConfirmUploadDialog from 'modules/shared/components/confirm-dialog/ConfirmUploadDialog';
const Upload = props => {
  return (
    <div>
      <form className="upload-form" onSubmit={props.submitFile}>
        <input
          className="file-input"
          type="file"
          onChange={props.onInputChange}
          accept=".csv"
          id="upload-input"
        />

        <Snackbar
          open={props.showErrorToast}
          autoHideDuration={2000}
          onClose={props.closeErrorToast}
        >
          <Alert onClose={props.closeErrorToast} severity={'error'}>
            {props?.errorToastText}
          </Alert>
        </Snackbar>

        <Snackbar
          open={props.showSuccessToast}
          autoHideDuration={2000}
          onClose={props.closeSuccessToast}
        >
          <Alert onClose={props.closeSuccessToast} severity={'success'}>
            {props?.successToastText}
          </Alert>
        </Snackbar>
      </form>
      {props?.file && (
        <ConfirmUploadDialog
          file={props?.file}
          open={props?.openDialog}
          submitFile={props.submitFile}
          handleClose={props.handleClose}
          loadingUpload={props?.loadingUpload}
        />
      )}
    </div>
  );
};
export default Upload;
