import React from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Calendar from 'modules/shared/components/_employee-views/Calendar/DatePicker/Calendar';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SkillChipListContainer from 'modules/shared/components/skill-chip-list/SkillChipListContainer';
import LEVELS from 'utilities/constants/levels';
const OverallAllocationFilter = props => {
  console.log('render');
  return (
    <div id="overallAllocationFilter">
      <div className="filterDialogTitle">Filter</div>

      <div className="filterDialogContent">
        <div className="formRow-50">
          <Calendar
            handleChange={date => props.handleChange('startDate', moment(date))}
            date={props.filter.startDate.toISOString() || undefined}
            placeholder="Start Date"
            fullWidth={true}
          />
          <Calendar
            handleChange={date => props.handleChange('endDate', moment(date))}
            date={props.filter.endDate.toISOString() || undefined}
            placeholder="End Date"
            fullWidth={true}
          />
        </div>
        <div className="allocationRange">
          <TextField
            onChange={e => props.handleChange('minUtil', e.target.value)}
            defaultValue={props.filter.minUtil}
            type="number"
            label="Minimum Allocation"
          />
          <TextField
            onChange={e => props.handleChange('maxUtil', e.target.value)}
            defaultValue={props.filter.maxUtil}
            type="number"
            label="Maximum Allocation"
          />
        </div>
        <SkillChipListContainer
          skills={props.filter.skills || undefined}
          handleSkillsChange={skills => props.handleChange('skills', skills)}
          placeholder="Skills"
        />
        <div className="skillLevelRange">
          <FormControl>
            <InputLabel id="minLevel">Minimum Level</InputLabel>
            <Select
              onChange={e => props.handleChange('minLevel', e.target.value)}
              value={props.filter.minLevel || ''}
              name="minLevel"
              labelId="minLevel"
            >
              {LEVELS?.map(lvl => (
                <MenuItem key={lvl?.score} value={lvl?.score}>
                  {lvl?.level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="maxLevel">Maximum Level</InputLabel>
            <Select
              onChange={e => props.handleChange('maxLevel', e.target.value)}
              value={props.filter.maxLevel || ''}
              name="maxLevel"
              labelId="maxLevel"
            >
              {LEVELS?.map(lvl => (
                <MenuItem key={lvl?.score} value={lvl?.score}>
                  {lvl?.level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="filterDialogActions">
        <Button onClick={props.resetFilter} color="secondary">
          Reset
        </Button>
        <div className="spacerDiv" />
        <Button onClick={props.cancel}>Cancel</Button>
        <Button onClick={props.applyFilter} disabled={!props.isValid}>
          Apply
        </Button>
      </div>
    </div>
  );
};
export default OverallAllocationFilter;
