import React, { useEffect, useState } from 'react';
import { InitiativesService } from '../../services/initiatives';
import InitiativeDialog from './InitativesDialog';
import './InitativesDialog.scss';
const InitiativeDialogContainer = props => {
  const [initiative, setInitiative] = useState({
    id: undefined,
    name: '',
    description: '',
    owners: [],
    startDate: undefined,
    endDate: undefined,
    tags: []
  });
  const [isValid, setIsvalid] = useState(false);
  const [dateRangeError, setDateRangeError] = useState(false);

  const handleChange = (name, value) => {
    const updatedInitiative = { ...initiative, [name]: value };
    const { isValid, dateRangeError } = validation(updatedInitiative, name);
    setInitiative(updatedInitiative);
    setIsvalid(isValid);
    setDateRangeError(dateRangeError);
  };
  const validation = (initiative, validate) => {
    if (
      (validate === 'startDate' || validate === 'endDate') &&
      initiative.startDate &&
      initiative.endDate
    ) {
      if (new Date(initiative.startDate).getTime() < new Date(initiative.endDate).getTime()) {
        setDateRangeError(false);
      } else {
        setDateRangeError(true);
      }
    } else {
      setDateRangeError(false);
    }
    const isValid =
      !dateRangeError &&
      initiative.name?.trim() &&
      (initiative.owners?.length || 0) > 0 &&
      initiative.startDate &&
      initiative.endDate;
    return { dateRangeError, isValid };
  };
  const save = async () => {
    const data = { ...initiative, name: initiative.name?.trim() };
    if (data && data.id !== null && data.id !== undefined) {
      try {
        await InitiativesService.update(data);
        reset();
        props.onInitiativeCreate('success');
      } catch {
        props.onInitiativeCreate('fail');
      }
    } else {
      try {
        await InitiativesService.add(data);
        reset();
        props.onInitiativeCreate('success');
      } catch (error) {
        props.onInitiativeCreate('fail');
      }
    }
  };
  const cancel = () => {
    reset();
    props.closeDialog();
  };
  const reset = () => {
    setInitiative({
      id: undefined,
      name: '',
      description: '',
      owners: [],
      startDate: undefined,
      endDate: undefined,
      tags: []
    });
    setIsvalid(false);
    setDateRangeError(false);
  };
  useEffect(() => {
    if (props.data) {
      setInitiative(props.data);
      setIsvalid(true);
      setDateRangeError(false);
    } else {
      reset();
    }
  }, []);

  return (
    <InitiativeDialog
      initiative={props.data}
      isValid={isValid}
      dateRangeError={dateRangeError}
      cancel={cancel}
      save={save}
      handleChange={handleChange}
    />
  );
};
export default InitiativeDialogContainer;
