import { axiosInstances } from 'utilities/axios/Instances/axios.instances';
const list = (clientId, projectId) => {
  return axiosInstances
    .withAuthToken()
    .get(`/clients/${clientId}/projects/${projectId}/resources/`);
};
const add = (clientId, projectId, data) => {
  return axiosInstances
    .withAuthToken()
    .post(`/clients/${clientId}/projects/${projectId}/resources/`, data);
};
const deleteAllocation = (clientId, projectId, resourceId, project, empDetails) => {
  return axiosInstances
    .withAuthToken()
    .delete(`/clients/${clientId}/projects/${projectId}/resources/${resourceId}`, {
      data: { project, empDetails }
    });
};
const patch = (clientId, projectId, resourceId, data) => {
  return axiosInstances
    .withAuthToken()
    .patch(`/clients/${clientId}/projects/${projectId}/resources/${resourceId}`, data);
};
const get = (clientId, projectId, resourceId) => {
  return axiosInstances
    .withAuthToken()
    .get(`/clients/${clientId}/projects/${projectId}/resources/${resourceId}`);
};
export const allocationService = { list, add, deleteAllocation, patch, get };
