import React from 'react';
import Indeterminate from '../../components/Loaders/indeterminate/indeterminate';
import Button from '@material-ui/core/Button';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import LockIcon from '@material-ui/icons/Lock';
import './Login.scss';
import iconDark from '../../assets/img/icon-dark.png';
import office365 from '../../assets/img/office-365-logo.png';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
const Login = props => {
  return (
    <div className="login">
      <div className="w-100 login-head hide-on-small-only">&nbsp;</div>
      <div className="card">
        <div className="cardHeader">
          <div className="logo"></div>
          <div className="cardHeaderText">
            <div className="cardTitle">Login</div>
          </div>
        </div>
        <div className="cardBody">
          <div className="wrapper">
            <img src={iconDark} alt="" className="responsive-img w-5" />
          </div>
          {props.adminLoginView ? (
            <>
              <form onSubmit={props.login} action="#" className="p-x-1">
                <div className="formRow">
                  <TextField
                    fullWidth
                    placeholder="UserName"
                    onChange={props.handleChange}
                    id="username"
                    name="username"
                    style={{ margin: '8px' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermIdentityOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="formRow">
                  <TextField
                    placeholder="Password"
                    fullWidth
                    onChange={props.handleChange}
                    type="password"
                    name="password"
                    style={{ margin: '8px' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={props.isLoading}
                  className="w-100"
                  type="submit"
                >
                  Login
                </Button>
              </form>
              {(props.invalidUser || props.unauthorized || props.unknownError) && (
                <div className="formError">
                  {props.unauthorized && <span>Invalid Credentials</span>}
                  {props.unknownError && <span>Something went wrong</span>}
                  {props.invalidUser && (
                    <span>Cannot login due to missing info, please contact system admin</span>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="cardLink-container">
              <Button href={props.authUrl} className="btn-link" color="secondary">
                Continue with <img src={office365} alt="" />
              </Button>
            </div>
          )}
          <div className="divider" />
          <div className="cardActions p-x-2 p-y-1 right-align">
            {props.adminLoginView ? (
              <Button
                className="btn-link"
                color="secondary"
                disabled={props.isLoading}
                href={props.isLoading ? '#' : props.authUrl}
              >
                <span>Continue with Office 365</span>
                <i className="mdi mdi-office mdi-20px"></i>
              </Button>
            ) : (
              <Button
                size="small"
                disabled={props.isLoading}
                onClick={() => props.toggleView(true)}
              >
                <span className="btn-text-sm">Have Special Credentials?</span>
              </Button>
            )}
          </div>
        </div>

        {props.isLoading && <Indeterminate />}
      </div>
    </div>
  );
};
export default Login;
