import React, { useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
const Tags = props => {
  const [values, setValues] = React.useState([]);
  useEffect(() => setValues([...props.tags]), [props.tags]);
  const handleChange = tags => {
    setValues(tags);
    props.handleTagChange?.(tags);
    //check if reached max number of tags before adding a new one
  };
  return (
    <div className="TagsChipList">
      {props.editable ? (
        <div className="form-group">
          <Autocomplete
            loading={props.isLoading}
            value={values}
            multiple
            id="tags-standard"
            options={props.allTags}
            onChange={(e, newValue) => {
              handleChange(newValue);
            }}
            filterSelectedOptions
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.name}
                  style={{ backgroundColor: option.color }}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={option => (
              <>
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    marginRight: '0.75em',
                    backgroundColor: option.color
                  }}
                />
                <span>{option.name}</span>
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Tags"
                placeholder={props.placeholder || 'add'}
              />
            )}
          />
        </div>
      ) : (
        <div className="tagsViewMode">
          {!props.dense ? (
            <div className="tagsList">
              {props.tags.map((tag, idx) => (
                <Chip
                  label={tag.name}
                  style={{
                    backgroundColor: tag.color,
                    margin: '4px',
                    fontWeight: 500,
                    fontSize: '14px'
                  }}
                  key={tag.id}
                />
              ))}
            </div>
          ) : (
            <div className="tagsList-dense">
              {props.tags.map((tag, idx) => (
                <Tooltip title={tag.name} key={tag.id}>
                  <div className="dense" style={{ backgroundColor: tag.color }}></div>
                </Tooltip>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Tags;
