import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Home from './components/Home/HomeContainer';
import LoginContainer from './modules/Login/LoginContainer';
import EmployeeProfile from './modules/employees/components/employee-profile/EmployeeProfileContainer';
import EmployeesContainer from './modules/employees/components/employees-list/EmployeesContainer';
import EmployeesSkillsContainer from './modules/employees/components/employee-skills/EmployeeSkillsContainer';
import SkillListContainer from './modules/Administration/skills-manage/components/skill-list/SkillListContainer';
import TagsListContainer from './modules/Administration/tags-manage/components/tags-list/TagsListContainer';
import RolesListContainer from './modules/Administration/user-manage/components/roles-list/RolesListContainer';
import ClientsListContainer from 'modules/Administration/clients-manage/components/clients-list/ClientsListContainer';
import ProjectListContainer from 'modules/Administration/projects-manage/components/project-list/ProjectListContainer';
import AllocatedListContainer from 'modules/Administration/allocation/components/allocated-list/AllocatedListContainer';
import CurrentInventoryContainer from 'modules/Reports/skills-inventory/components/current-inventory/CurrentInventoryContainer';
import SkillsTrendsContainer from 'modules/Reports/skills-inventory/components/skills-trends/SkillsTrendsContainer';
import EmplyeesWithMissingSkillsContainer from 'modules/Reports/skills-inventory/components/employees-with-missing-skills/EmployeesWithMissingSkillsContainer';
import OverallAllocationContainer from 'modules/Reports/allocation/components/overall-allocation/OverallAllocationContainer';
import LoadingState from 'modules/shared/components/_states/LoadingState/LoadingState';
import AccessDenied from 'components/AccessDenied/AccessDenied';
import InitativesContainer from 'modules/Performance/initiatives-manage/components/initatives/InitativesContainer';
import RoadmapsContainer from 'modules/Performance/roadmap-manage/components/roadmaps/RoadmapsContainer';
import DepartmentsListContainer from 'modules/Administration/departments-manage/components/department-list/DepartmentListContainer';
import SkillsStatisticsContainer from 'modules/Reports/skills-inventory/components/skills-statistics/SkillsStatisticContainer';
import EmployeeSkillsContainer from 'modules/Reports/skills-inventory/components/employee-skills/EmployeeSkillsContainer';
import EmployeesAllSkillsContainer from 'modules/Reports/skills-inventory/components/employees-all-skills/EmployeesAllSkillsContainer';
import FeedbackListContainer from 'modules/Feedback/components/feedback-list/FeedbackListContainer';
import FeedbackDetailsContainer from 'modules/Feedback/components/feedback-details/FeedbackDetailsContainer';
import FeedbackAuthenticationContainer from 'modules/Feedback/components/feedback-authentication/FeedbackAuthenticationContainer';
import FeedbackResponseContainer from 'modules/Feedback/components/feedback-response/FeedbackResponseContainer';
import ResponseConfirmation from 'modules/Feedback/components/response-confirmation/ResponseConfirmation';
const Main = props => {
  const returnUrl = props.location.pathname + props.location.search + props.location.hash;
  const hasPermission = permission => {
    if (props.userPermissions.findIndex(a => a && a.toLowerCase() === 'admin') >= 0) return true;
    if (
      props.userPermissions.length > 0 &&
      props.userPermissions.some(a => a && a.toLowerCase() === permission.toLowerCase())
    )
      return true;
    return false;
  };
  const unauthorizedRoutes = ['/login', '/response', '/response-confirmation'];
  const isUnauthorizedRoute = location => {
    const result = unauthorizedRoutes.some(route => location.includes(route));
    return result;
  };
  return (
    <Switch>
      <Route exact path="/login" component={LoginContainer} />
      {!props.isLoggedIn &&
        props.isAuthChecked &&
        !isUnauthorizedRoute(props.location.pathname) && (
          <Redirect to={`/login?returnUrl=${returnUrl}`} />
        )}
      {!props.isLoggedIn && !props.isAuthChecked && <Route path="*" component={LoadingState} />}
      <Route exact path="/" component={Home} />

      <Route exact path="/employees" component={EmployeesContainer}>
        {!hasPermission('employees') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/employees/:id/profile" component={EmployeeProfile}>
        {/* protect it inside the component */}
      </Route>

      <Route exact path="/employees/:id/skills" component={EmployeesSkillsContainer} />
      {/* protect it inside the component */}

      <Route exact path="/admin/departments" component={DepartmentsListContainer}>
        {!hasPermission('departments') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/admin/skills" component={SkillListContainer}>
        {!hasPermission('skills') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/admin/tags" component={TagsListContainer}>
        {!hasPermission('tags') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/admin/security" component={RolesListContainer}>
        {!hasPermission('user roles') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/admin/clients" component={ClientsListContainer}>
        {!hasPermission('clients') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/admin/clients/:id/projects" component={ProjectListContainer}>
        {!hasPermission('clients') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/admin/clients/:id/projects/:pid" component={AllocatedListContainer}>
        {!hasPermission('clients') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/reports/skills" component={CurrentInventoryContainer}>
        {!hasPermission('reports_skills') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/reports/skills/trends" component={SkillsTrendsContainer}>
        {!hasPermission('reports_skills') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/reports/skills/statistics" component={SkillsStatisticsContainer}>
        {!hasPermission('reports_skills') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/reports/skills/employees" component={EmplyeesWithMissingSkillsContainer}>
        {!hasPermission('reports_skills') && <Redirect to="/accessdenied" />}
      </Route>
      <Route exact path="/reports/skills/employee-skills" component={EmployeeSkillsContainer}>
        {!hasPermission('reports_skills') && <Redirect to="/accessdenied" />}
      </Route>
      <Route
        exact
        path="/reports/skills/employees-all-skills"
        component={EmployeesAllSkillsContainer}
      >
        {!hasPermission('reports_skills') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/reports/employees/allocation" component={OverallAllocationContainer}>
        {!hasPermission('reports_system') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/performance/initiatives" component={InitativesContainer}>
        {!hasPermission('initiatives') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/performance/roadmaps" component={RoadmapsContainer}>
        {!hasPermission('roadmaps') && <Redirect to="/accessdenied" />}
      </Route>

      <Route exact path="/feedback" component={FeedbackListContainer}>
        {!hasPermission('feedback') && <Redirect to="/accessdenied" />}
      </Route>
      <Route exact path="/feedback/:id" component={FeedbackDetailsContainer}>
        {!hasPermission('feedback') && <Redirect to="/accessdenied" />}
      </Route>
      <Route exact path="/feedback/:id/login" component={FeedbackAuthenticationContainer}></Route>
      <Route exact path="/feedback/:id/response" component={FeedbackResponseContainer}></Route>
      <Route exact path="/response-confirmation" component={ResponseConfirmation}></Route>

      <Route exact path="/accessdenied" component={AccessDenied} />

      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = store => ({
  isLoggedIn: store.auth.isLoggedIn,
  isAuthChecked: store.auth.isAuthChecked,
  userPermissions: store.auth.userPermissions
});
export default withRouter(connect(mapStateToProps)(Main));
